import { CreditScore } from '../../types/creditscore';
import { BoxWithHeader } from '../Box/BoxWithHeader';
import { ChartCorporation } from '../ChartCorporation/ChartCorporation';
import { SplitList, SplitListItem } from '../SplitList';
import { CarIcon } from '../icons/Car';

type CreditHandlingCompanyInformationProps = {
  creditScoreData: CreditScore;
};

export function CreditHandlingCompanyInformation({
  creditScoreData,
}: CreditHandlingCompanyInformationProps) {
  const vatNumber =
    creditScoreData.dnb.companyRiskAndCreditData.companyInformation.identifiers
      .vatNumber;
  const companyName =
    creditScoreData.dnb.companyRiskAndCreditData.companyInformation.companyName
      .registeredName.name;
  const naceCode =
    creditScoreData.dnb.companyRiskAndCreditData.companyInformation
      .industryCodeSet.naceCodes.primaryNaceCode.code;
  const naceDescription =
    creditScoreData.dnb.companyRiskAndCreditData.companyInformation
      .industryCodeSet.naceCodes.primaryNaceCode.description;
  const establishmentYear =
    creditScoreData.dnb.companyRiskAndCreditData.companyInformation
      .registrationInformation.foundationDate.year;
  const numberOfEmployees =
    creditScoreData.dnb.companyRiskAndCreditData.companyInformation
      .generalCompanyData.employeeCount;
  const directors =
    creditScoreData.dnb.companyRiskAndCreditData.management.directors
      .currentDirectors;
  const boardMembers =
    creditScoreData.dnb.companyRiskAndCreditData.management.boardMembers
      .currentBoardMembers;
  const ownership = creditScoreData.dnb.companyRiskAndCreditData.ownership;

  return (
    <div className="grid gap-4">
      <BoxWithHeader icon={<CarIcon />} title="About the company">
        <div className="grid grid-cols-2 gap-8">
          <div>
            <SplitList heading="Customer">
              <SplitListItem label="Company">
                <span className="text-right">{companyName}</span>
              </SplitListItem>
              <SplitListItem label="VAT Number">
                <span className="text-right">{vatNumber}</span>
              </SplitListItem>
              <SplitListItem label="NACE">
                <span className="text-right">
                  <span className="block">{naceCode}</span>
                  <span className="block text-gray-500">
                    {naceDescription}
                  </span>{' '}
                </span>
              </SplitListItem>
              <SplitListItem label="Established">
                <span className="text-right">{establishmentYear}</span>
              </SplitListItem>
            </SplitList>
          </div>
          <div>
            <SplitList heading="Employees">
              <SplitListItem label="Number of employees">
                <span className="text-right">{numberOfEmployees}</span>
              </SplitListItem>
              <SplitListItem
                label={directors.length > 1 ? 'Directors' : 'Director'}
              >
                <span className="text-right">
                  {directors.map((director) => (
                    <span>{director.name}</span>
                  ))}
                </span>
              </SplitListItem>
              <SplitListItem label="Board members">
                <span className="text-right">
                  {boardMembers.map((boardMember, index) => (
                    <span>
                      {boardMember.name}
                      {index < boardMembers.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                </span>
              </SplitListItem>
            </SplitList>
          </div>
        </div>
      </BoxWithHeader>
      <BoxWithHeader icon={<CarIcon />} title="Corporation and owners">
        <div className="grid grid-cols-2">
          <div>
            <SplitList heading="Share holders">
              {ownership?.parentCompanies.parents.map((company) => {
                return (
                  <SplitListItem label={company.name}>
                    <span className="text-right">
                      {company.ownershipInPercent}%
                    </span>
                  </SplitListItem>
                );
              })}
            </SplitList>
          </div>
          <ChartCorporation ownsershipData={ownership} />
        </div>
      </BoxWithHeader>
    </div>
  );
}
