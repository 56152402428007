import { FormProvider, useForm } from 'react-hook-form';
import { Comment } from '../_shared/forms/fields/Comment';
import { Button } from '../Button/Button';
import { ThreeOneLayout } from '../Layout/ThreeOneLayout';
import { Box } from '../Box/Box';

export function CompleteCaseAssessmentTaskForm({ flowProps }) {
  const { task } = flowProps;
  const recipients = task?.context?.recipients;
  const accountNumbers = task?.context?.accountNumbers;
  const totalAmountInclTax = task?.context?.totalAmountInclTax;
  const method = useForm({
    defaultValues: {
      comment: '',
    },
  });
  const { handleSubmit } = method;
  const onSubmit = (data) => {
    flowProps.complete(data);
  };

  const variables = task?.variables;
  const processProgress = [
    {
      label: 'KYC',
      date: task?.context?.kycCompletedAt?.date ?? 'No date found',
      time: task?.context?.kycCompletedAt?.time ?? 'No time found',
    },
    {
      label: 'Credit',
      date: task?.context?.creditProcessCompletedAt?.date ?? 'No date found',
      time: task?.context?.creditProcessCompletedAt?.time ?? 'No time found',
    },
    {
      label: 'Customer signing',
      date:
        task?.context?.signingContractEstablishmentTypeCustomerCompletedAt
          ?.date ?? 'No date found',
      time:
        task?.context?.signingContractEstablishmentTypeCustomerCompletedAt
          ?.time ?? 'No time found',
    },
    ...(!variables?.isAssetFlow
      ? []
      : [
          {
            label: 'Supplier signing',
            date:
              task?.context?.signingContractEstablishmentTypeSupplierCompletedAt
                ?.date ?? 'No date found',
            time:
              task?.context?.signingContractEstablishmentTypeSupplierCompletedAt
                ?.time ?? 'No time found',
          },
        ]),
    {
      label: 'Pod signing',
      date: task?.context?.podSigningCompletedAt?.date ?? 'No date found',
      time: task?.context?.podSigningCompletedAt?.time ?? 'No time found',
    },
  ];
  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <ThreeOneLayout>
          <ThreeOneLayout.Left>
            <Box className="grid gap-6">
              <h4 className="text-black font-medium">Payment details</h4>
              <div className="flex flex-row justify-between">
                <div className="flex flex-col gap-2 w-1/3">
                  <div className="text-black font-medium flex gap-4 justify-between items-center">
                    <span className="">Expected total payout</span>
                    <span>DKK {task?.context?.totalAmountInclTax}</span>
                  </div>
                  <div className="justify-between flex gap-4 items-center">
                    <span className="">Previously paid</span>
                    <span>DKK {task?.context?.prePaidAmountInclTax}</span>
                  </div>
                  <div className=" justify-between flex gap-4 items-center">
                    <span className="">Final payout</span>
                    <span>DKK {task?.context?.amountToBePaidInclTax}</span>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="flex-1 mr-4">
                    <div className="label text-black font-medium">
                      Total Amount:
                    </div>
                    <div>{totalAmountInclTax}</div>
                  </div>

                  <div className="flex-1 mr-4">
                    <div className="label text-black font-medium">
                      Recipients:
                    </div>
                    {recipients?.length > 0 &&
                      recipients?.map((recipient, index) => (
                        <div key={index}>{recipient}</div>
                      ))}
                  </div>

                  <div className="flex-1 mr-4">
                    <div className="label text-black font-medium">
                      Accounts:
                    </div>
                    {accountNumbers?.length > 0 &&
                      accountNumbers?.map((accountNo, index) => (
                        <div key={index}>{accountNo}</div>
                      ))}
                  </div>
                </div>
              </div>
            </Box>
            {/*   <Box className="grid gap-2">
              <h4 className="text-black font-medium">Assets details</h4>
              <div>
                {variables?.assetIds?.map((asset) => {
                  return <div>{asset}</div>;
                })}
              </div>
            </Box> */}
            <Box className="grid gap-2">
              <h4 className="text-black font-medium pb-8">
                Credit assessments comments
              </h4>
              {task?.context?.assessments?.map((assessment) => {
                const { comment, date, reporter } = assessment;
                return (
                  <div className="bg-gray-50 p-4 border border-gray-400 flex flex-col gap-1">
                    <h3 className="text-sm">
                      {reporter?.name} {date?.date} {date?.time}
                    </h3>
                    <h4 className="text-black text-lg">Comment</h4>
                    <p>{comment}</p>
                  </div>
                );
              })}
              {/*     <div className="flex flex-col rounded-md">
                <DecisionComment
                  disabled={true}
                  name=""
                  label="Caseworker Assessment"
                  placeholder={item?.fourEyesComment ?? 'siuu'}
                  reporter={item?.fourEyesReporter ?? 'swehriew'}
                  date={item?.date ?? '23.02.2024'}
                  userType={item?.userType ?? 'admin'}
                ></DecisionComment>
              </div> */}
            </Box>
            <Box className="grid gap-8">
              <h4 className="text-black font-medium">Process</h4>
              <div className="flex flex-row w-full items-center justify-between">
                {processProgress?.map((progress, index) => {
                  return (
                    <div className="flex items-center flex-row">
                      <div className="flex flex-col gap-2 items-center justify-center">
                        <div className="h-10 w-10 rounded-full bg-grass-50 text-white flex items-center justify-center">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.354 2.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708 0l-4-4a.5.5 0 1 1 .708-.708L5 12.293l9.646-9.647a.5.5 0 0 1 .708 0Z"
                              fill="#F9F9F9"
                            />
                          </svg>
                        </div>
                        <span className="uppercase text-sm text-black">
                          {progress?.label}
                        </span>
                        <span className="text-xs">{progress?.date}</span>
                        <span className="text-xs">{progress?.time}</span>
                      </div>
                      {/*                       <div className="onePixelHeight bg-gray-200" />
                       */}{' '}
                    </div>
                  );
                })}
                <div className="flex flex-col justify-center items-center px-4 gap-2">
                  <div className="h-10 w-10 rounded-full bg-gray-300 text-white flex items-center justify-center"></div>
                  <span className="uppercase text-sm text-black">
                    Start Contract
                  </span>
                  <span className="text-transparent">fds</span>
                </div>
              </div>
            </Box>
          </ThreeOneLayout.Left>
          <ThreeOneLayout.Right>
            <div className="flex flex-col gap-4">
              <h3 className="pb-2 border-b border-gray-200 font-medium">
                Contract ID: {variables?.contractId}
              </h3>
              <div>
                <h3 className="text-black pb-2">Applicant</h3>
                <div className="flex flex-col gap-1">
                  <span>Company: {variables?.customerName}</span>
                  <span>CVR: {variables?.customerId}</span>
                </div>
              </div>
              <div>
                <h3 className="text-black pb-2">Company contact</h3>
                <div className="flex flex-col gap-1">
                  <span>Name: {variables?.customerName}</span>
                  <span>Phone: {variables?.customerId}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <Comment
                name="comment"
                label="Internal comment"
                placeholder="Enter a comment"
                disabled={task.status === 'completed'}
              />
              <Button type="submit">Final payout and activate contract</Button>
            </div>
          </ThreeOneLayout.Right>
        </ThreeOneLayout>
      </form>
    </FormProvider>
  );
}
