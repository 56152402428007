import { PieChart, Pie, Cell } from 'recharts';

export function ScoreColors(score) {
  return {
    primary: '#2B6494',
    secondary: '#D7E5F1',
  };
}

export function CarHalfPieScore({
  title = 'Type of asset',
  cars,
  assets,
  isLoading = false,
}) {
  const data = [
    { name: 'A', value: assets, color: ScoreColors(assets).primary },
    {
      name: 'B',
      value: cars > 0 ? cars : 0,
      color: ScoreColors(cars).secondary,
    },
  ];
  const cx = 150;
  const cy = 100;
  const iR = 65;
  const oR = 100;

  return (
    <div className="relative bg-white w-fit rounded-md shadow-lg pt-4">
      <div className="px-4 pb-4 flex flex-col gap-4">
        <p>{title}</p>
        <div className="flex justify-between items-center">
          <div className="flex flex-col items-center justify-center">
            <span className="text-2xl">{cars}</span>
            <span className="text-xs">Cars</span>
          </div>
          <div className="flex flex-col items-center justify-center">
            <span className="text-2xl">{assets}</span>
            <span className="text-xs">Assets</span>
          </div>
        </div>
      </div>
      <PieChart className="w-fit h-fit relative" width={300} height={100}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
          className="relative"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
      {/*  <div className="absolute bottom-2 ml-1 left-1/2 transform -translate-x-1/2">
        <span className="text-2xl">
          {percentage > 100 ? 100 : percentage.toFixed(0)}%
        </span>
      </div> */}
    </div>
  );
}
