import { useFormConnect } from '../../../../hooks/useFormConnect';
import { InputBox } from '../../Inputs/InputBox';
import { currencyMask } from '../../Inputs/InputBox/InputBox.masks';

export const Amount = ({ name, label = '', disabled = false }) => {
  const props = useFormConnect(name);
  return (
    <InputBox
      disabled={disabled}
      label={label}
      mask={currencyMask}
      {...props}
    ></InputBox>
  );
};
