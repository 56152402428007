import { formatMoney } from '../../utils/formatMoney';
import { SplitList, SplitListItem } from '../SplitList';

export function PaymentDetails({ details, isAsset = false }) {
  const Contract = details?.Contract;
  const CalculationResult = details?.CalculationResult;
  if (isAsset) {
    const leasingContractData = details?.Contract?.LeasingAssetsContract;
    return (
      <div className="flex gap-8">
        <SplitList heading="Payment information">
          <SplitListItem label="Total sum incl vat">
            {formatMoney(
              details?.Calculation?.LeasingAssets?.Principal?.IncVat,
            )}{' '}
            DKK
          </SplitListItem>
          <SplitListItem label="Total cost per month incl vat">
            {formatMoney(details?.Calculation?.LeasingAssets?.Payment?.IncVat)}{' '}
            DKK
          </SplitListItem>
          <SplitListItem label="Leasing period">
            {leasingContractData?.LeasingPeriodMonths} months
          </SplitListItem>
          <SplitListItem label="Interest rate">
            {details?.Calculation?.LeasingAssets?.NominalInterestRate} %
          </SplitListItem>
        </SplitList>
      </div>
    );
  }
  return (
    <div className="flex gap-8">
      <SplitList heading="Payment information">
        <SplitListItem label="Total sum incl vat">
          {formatMoney(
            CalculationResult?.Leasing
              ?.VehiclePriceLicencePlateDeliveryAndSubidiesIncVAT +
              CalculationResult?.Leasing?.L64UpfrontPayment,
            true,
          )}
        </SplitListItem>
        <SplitListItem label="Total cost per month incl vat">
          {formatMoney(CalculationResult?.Leasing?.TotalMonthlyPayment, true)}
        </SplitListItem>
        <SplitListItem label="Leasing period">
          {CalculationResult?.Leasing?.LeasingPeriod} months
        </SplitListItem>
        <SplitListItem label="Interest rate">
          {Contract?.Interest}%
        </SplitListItem>
      </SplitList>
    </div>
  );
}
