import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { Checkbox } from '../_shared/forms/fields/Checkbox';
import { Text } from '../_shared/forms/fields/Text';
import { Comment } from '../_shared/forms/fields/Comment';
import { useState } from 'react';

export function NeedMoreDocumentationTaskForm({ flowProps }) {
  const reporter = {
    name: flowProps?.user?.profile?.name,
    id: flowProps?.user?.profile?.username,
  };
  const method = useForm({
    resolver: zodResolver(
      z.object({
        docs: z
          .array(z.object({ value: z.string(), selected: z.boolean() }))
          .min(1, { message: 'Select at least one document' }),
        sendNotification: z.boolean(),
        email: z.string().email({ message: 'Enter a valid email' }).optional(),
        message: z.string(),
        date: z.string().datetime(),
        reporter: z.object({
          id: z.string(),
          name: z.string(),
        }),
      }),
    ),
    defaultValues: {
      sendNotification: false,
      customDoc: '',
      reporter: reporter,
      date: new Date().toISOString(),
      message: '',
      docs: [
        {
          value: 'budget',
          selected: false,
          label: 'Budget',
        },

        {
          value: 'updatedFinancialStatement',
          selected: false,
          label: 'Updated financial statement',
        },
        {
          value: 'businessPlan',
          selected: false,
          label: 'Business plan',
        },
      ],
    },
  });

  const { handleSubmit, control, watch, resetField } = method;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'docs',
  });
  function onSubmit(data) {
    const selectedDocs = data.docs
      .filter((doc) => {
        if (doc.selected) {
          return doc.value;
        }
      })
      .map((doc) => doc.value);

    const payload = {
      ...data,
      docs: selectedDocs,
    };
    flowProps?.trigger(payload);
  }

  const customDoc = {
    label: watch('customDoc'),
    value: watch('customDoc'),
    selected: true,
  };

  const [error, setError] = useState(false);
  function appendAndClearField(doc) {
    if (doc.label === '') {
      setError(true);
    } else {
      setError(false);
      append(doc);
      resetField('customDoc');
    }
  }

  return (
    <FormProvider {...method}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-gray-100 h-full w-full flex flex-col gap-4 justify-start items-center p-4"
      >
        <h3 className="flex flex-col gap-4 w-2/3 text-lg">
          Ask for more documentation
        </h3>
        <Box className="w-2/3 flex flex-col gap-4">
          <span className="text-gray-700 font-medium">Documents</span>
          <div className="flex flex-col gap-3">
            {fields?.map((option, index) => {
              return (
                <div className="flex gap-4">
                  <Checkbox
                    name={`docs[${index}].selected`}
                    label={option?.label}
                  />
                  {index > 2 && (
                    <button onClick={() => remove(index)}>X</button>
                  )}
                </div>
              );
            })}
            <Text name="customDoc" label="Custom document" />
            {error && (
              <p className="text-red-500">Please enter a document name</p>
            )}
            <Button
              type="button"
              intent="secondary"
              onClick={() => appendAndClearField(customDoc)}
            >
              Add custom (+)
            </Button>
          </div>
        </Box>
        <Box className="w-2/3 flex flex-col gap-4">
          <span className="text-gray-700 font-medium">Add a message</span>
          <Comment placeholder="(Optional)" name="message" label="Message" />
        </Box>
        <Box className="w-2/3 flex flex-col gap-4">
          <span className="text-gray-700 font-medium">Send notification</span>
          <div className="flex flex-col gap-3">
            <Checkbox
              name="sendNotification"
              label="I want to send a notification"
            />
            {watch('sendNotification') && <Text name="email" label="Email" />}
          </div>
        </Box>
        <div className="w-2/3 flex gap-4">
          <Button type="submit" intent="primary">
            Complete
          </Button>
          <Button type="button" intent="secondary">
            Save and close
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
