import { FinancialStatement } from '../../types/creditscore';

function formatBarBalanceData(financialStatements: FinancialStatement[]) {
  return financialStatements
    .map((financialStatement) => {
      const year = financialStatement.closingPeriod.year;
      const currentAssets =
        financialStatement.balanceSheet.assets.currentAssets.currentAssetsTotal;
      const fixedAssets =
        financialStatement.balanceSheet.assets.assetsTotal - currentAssets;
      const debt =
        financialStatement.balanceSheet.liabilitiesAndEquity.liabilities
          .currentLiabilities.currentLiabilitiesTotal +
        financialStatement.balanceSheet.liabilitiesAndEquity.liabilities
          .nonCurrentLiabilities.longTermLiabilitiesTotal;

      const equity =
        financialStatement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
          .equityTotal;
      const debtWithoutEquity = equity < 0 ? debt + equity : debt;
      const equityAsPositive = equity > 0 ? equity : equity * -1;
      const assetsPercentageCurrent =
        (currentAssets / (currentAssets + fixedAssets)) * 100;
      const assetsPercentageFixed =
        (fixedAssets / (currentAssets + fixedAssets)) * 100;
      const debtPercentage = (debt / (debt + equityAsPositive)) * 100;
      const equityPercentage =
        (equityAsPositive / (debt + equityAsPositive)) * 100;

      return {
        year,
        currentAssets,
        fixedAssets,
        debt,
        debtWithoutEquity,
        equity,
        equityAsPositive,
        assetsPercentageCurrent: parseFloat(assetsPercentageCurrent.toFixed(2)),
        assetsPercentageFixed: parseFloat(assetsPercentageFixed.toFixed(2)),
        debtPercentage: parseFloat(debtPercentage.toFixed(2)),
        equityPercentage: parseFloat(equityPercentage.toFixed(2)),
        assets: {
          currentAssets,
          fixedAssets,
          assetsPercentageCurrent: parseFloat(
            assetsPercentageCurrent.toFixed(2),
          ),
          assetsPercentageFixed: parseFloat(assetsPercentageFixed.toFixed(2)),
        },
        liabilities: {
          debt,
          debtWithoutEquity,
          equity,
          debtPercentage: parseFloat(debtPercentage.toFixed(2)),
          equityPercentage: parseFloat(equityPercentage.toFixed(2)),
        },
      };
    })
    .reverse();
}

export { formatBarBalanceData };
