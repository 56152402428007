import { UploadDocumentsTaskForm } from '../../components/Views/UploadDocumentsTaskForm';
import { FlowContext } from '../../hooks/useFlowContext';

export function UploadDocuments(props) {
  return (
    <FlowContext.Provider value={props}>
      <UploadDocumentsTaskForm flowProps={props} />
    </FlowContext.Provider>
  );
}
