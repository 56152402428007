import clsx from 'clsx';
import { TaskItemBadgeLabledHover } from '../TaskItems/TaskItem.BadgeLabledHover';

export const CaseSummaryProgressBar = ({ flowProps }) => {
  flowProps = flowProps.flow || flowProps || {};
  const state = flowProps?.caseData?.data || flowProps?.data || {};

  const flowCancelled = flowProps?.caseData?.status === 'archived';

  let progress =
    state.kyc?.businessStatus &&
    state.creditProcess?.businessStatus !== 'ONBOARDING_DONE' &&
    state.kyc?.businessStatus !== 'COMPLETED_APPROVED'
      ? 1
      : 0;
  progress = state.signingCustomer?.businessStatus ? 2 : progress;

  progress =
    state.signingCustomer?.businessStatus === 'SIGNING_COMPLETED'
      ? 3
      : progress;

  progress =
    state.invoice?.businessStatus === 'isAvailableForInvoice' ? 4 : progress;

  progress =
    state.invoice?.businessStatus === 'invoicesCompleted' ? 5 : progress;

  progress =
    state.signingPod?.businessStatus === 'SIGNING_COMPLETED' ? 6 : progress;

  progress =
    state.applicationFinalized?.businessStatus === 'FINAL_APPROVED'
      ? 7
      : progress;

  const status = flowCancelled ? 'delete' : 'unknown';

  return (
    <div className="relative rounded-xl shadow-lg flex flex-row justify-start w-full border border-gray-300">
      <div
        className={clsx(
          'relative h-4 w-4',
          progress === 7 ? 'bg-green-200' : 'bg-blue-200',
          progress === 0 ? 'rounded-xl' : 'rounded-l-xl',
        )}
      />
      <div className="w-full">
        <div
          className={clsx(
            'relative rounded-r-xl h-4',
            progress === 7 ? 'bg-green-200' : 'bg-blue-200',
          )}
          style={{ width: `${(Math.min(progress, 6) / 6) * 100}%` }}
        />
      </div>
      <div className="absolute right-0 w-progress">
        <TaskItemBadgeLabledHover
          status={progress > 0 ? 'completed' : status}
          label="Offer Creation"
          style={{
            right: '100%',
          }}
          className="absolute"
        />
        <TaskItemBadgeLabledHover
          status={progress > 1 ? 'completed' : status}
          className="absolute"
          label="Onboarding and Credit"
          style={{
            right: `${100 - 16.67}%`,
          }}
        />
        <TaskItemBadgeLabledHover
          status={progress > 2 ? 'completed' : status}
          label="Customer Signing"
          className="absolute"
          style={{
            right: `${100 - 33.33}%`,
          }}
        />
        <TaskItemBadgeLabledHover
          status={progress > 3 ? 'completed' : status}
          label="Vendor Signing"
          className="absolute"
          style={{
            right: `${100 - 50}%`,
          }}
        />
        <TaskItemBadgeLabledHover
          status={progress > 4 ? 'completed' : status}
          label="Invoicing"
          className="absolute"
          style={{
            right: `${100 - 66.67}%`,
          }}
        />
        <TaskItemBadgeLabledHover
          status={progress > 5 ? 'completed' : status}
          label="Proof of Delivery"
          className="absolute"
          style={{
            right: `${100 - 83.33}%`,
          }}
        />
        <TaskItemBadgeLabledHover
          status={progress > 6 ? 'completed' : status}
          label="Account Activation"
          className="absolute"
          style={{
            right: `${0}%`,
          }}
        />
      </div>
    </div>
  );
};
