import clsx from 'clsx';
import { formatMoney } from '../../utils/formatMoney';
import { Box } from '../Box/Box';

export function CaseSummaryInvoice({ flowProps }) {
  const invoiceData = flowProps?.data?.invoice;
  const currency = invoiceData?.documentCurrencyCode === 'DK' ? 'DKK' : 'EUR';
  return (
    <div className=" p-6 rounded-xl shadow-lg gap-6 flex flex-col w-full border border-gray-300">
      <div className="flex flex-row gap-8 text-xs w-full">
        <div>
          <p className=" text-gray-600 uppercase font-medium">Total amount</p>
          {formatMoney(
            invoiceData?.legalMonetaryTotal?.lineExtensionAmount?.amount,
            true,
            0,
            currency,
          )}
        </div>
        <div>
          <p className=" text-gray-600 uppercase font-medium">
            Total tax amount
          </p>
          <p className=" text-black">
            {formatMoney(
              invoiceData?.taxTotal?.taxAmount?.amount,
              true,
              0,
              currency,
            )}
          </p>
        </div>
        <div>
          <p className=" text-gray-600 uppercase font-medium">payment id</p>
          <p className=" text-black">{invoiceData?.paymentMeans?.paymentId}</p>
        </div>
        <div>
          <p className=" text-gray-600 uppercase font-medium">vendor</p>
          <p className=" text-black">
            {invoiceData?.accountingSupplierParty?.party?.name}
          </p>
        </div>
        <div>
          <p className=" text-gray-600 uppercase font-medium">vendor contact</p>
          <p className=" text-black">
            {
              invoiceData?.accountingSupplierParty?.party?.contact
                ?.electronicMail
            }
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-xs uppercase">Note</span>
        <span className="p-2 bg-blue-50 rounded-sm text-xs">
          {invoiceData?.note}
        </span>
      </div>
      <div className="flex flex-col gap-1 w-full">
        <div className="grid grid-cols-3 w-full px-4">
          <span className="text-xs uppercase">item</span>
          <span className="text-xs uppercase text-right">quantity</span>
          <span className="text-xs uppercase text-right">price</span>
        </div>
        {invoiceData?.invoiceLine?.map((line, index) => {
          return (
            <div
              className={clsx(
                'grid grid-cols-3 w-full p-4 border-b border-gray-300',
                index % 2 === 0 ? 'bg-gray-100' : '',
              )}
            >
              <span className="text-xs">{line?.item?.description}</span>
              <span className="text-xs uppercase text-right">
                {line?.invoicedQuantity}
              </span>
              <span className="text-xs uppercase text-right">
                {formatMoney(
                  line?.price?.priceAmount?.amount,
                  true,
                  0,
                  currency,
                )}{' '}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
