import clsx from 'clsx';
import { useState } from 'react';
import { BsStars } from 'react-icons/bs';

export function MagicButton({
  isLoading,
  onClick,
}: {
  isLoading: boolean;
  onClick: () => void;
}) {
  const [gptButtonHover, setGptButtonHover] = useState(false);

  return (
    <div
      className={clsx(
        'absolute right-4 top-4 rounded-lg bg-purple-800 opacity-70 cursor-pointer hover:shadow-lg hover:bg-purple-800 hover:opacity-100',
        isLoading && 'cursor-not-allowed',
      )}
      onClick={onClick}
      onMouseOver={() => setGptButtonHover(true)}
      onMouseLeave={() => setGptButtonHover(false)}
    >
      {[...Array(30).keys()].map((index) => (
        <BorderParticle
          nth={index}
          maxAmount={30}
          show={!isLoading && !gptButtonHover}
        />
      ))}
      {[...Array(30).keys()].map((index) => (
        <BorderParticle
          nth={index}
          maxAmount={30}
          show={!isLoading && !gptButtonHover}
          offset={0.33}
        />
      ))}
      {[...Array(30).keys()].map((index) => (
        <BorderParticle
          nth={index}
          maxAmount={30}
          show={!isLoading && !gptButtonHover}
          offset={0.667}
        />
      ))}
      <BsStars
        className={clsx('text-white w-8 h-8 p-2', isLoading && 'animate-pulse')}
      />
    </div>
  );
}

function BorderParticle({
  nth,
  maxAmount = 3,
  show = true,
  offset = 0.0,
}: {
  nth: number;
  maxAmount?: number;
  offset?: number;
  show?: boolean;
}) {
  const animationDuration = 3000;
  const delayBetween = -(animationDuration / 2) / maxAmount;
  return (
    <div
      className="absolute w-full h-full"
      style={{
        animation: `${animationDuration}ms ease-in-out calc(${animationDuration / 4}ms + ${delayBetween * nth}ms - ${offset * animationDuration}ms) infinite glowY`,
      }}
    >
      <div
        className="w-full h-full"
        style={{
          animation: `${animationDuration}ms ease-in-out calc(0s + ${delayBetween * nth}ms - ${offset * animationDuration}ms) infinite glowX`,
          opacity: Math.max(0, (nth + 1) / maxAmount),
        }}
      >
        <div
          className={clsx(
            'absolute transform -translate-x-1/2 -translate-y-1/2 bg-pink-600 rounded-xl w-0.5 h-0.5',
            !show && 'hidden',
          )}
        />
      </div>
    </div>
  );
}
