export function SuitcaseIcon({ currentColor = 'currentColor' }) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 13"
      height={16}
    >
      <path
        d="M11.91 3h-9a2 2 0 0 0-2 2v5.5a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2ZM4.41 12.5V3M10.41 12.5V3M4.91 3a2.5 2.5 0 1 1 5 0"
        stroke={currentColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
