import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { fetchConfig } from "../api/api"

function transformSelectedConfigToString(obj) {
  if (!obj) return ""

  const variableKey = encodeURIComponent(obj.name)
  const variableValue = encodeURIComponent(obj.value)

  return `&variableKey=${variableKey}&variableValue=${variableValue}`
}

export const useFilter = () => {
  // Time range filter
  const defaultStartDate = new Date()
  defaultStartDate.setDate(defaultStartDate.getDate() - 7)
  const [startDate, setStartDate] = useState(
    defaultStartDate.toISOString().split("T")[0]
  )
  const [endDate, setEndDate] = useState(new Date().toISOString().split("T")[0])

  // Variable filter
  const [selectedVariable, setSelectedVariable] = useState(null)

  // Task analysis
  const [selectedTaskAnalysis, setSelectedTaskAnalysis] = useState(null)

  const { data: configData } = useQuery({
    queryKey: ["config"],
    queryFn: () => fetchConfig(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // select: (data) => {
    //   const filterArray = data?.filters
    //   const taskAnalysisArray = data?.taskAnalysisKeys

    //   return {
    //     taskAnalysisKeys: taskAnalysisArray?.taskAnalysisKeys,
    //     filters: filterArray.filters.map((item) => {
    //       return {
    //         ...item,
    //         values: ["Alle", ...item.values],
    //       }
    //     }),
    //   }
    // },
  })

  const adjustedFilters = configData?.filters.map((item) => {
    return {
      ...item,
      values: ["Alle", ...item.values],
    }
  })

  if (
    configData?.taskAnalysisKeys.length > 0 &&
    selectedTaskAnalysis === null
  ) {
    setSelectedTaskAnalysis(configData?.taskAnalysisKeys[0])
  }

  const handleSelectConfig = (item, val) => {
    if (val === "Alle") {
      setSelectedVariable(null)
    } else {
      setSelectedVariable({ name: item.name, value: val })
    }
  }

  return {
    dates: {
      startDate,
      endDate,
      onChangeStartDate: setStartDate,
      onChangeEndDate: setEndDate,
    },
    config: {
      configurableVariables: adjustedFilters,
      taskAnalysisKeys: configData?.taskAnalysisKeys,
      onChangeTaskAnalysis: setSelectedTaskAnalysis,
      selectedTaskAnalysis: selectedTaskAnalysis,
      variableQueryParam: transformSelectedConfigToString(selectedVariable),
      selectedVariable: selectedVariable,
      handleSelectConfig,
    },
  }
}
