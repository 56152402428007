export const PROCESSES = {
  CREDIT_PROCESS: 'credit-process',
  CREDIT_PROCESS_MANUAL_HANDLING: 'credit-process-manual-handling',
  INITIATE_CONTRACT: 'initiate-contract',
  INITIATE_DELIVERY: 'initiate-delivery',
  KYC_B2B_DENMARK: 'kyc-b2b-denmark',
  CREDIT_SCORE_B2B_DENMARK: 'credit-score-b2b-denmark',
  ONBOARDING_B2B_DENMARK: 'onboarding-b2b-denmark',
  SEND_NOTIFICATION_EVENT: 'send-notification-event',
} as const;
