import {
  SkeletonAggregate,
  SkeletonMetric,
} from "./FeaturedMetrics.SkeletonMetric"
import { cn } from "../../utils"
import { Fragment } from "react"

export function FeaturedMetricsLoading() {
  return (
    <div
      className={cn(
        "lg -8 -mb-16 rounded-lg bg-white p-4 text-neutral-black shadow-xl"
      )}
    >
      <div className="flex w-full items-stretch justify-between">
        <Fragment>
          <div className="flex basis-full flex-col">
            <SkeletonMetric />
          </div>
          <div className="mr-4 w-px bg-neutral-400" />
        </Fragment>
        <Fragment>
          <div className="flex basis-full flex-col">
            <SkeletonMetric />
          </div>
          <div className="mr-4 w-px bg-neutral-400" />
        </Fragment>
        <Fragment>
          <div className="flex basis-full flex-col">
            <SkeletonMetric />
          </div>
          <div className="mr-4 w-px bg-neutral-400" />
        </Fragment>
        <Fragment>
          <div className="flex basis-full flex-col">
            <SkeletonMetric />
          </div>
          <div className="mr-4 w-px bg-neutral-400" />
        </Fragment>
        <Fragment>
          <div className="flex basis-full flex-col">
            <SkeletonMetric />
          </div>
          <div className="mr-4 w-px bg-neutral-400" />
        </Fragment>
        <div className="basis-full">
          <SkeletonAggregate />
        </div>
      </div>
    </div>
  )
}
