export function getPendingTaskTitle(task: any) {
  /*   if (task?.taskType === 'upload-requested-documents') {
    return `Upload requested documents - ${task?.context?.documentsRequested.map(
      (doc, index) => {
        const firstLetterUpperCase = doc.charAt(0).toUpperCase() + doc.slice(1);
        return `(${firstLetterUpperCase}${index + 1 === task?.context?.documentsRequested.length ? '' : ', '})`;
      },
    )}`;
  } */
  if (task?.context?.assetType) {
    return `Check ${task?.context?.assetType} payout`;
  }

  if (task?.taskType === 'choose-signers') {
    return 'Select signees - ' + task?.variables?.customerName;
  }

  if (task?.taskType === 'sign-document') {
    return 'Sign document - ' + task?.variables?.customerName;
  }

  return task?.taskType;
}
