import { format, parseISO } from 'date-fns';
import { nb, enGB } from 'date-fns/locale';

function parse(date) {
  if (typeof date === 'string') {
    return parseISO(date);
  }
  return date;
}

export function formatDate(date, frmat = 'dd MMMM yyyy', locale = enGB) {
  return format(parse(date), frmat, { locale: locale });
}
