import { ManuallyApproveTaskForm } from '../../components/Views/ManuallyApproveTaskForm';
import { FlowContext } from '../../hooks/useFlowContext';

export function ManuallyApprove(props) {
  return (
    <FlowContext.Provider value={props}>
      <ManuallyApproveTaskForm flowProps={props} />
    </FlowContext.Provider>
  );
}
