import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { Box } from '../Box/Box';

export function CreditScoreGraph({ score, title = 'Credit Score' }) {
  const creditScore = score;
  const findRank = (score) => {
    if (score == null) {
      return 'Not Applicable';
    }

    if (score > 75) {
      return 'Good';
    } else if (score > 25) {
      return 'Medium';
    } else {
      return 'Poor';
    }
  };
  return (
    <div
      className="bg-white relative rounded-md shadow-md px-4 pt-4 w-full grid grid-cols-1"
      style={{ gridTemplateRows: 'auto auto 1fr' }}
    >
      <h1 className="text-md">{title}</h1>
      <h2 className="text-2xl text-black">{findRank(creditScore)}</h2>
      <div className="flex flex-col justify-end">
        <HalfPieChartCreditScore creditScore={creditScore} />
        <span className="absolute text-black font-medium text-xl bottom-2 left-1/2 -translate-x-1/2 transform">
          {creditScore}%
        </span>
      </div>
    </div>
  );
}

export function ScoreColors(score) {
  if (score > 75) {
    return {
      primary: '#1E6D00',
      secondary: '#E1F7D8',
    };
  } else if (score > 25) {
    return {
      primary: '#FFBB16',
      secondary: '#FFF2A5',
    };
  } else {
    return {
      primary: '#E4251B',
      secondary: '#FFE2E1',
    };
  }
}

export function HalfPieChartCreditScore({ creditScore = 40 }) {
  const data = [
    { name: 'A', value: creditScore, color: ScoreColors(creditScore).primary },
    {
      name: 'B',
      value: 100 - creditScore,
      color: ScoreColors(creditScore).secondary,
    },
  ];
  const iR = '60%';
  const oR = '100%';

  return (
    <div className="overflow-hidden" style={{ height: '160px' }}>
      <div className="w-full" style={{ height: '320px' }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data}
              innerRadius={iR}
              outerRadius={oR}
              stroke="none"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
