import { useState } from 'react';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { useFormConnect } from '../../../../hooks/useFormConnect';
import clsx from 'clsx';
import { Text } from '../../forms/fields/Text';
import { Email } from '../../forms/fields/Email';

type RadioButtonListProps = {
  id: string;
  horizontal?: boolean;
  setValue: (field, value: any) => void;
  options: {
    label: string;
    value: any;
    index: number;
  }[];
};

export function RadioButtonListSignees({
  id,
  options,
  horizontal = false,
  setValue,
}: RadioButtonListProps) {
  const { value, name, error } = useFormConnect(id);
  const { t } = useFlowContext();
  function change(value) {
    setValue('signers', options[value].value);
  }

  return (
    <div className="w-full">
      <fieldset className="cursor-pointer">
        <legend className="sr-only">Radio buttons</legend>
        <div className={horizontal ? 'flex flex-row gap-4' : 'grid gap-2 '}>
          {options.map((option) => {
            return (
              <div
                key={option.value}
                className="flex flex-col items-start gap-2"
              >
                <div className="flex items-center gap-2">
                  <input
                    value={option.value}
                    id={option.label}
                    name={name}
                    type="radio"
                    checked={value[0]?.id === option.value[0]?.id}
                    className="h-4 min-w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    onChange={() => change(option.index)}
                  />
                  <label
                    htmlFor={option.label}
                    className=" block text-sm text-gray-700"
                  >
                    {option.label}
                  </label>
                </div>
                {value[0]?.id === option.value[0]?.id && (
                  <div>
                    {option?.value?.map((signer, index) => {
                      return (
                        <div className="flex flex-col gap-2">
                          <p>
                            {signer?.name}
                            {signer?.positions.map(
                              (item) => ' - ' + item?.roleName,
                            )}
                          </p>
                          <Email
                            label={`Email to ${signer?.name}`}
                            name={`signers[${index}].email`}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
          {error && <span className="block text-red-500">{t(error)}</span>}
        </div>
      </fieldset>
    </div>
  );
}
