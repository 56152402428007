import { useQuery } from '@tanstack/react-query';
import { TaskProps } from '../../types/taskProps';
import {
  convertXml2json,
  fetchDecisionsByHash,
  findDescriptionById,
} from '../utils/decisions';
import { CenteredSpinner } from '@flow/icons';
import { DMNCard } from './DMNCard';
import './decisions.css';

export function Decisions(props: TaskProps) {
  const { flow } = props;
  const { flowId } = flow;
  const { data, isFetching } = useQuery({
    queryKey: ['decisions', flowId],
    queryFn: async () => {
      const values = Object.values(flow.data?.decisions ?? {});
      const flattenedValues = values.reduce((acc: any[], val: any) => {
        return acc.concat(val);
      }, [] as string[]);
      return Promise.all(
        flattenedValues.map(async (item: any) => {
          const xml = await fetchDecisionsByHash(
            item?.metaData?.layerDigest,
            item?.metaData?.tablename,
          );
          const ruleIds = item?.ruleIds;
          return { ...item, xml, ruleIds };
        }),
      );
    },
  });

  if (isFetching) return <CenteredSpinner />;
  const dmnCardsSorted = data
    ?.map((item) => {
      let converted;
      const descriptions: string[] = [];
      if (item?.ruleIds?.length > 0) {
        converted = convertXml2json(item?.xml);
        for (const id of item.ruleIds) {
          descriptions.push(findDescriptionById(converted, id)!);
        }
      }

      return {
        item,
        descriptions,
        ruleIdsLength: item?.ruleIds?.length ?? 0,
      };
    })
    .sort((a, b) => b.ruleIdsLength - a.ruleIdsLength);

  return (
    <div className=" max-w-4xl gap-10 grid grid-cols-2">
      {dmnCardsSorted?.map(({ item, descriptions }, index) => (
        <div key={`${index}-${item.id} `}>
          <DMNCard item={item} descriptions={descriptions} />
        </div>
      ))}
    </div>
  );
}
