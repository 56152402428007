import { Select } from "@flow/forms";
import { useFormConnect } from "../../hooks/useFormConnect";

export function CountryChooser({ id, label }: { id: string; label: string }) {
  const { ref, error, ...props } = useFormConnect(id);
  return (
    <div>
      <Select
        blankOptionLabel="Choose country..."
        {...props}
        label={label}
        options={[
          {
            label: "Norway",
            value: "norway"
          },
          {
            label: "Sweden",
            value: "sweden"
          },
          {
            label: "Denmark",
            value: "denmark"
          },
          {
            label: "Finland",
            value: "finland"
          }
        ]}
        showBlankOption
      />
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}
