import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts"

export function ChartLineFeaturedMetric({ data }) {
  const widths = {
    1: 10,
    2: 20,
    3: 30,
    4: 40,
    5: 50,
    6: 60,
    7: 70,
    8: 80,
  }

  const highestNumberOfDigitsInDataValue = data.reduce((acc, item) => {
    const numberOfDigits = item.value.toString().length
    if (numberOfDigits > acc) {
      return numberOfDigits
    }
    return acc
  }, 0)

  const max = widths[highestNumberOfDigitsInDataValue] ?? 100
  const width = max > 20 ? max + 10 : 30

  return (
    <div className="relative w-full pb-[180px]">
      <div className="absolute bottom-0 left-0 right-0 top-0">
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <XAxis
              dataKey="date"
              axisLine={true}
              tickLine={false}
              includeHidden={true}
              hide={false}
              allowDataOverflow
            />
            <YAxis
              axisLine={true}
              tickLine={false}
              tick={{ fill: "#7A7C7A" }}
              stroke="#DAD9D8"
              domain={["dataMin", "dataMax"]}
              width={width}
            />
            <CartesianGrid stroke="#DAD9D8" vertical={false} />

            <Line
              dataKey="value"
              fill={"#452268"}
              dot={false}
              type="monotone"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
