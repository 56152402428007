import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { Attachment } from '../_shared/Attachment/Attachment';
import { RadioButtonList } from '../_shared/Inputs/RadioButtonList/RadioButtonList';
import { Text } from '../_shared/forms/fields/Text';
import { ThreeOneLayout } from '../Layout/ThreeOneLayout';
import { CheckBox } from '../_shared/Inputs/Checkbox/Checkbox';
import { Checkbox } from '../_shared/forms/fields/Checkbox';
import { Amount } from '../_shared/forms/fields/Amount';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { Comment } from '../_shared/forms/fields/Comment';
import { DateInput } from '../_shared/Inputs/Datepicker/Datepicker';
import { EquipmentCombobox } from '../_shared/Inputs/ComboBox/EquipmentComboBox';
import { formatMoney } from '../../utils/formatMoney';
import { useEffect } from 'react';

function Supplier({ supplier }) {
  if (!supplier) return null;

  if (typeof supplier === 'string' || typeof supplier === 'number') {
    return (
      <div>
        <span>Supplier: {supplier}</span>
      </div>
    );
  }

  return (
    <div>
      <span className="font-medium">Supplier: </span>
      <span>{supplier?.CompanyName}</span>
      <br />
      <span>{supplier?.Cvr}</span>
      <br />
      <span>{supplier?.Email}</span>
    </div>
  );
}

export function VerifyInvoiceTaskForm({ flowProps }) {
  const { invoice, incomingInvoiceFlowId } = flowProps?.task?.context;
  const totalNetAmount =
    invoice?.legalMonetaryTotal?.lineExtensionAmount?.amount;
  const invoiceTotal = invoice?.legalMonetaryTotal?.payableAmount?.amount;
  const assetOptionsData = flowProps?.task?.context?.assets?.map((asset) => {
    return {
      id: asset?.Id,
      label: `${asset?.Brand} - ${asset?.Model}`,
      assetType: asset?.Type,
    };
  });

  const method = useForm({
    resolver: zodResolver(
      z.object({
        invoiceNo: z.string(),
        buyer: z.string(),
        supplier: z.string(),
        accountNo: z.string(),
        iban: z.string(),
        paymentDate: z.string(),
        kid: z.string(),
        totalNetAmount: z.number(),
        carriageNet: z.number(),
        totalTaxAmount: z.number(),
        totalInvoiceAmount: z.number(),
        paymentType: z.string({
          invalid_type_error: 'Please select a payment type',
        }),
        assetId: z.number({
          invalid_type_error: 'Please select an asset',
        }),
        assessment: z.object({
          decision: z.string({
            invalid_type_error: 'Please select an option',
          }),
          comment: z.string(),
        }),
      }),
    ),
    defaultValues: {
      invoiceNo: invoice?.id || '',
      buyer: invoice?.accountingCustomerParty?.party?.name || '',
      supplier: invoice?.accountingSupplierParty?.party?.name || '',
      accountNo: invoice?.accountNo || '',
      iban:
        invoice?.paymentMeans?.payeeFinancialAccount?.financialAccountID || '',
      paymentDate: invoice?.paymentMeans?.paymentDueDate || '',
      kid: '',
      totalNetAmount: Number(totalNetAmount.toFixed(0)) || 0,
      carriageNet: 0,
      totalTaxAmount:
        Number(invoice?.taxTotal?.taxAmount?.amount.toFixed(0)) || 0,
      totalInvoiceAmount: Number(invoiceTotal.toFixed(0)) || 0,
      paymentType: invoice?.taxTotal?.taxAmount?.paymentType || null,
      assessment: {
        comment: '',
        decision: null,
      },
      assetId: null,
    },
  });

  const { handleSubmit, setValue, formState, watch } = method;

  function onSubmit(data) {
    flowProps.complete(data);
  }

  const applicant =
    flowProps?.flow?.data?.creditProcess?.applicant?.companyApplicant;
  const customer = {
    name: flowProps?.task?.variables?.customerName,
    phone: flowProps?.task?.variables?.phoneNumber,
    email: flowProps?.task?.variables?.email,
  };

  const assets =
    flowProps?.flow?.data?.currentContractDetails?.Contract
      ?.LeasingAssetsContract?.Assets;

  const hasSelectedAsset = watch('assetId');
  const isTotalNetAmountValidForFinalPayment = hasSelectedAsset
    ? assets.find((asset) => asset.Id === hasSelectedAsset)?.Price
    : null;

  const isEligibleForFinalPayment =
    totalNetAmount > isTotalNetAmountValidForFinalPayment * 0.89 ||
    totalNetAmount < isTotalNetAmountValidForFinalPayment * 0.11;

  const invoiceAttachmentKey = `invoice-${incomingInvoiceFlowId}`;

  const options = [
    {
      label: 'Deposit / Advanced payout',
      value: 'deposit',
    },
    { label: 'Final payment', value: 'finalPayment' },
  ];
  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <ThreeOneLayout>
          <ThreeOneLayout.Left>
            <Box className="grid gap-2">
              <div className="flex flex-col gap-4">
                <h4 className="text-black font-medium">Type of payment</h4>
                <div className="flex gap-8">
                  <div className="grid gap-2">
                    <label htmlFor="equipment">Assets</label>
                    <EquipmentCombobox
                      setValue={setValue}
                      options={assetOptionsData}
                      name="assetId"
                    />
                    {formState.errors?.assetId && (
                      <span className="text-red-500">
                        {formState.errors?.assetId?.message}
                      </span>
                    )}
                  </div>
                  <div className="grid">
                    <label htmlFor="paymentType">Payment type</label>
                    <RadioButtonList options={options} id="paymentType" />
                  </div>
                </div>
              </div>
            </Box>
            <Box className="grid gap-2">
              <h4 className="text-black font-medium">Invoice Data</h4>
              <div className="grid grid-cols-4 gap-4">
                <Text name="invoiceNo" label="Invoice no" />
                <Text name="buyer" label="Buyer" />
                <Text name="supplier" label="Supplier" />
                <Text name="accountNo" label="Account no" />
                <Text name="iban" label="IBAN" />
                <DateInput id="paymentDate" label="Payment date" />
                <Text name="kid" label="KID" />
                <Amount name="totalNetAmount" label="Total net amount" />
                <Amount name="carriageNet" label="Carriage Net" />
                <Amount name="totalTaxAmount" label="Total tax amount" />
                <Amount name="totalInvoiceAmount" label="Invoice total" />
              </div>
            </Box>
            <Box className="p-0" padding="">
              <h4 className="w-full p-4 text-black font-medium border-b border-gray-400">
                Invoice
              </h4>
              <Attachment
                flow={flowProps}
                attachmentKey={invoiceAttachmentKey}
                mimeType="application/pdf"
              />
            </Box>
          </ThreeOneLayout.Left>
          <ThreeOneLayout.Right>
            <div className="grid gap-4">
              <div>
                <h4 className="text-black font-medium">Applicant</h4>
                <span>{applicant?.companyName}</span>
                <br />
                <span>{applicant?.companyRegistrationNumber}</span>
              </div>
              <div>
                <h4 className="text-black font-medium">Company contact</h4>
                <span>{customer?.name}</span>
                <br />
                <span>Phone: {customer?.phone}</span>
                <br />
                <span>E-mail: {customer?.email}</span>
              </div>
              <div>
                <div className="flex flex-col gap-4">
                  {assets?.map((asset) => {
                    return (
                      <div key={asset?.Id} className="flex flex-col">
                        <h4 className="text-black font-medium">
                          {asset?.Type}: {asset?.Brand} {asset?.Model}
                        </h4>
                        <span>
                          Price ex VAT: {formatMoney(asset?.Price)} kr
                        </span>{' '}
                        <Supplier supplier={asset?.Supplier} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col gap-4">
              <label
                className=" font-medium text-black"
                id="assessment.decision"
                aria-label="decision"
              >
                Decision
              </label>
              <RadioButtonList
                id="assessment.decision"
                options={[
                  { label: 'Approve invoice', value: 'approved' },
                  { label: 'Request invoice adjustment', value: 'rejected' },
                  { label: 'Reject and delete invoice', value: 'rejected' },
                  { label: 'Incorrectly assigned invoice', value: 'rejected' },
                ]}
              />
              <Comment
                name="assessment.comment"
                label="Internal Comment"
                placeholder="(Optional)"
              />
              <div className="w-full flex gap-2">
                <Button type="submit">Complete</Button>
                <Button intent="secondary" onClick={() => flowProps?.save()}>
                  Save for later
                </Button>
              </div>
            </div>
          </ThreeOneLayout.Right>
        </ThreeOneLayout>
      </form>
    </FormProvider>
  );
}
