import clsx from 'clsx';

export function BoxWithHeader({
  children,
  className = '',
  title,
  icon,
  headerData = '',
}) {
  return (
    <div className="w-full">
      <div className=" bg-blue-200 w-full px-4 py-3 borderTopRadius flex items-center justify-between text-blue-900 font-medium">
        <div className="flex items-center gap-2 [&>*]:m-0 w-fit">
          <span className=" text-blue-400">{icon}</span>
          <h3>{title}</h3>
        </div>
      </div>
      <div className={clsx('bg-white rounded-md shadow-md p-4', className)}>
        {children}
      </div>
    </div>
  );
}
