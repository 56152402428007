import { Queues } from '../types/queue';

const defaultQueueSettings = {
  queueSort: 'sort=createdAt&dir=-1',
  pollingInterval: 5000,
  limit: 10,
};
const queueDefinition: Queues = {
  'my-cases-queue': {
    name: 'my-cases-queue',
    label: 'my-cases-label',
    queueType: 'assignee',
    queueFilter: '?status=active&hasIncidents=false&view=queueView',
    ...defaultQueueSettings,
  },
  'orders-queue': {
    name: 'orders-queue',
    label: 'orders-queue-label',
    queueFilter:
      '?status=active&hasIncidents=false&flowDefinitionId=initiate-contract&view=queueView',
    ...defaultQueueSettings,
  },
  'failed-cases-queue': {
    name: 'failed-cases-queue',
    label: 'failed-cases-label',
    queueFilter: '?hasIncidents=true&view=queueView',
    ...defaultQueueSettings,
  },
  'completed-cases-queue': {
    name: 'completed-cases-queue',
    label: 'completed-cases-label',
    queueFilter: '?status=completed&hasIncidents=false&view=queueView',
    ...defaultQueueSettings,
  },
  'manual-handling-queue': {
    name: 'manual-handling-queue',
    label: 'manual-handling-label',
    queueFilter:
      '?status=active&hasIncidents=false&data.applicationStatus=ManualReview&data.creditProcess.businessStatus=MANUAL_HANDLING_CREDIT&assignee=!&view=queueView',
    ...defaultQueueSettings,
  },
  'inprogress-credit-queue': {
    name: 'inprogress-credit-queue',
    label: 'inprogress-credit-label',
    queueFilter:
      '?status=active&hasIncidents=false&assignee.id&data.creditProcess.businessStatus=MANUAL_HANDLING_CREDIT&assignee=&view=queueView',
    ...defaultQueueSettings,
  },
  'final-approved-credit-queue': {
    name: 'final-approved-credit-queue',
    label: 'final-approved-credit-label',
    queueFilter:
      '?status=active&hasIncidents=false&data.creditProcess.businessStatus=FOUR_EYES&view=queueView',
    ...defaultQueueSettings,
  },
  'approved-credit-queue': {
    name: 'approved-credit-queue',
    label: 'approved-credit-label',
    queueFilter:
      '?status=active&hasIncidents=false&data.creditProcess.businessStatus=COMPLETED_APPROVED&view=queueView',
    ...defaultQueueSettings,
  },
  'signing-queue': {
    name: 'signing-queue',
    label: 'signing-queue-label',
    queueFilter:
      '?status=active&hasIncidents=false&data.signing.businessStatus=CREATED&view=queueView',
    ...defaultQueueSettings,
  },

  'contracts-queue': {
    name: 'contracts-queue',
    label: 'contracts-label',
    queueFilter:
      '?status=active&hasIncidents=false&data.documents.businessStatus=DOCUMENT_PENDING&view=queueView',
    ...defaultQueueSettings,
  },
  'non-assigned-invoice-queue': {
    name: 'non-assigned-invoice-queue',
    label: 'non-assigned-invoice-label',
    queueFilter:
      '?status=active&flowDefinitionId=register-invoice&data.invoiceStatus=received&view=queueView',
    ...defaultQueueSettings,
  },
  /*   'payout-inbox': {
    name: 'payout-inbox',
    label: 'payout-inbox-label',
    queueFilter: '?status=active&hasIncidents=false',
    ...defaultQueueSettings,
  }, */
  'inprogress-payout-queue': {
    name: 'inprogress-payout-queue',
    label: 'inprogress-payout-label',
    queueFilter:
      '?status=active&flowDefinitionId=initiate-contract&data.payout.status=IN_PROGRESS&view=queueView',
    ...defaultQueueSettings,
  },
  'proof-of-delivery-queue': {
    name: 'proof-of-delivery-queue',
    label: 'proof-of-delivery-label',
    queueFilter:
      '?status=active&hasIncidents=false&data.payout.businessStatus=PROOF_OF_DELIVERY&view=queueView',
    ...defaultQueueSettings,
  },
  'ready-for-activation-queue': {
    name: 'ready-for-activation-queue',
    label: 'ready-for-activation-label',
    queueFilter:
      '?status=active&hasIncidents=false&data.payout.businessStatus=READY_FOR_ACTIVATION&view=queueView',
    ...defaultQueueSettings,
  },
  'waiting-for-payout-queue': {
    name: 'waiting-for-payout-queue',
    label: 'waiting-for-payout-label',
    queueFilter:
      '?status=active&hasIncidents=false&data.payout.businessStatus=WAITING_FOR_PAYOUT&view=queueView',
    ...defaultQueueSettings,
  },
  'incoming-changes-queue': {
    name: 'incoming-changes-queue',
    label: 'incoming-changes-label',
    queueFilter:
      '?status=active&hasIncidents=false&flowDefinitionId=contract-change&referenceName=!%3Dearly-termination&view=queueView',
    ...defaultQueueSettings,
  },
  'early-termination-queue': {
    name: 'early-termination-queue',
    label: 'early-termination-label',
    queueFilter:
      '?status=active&hasIncidents=false&flowDefinitionId=contract-change&referenceName=early-termination&view=queueView',
    ...defaultQueueSettings,
  },
  'manually-complete-onboarding-queue': {
    name: 'manually-complete-onboarding-queue',
    label: 'manually-complete-onboarding-label',
    queueFilter:
      '?status=active&hasIncidents=false&data.kyc.businessStatus=ONBOARDING_WAITING&view=queueView',
    ...defaultQueueSettings,
  },
};

const menuStructure = [
  { name: 'my-cases-queue' },
  { name: 'orders-queue' },
  {
    name: 'onboarding-queue',
    elems: ['manually-complete-onboarding-queue'],
    isDefaultOpen: true,
  },
  {
    name: 'credit-handling-label',
    elems: [
      'manual-handling-queue',
      'inprogress-credit-queue',
      'final-approved-credit-queue',
      'approved-credit-queue',
    ],
    isDefaultOpen: true,
  },
  { name: 'signing-queue' },
  {
    name: 'document-handling-label',
    elems: ['contracts-queue'],
  },

  {
    name: 'payout-label',
    elems: [
      'non-assigned-invoice-queue',
      'inprogress-payout-queue',
      'proof-of-delivery-queue',
      'ready-for-activation-queue',
      'waiting-for-payout-queue',
    ],
    isDefaultOpen: true,
  },
  {
    name: 'active-contracts-label',
    elems: ['incoming-changes-queue', 'early-termination-queue'],
    isDefaultOpen: true,
  },
  { name: 'completed-cases-queue' },
  { name: 'failed-cases-queue' },
];

export { queueDefinition, menuStructure };
