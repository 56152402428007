import axios from 'axios';
import { getToken } from './sessionStorage';
var convert = require('xml-js');

export async function fetchDecisionsByHash(hash: string, filename: string) {
  const token = getToken();
  return axios
    .get<string>(`/api/decision-info/xmlFile/${hash}?filename=${filename}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data);
}

interface JsonElement {
  _attributes?: { id: string; [key: string]: string };
  [key: string]: any;
}

export function findDescriptionById(
  data: any,
  id: string,
  visited = new Set<any>(),
  parent?: any,
): string | undefined {
  // Check if the current level has attributes and matches the ID
  if (data._attributes?.id === id && data.description?._text) {
    return data.description._text;
  }

  // If this object has already been visited, return undefined to avoid infinite loop
  if (visited.has(data)) {
    return undefined;
  }

  // Add current object to visited set
  visited.add(data);

  // Iterate over all keys in the current level
  for (const key of Object.keys(data)) {
    const value = data[key];

    // If the value is an object, recurse into it
    if (typeof value === 'object' && value !== null) {
      const result = findDescriptionById(value, id, visited);
      if (result) {
        return result;
      }
    }

    // If the value is an array, iterate and recurse into each item
    else if (Array.isArray(value)) {
      for (const item of value) {
        const result = findDescriptionById(item, id, visited);
        if (result) {
          return result;
        }
      }
    }
  }

  // If no hit is found
  return undefined;
}

export function convertXml2json(xml: string): JsonElement {
  return convert.xml2js(xml, {
    compact: true,
    spaces: 4,
  }) as JsonElement;
}
