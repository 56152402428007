const getFeatures = (): string[] => {
  return (window as any).stacc_env?.featureToggles;
};

const getFeaturesWithConfig = (): {
  [key: string]: { [key: string]: string };
} => {
  const features = getFeatures();
  const enabledFeatures = features?.filter((feature) =>
    feature.includes('Enabled'),
  );
  const featuresWithConfig = enabledFeatures?.reduce((acc, feature) => {
    const featureName = feature.split('Enabled')[0];
    const featureConfig = features
      .filter(
        (f) =>
          f.split(':')?.[0]?.includes(featureName) &&
          f !== `${featureName}Enabled`,
      )
      .reduce((subAcc, f) => {
        const key = f.split(':')?.[0];
        const subKey = key
          .replace(featureName, '')
          .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
            index === 0 ? word.toLowerCase() : word.toUpperCase(),
          );
        subAcc[subKey] = f.replace(`${key}:`, '');
        return subAcc;
      }, {});
    acc[featureName] = featureConfig;
    return acc;
  }, {});
  return featuresWithConfig;
};

export const featureConfig = getFeaturesWithConfig();
