import { formatDate } from '../../utils/formatDate';
import { formatMoney } from '../../utils/formatMoney';
import { SplitList, SplitListItem } from '../SplitList';

export function AssetDetails({ details, isAsset = false }) {
  const vehicleData = details?.Contract?.Vehicle;
  if (isAsset) {
    return (
      <div className="flex gap-8">
        <SplitList heading={details?.Type}>
          <SplitListItem label="Asset">{details?.Brand}</SplitListItem>
          <SplitListItem label="Description">
            {details?.Category}({details?.Condition})
          </SplitListItem>
          <SplitListItem label="Model Year">{details?.ModelYear}</SplitListItem>
          <SplitListItem label="Price">
            {formatMoney(details?.Price)} DKK
          </SplitListItem>
        </SplitList>
      </div>
    );
  }
  return (
    <div className="flex gap-8">
      <SplitList heading="Car">
        <SplitListItem label="Asset">{vehicleData?.BrandName}</SplitListItem>
        <SplitListItem label="Description">
          {vehicleData?.EngineSize} {vehicleData?.EngineType}{' '}
        </SplitListItem>
        <SplitListItem label="Purchase date">
          {formatDate(vehicleData?.FirstRegistrationDate)}
        </SplitListItem>
        <SplitListItem label="Leasing period">
          {details?.CalculationResult?.Leasing?.LeasingPeriod} months
        </SplitListItem>
      </SplitList>
    </div>
  );
}
