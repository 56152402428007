const formatter = new Intl.NumberFormat('no-NB', {
  style: 'percent',
  minimumFractionDigits: 2,
});

export function formatPercentage(value: number | string) {
  const numberValue = Number(value);
  let usedValue = isNaN(numberValue) ? 0 : numberValue;
  if (usedValue > 1) {
    usedValue = usedValue / 100;
  }

  return formatter.format(usedValue);
}
