import { AssetDeliveryTaskForm } from '../../components/Views/AssetDeliveryTaskForm';
import { FlowContext } from '../../hooks/useFlowContext';

export function SelectAssetsForPod(props) {
  return (
    <FlowContext.Provider value={props}>
      <AssetDeliveryTaskForm flowProps={props} />
    </FlowContext.Provider>
  );
}
