import clsx from 'clsx';

type TaskItemBadgeProps = {
  status?: 'delete' | 'completed' | 'active' | 'unknown';
  className?: string;
  style?: { [key: string]: string };
};

export function TaskItemBadge({
  status,
  className,
  style,
}: TaskItemBadgeProps) {
  switch (status) {
    case 'delete':
      return (
        <div
          style={style}
          className={clsx(
            'h-4 w-4 rounded-full bg-transparent borderSize  border-red-500',
            className,
          )}
        />
      );
    case 'completed':
      return (
        <div
          style={style}
          className={clsx(
            'h-4 w-4 rounded-full bg-transparent borderSize border-green-600',
            className,
          )}
        />
      );
    case 'active':
      return (
        <div
          style={style}
          className={clsx(
            'h-4 w-4 rounded-full bg-transparent borderSize  border-yellow-500',
            className,
          )}
        />
      );
    default:
      return (
        <div
          style={style}
          className={clsx(
            'h-4 w-4 rounded-full bg-transparent borderSize  border-gray-500',
            className,
          )}
        />
      );
  }
}
