import { useForm, FormProvider } from 'react-hook-form';
import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { RadioButtonList } from '../_shared/Inputs/RadioButtonList/RadioButtonList';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
export function ManualOnboardingTaskForm({ flowProps }) {
  const method = useForm({
    resolver: zodResolver(
      z.object({
        customerRiskRating: z.string({
          invalid_type_error: 'Please decide customer risk',
        }),
        customerLifecycle: z.string({
          invalid_type_error: 'Please select a lifecycle situation',
        }),
        decision: z.string({ invalid_type_error: 'Please make a decision' }),
      }),
    ),
    defaultValues: {
      customerRiskRating: flowProps?.task?.data?.customerRiskRating || null,
      customerLifecycle: flowProps?.task?.data?.customerLifecycle || null,
      decision: null,
    },
  });

  const { handleSubmit, watch } = method;
  const { complete, save } = flowProps;
  function onSubmit(data) {
    complete(data);
  }

  const customerRiskRating = watch('customerRiskRating');
  const customerLifecycle = watch('customerLifecycle');
  return (
    <FormProvider {...method}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="h-full  flex flex-col gap-2 items-center px-24 pt-4 justify-start bg-gray-100"
      >
        <div className="w-full flex flex-col gap-4">
          <Box className="p-8">
            <div className="flex flex-col gap-6">
              <h3 className="text-black text-lg pb-4">Manual KYC</h3>
              <div className="flex flex-col gap-2">
                <label
                  className="text-md font-medium"
                  htmlFor="customerRiskRating"
                >
                  Customer Risk Rating
                </label>
                <RadioButtonList
                  id="customerRiskRating"
                  options={[
                    { label: 'High', value: 'high' },
                    { label: 'Medium', value: 'medium' },
                    { label: 'Low', value: 'low' },
                  ]}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="text-md font-medium"
                  htmlFor="customerLifecycle"
                >
                  Customer Lifecycle
                </label>
                <RadioButtonList
                  id="customerLifecycle"
                  options={[
                    { label: 'Active customer', value: 'active' },
                    {
                      label: 'Blocked due to missing information',
                      value: 'blockedMissing',
                    },
                    {
                      label: 'Blocked due to suspicious activities',
                      value: 'blockedSuspicious',
                    },
                    { label: 'Offboarded customer', value: 'offboarded' },
                    { label: 'No customer (N/A)', value: 'noCustomer' },
                  ]}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-md font-medium" htmlFor="decision">
                  Decision
                </label>
                <RadioButtonList
                  id="decision"
                  options={[
                    { label: 'Approve', value: 'approve' },
                    { label: 'Reject', value: 'reject' },
                  ]}
                />
              </div>
            </div>
          </Box>
          <div className="flex gap-2">
            <Button type="submit">Complete</Button>
            <Button
              onClick={() => save({ customerRiskRating, customerLifecycle })}
              intent="secondary"
              type="button"
            >
              Save and close
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
