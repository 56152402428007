import { ArrowIcon } from '../icons/Arrow';
import { PlusIcon } from '../icons/PlusIcon';

type WidgetCardProps = {
  imgSrc?: string;
  text?: string;
  url?: string;
  isLast?: boolean;
};

export const WidgetCard = ({
  imgSrc,
  text = '',
  isLast,
  url,
}: WidgetCardProps) => {
  if (isLast) {
    return (
      <button className="bg-transparent text-sm twofifty rounded-md border-gray-400 border border-dashed hover:border-gray-800 hover:text-gray-800 text-gray-400 py-6 px-12 gap-2 flex items-center justify-center flex-col w-fit">
        <span>
          <PlusIcon />
        </span>
        <p>Add shortcut</p>
      </button>
    );
  }
  return (
    <a
      href={url}
      target="_blank"
      className="relative transform transition ease-in-out hover:scale-105 hover:shadow-2xl text-white shadow-lg rounded-md flex flex-col justify-end  "
    >
      <div className="absolute blackToTransparentGradient rounded-md top-0 left-0 w-full h-full"></div>
      <img
        height={250}
        width={250}
        alt=""
        className="rounded-md "
        src={imgSrc}
      ></img>
      <div className="flex absolute bottom-2 left-0 px-4 w-full flex-row justify-between items-center gap-6">
        <span className="font-medium">{text}</span>
        <ArrowIcon />
      </div>
    </a>
  );
};
