import { VerifyInvoiceTaskForm } from '../../components/Views/VerifyInvoiceTaskForm';
import { FlowContext } from '../../hooks/useFlowContext';

export function VerifyInvoice(props) {
  return (
    <FlowContext.Provider value={props}>
      <VerifyInvoiceTaskForm flowProps={props} />
    </FlowContext.Provider>
  );
}
