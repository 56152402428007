export function OfficeBuildingsIcon({ size = 24 }) {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor">
        <path d="M7.5 15.75a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75ZM8.25 18a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3ZM7.5 12.75a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75Z" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.002.794A.75.75 0 0 0 12 1.5v3.75a.75.75 0 0 0 1.5 0V2.564l9 3.215V22.5H15V8.25a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75V22.5h-3v-9h.75a.75.75 0 0 0 0-1.5H.75a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75h22.5a.75.75 0 0 0 .75-.75v-18c0-.317-.2-.6-.498-.706l-10.5-3.75ZM13.5 9v13.5H6V9h7.5Z"
        />
        <path d="M17.25 6a.75.75 0 0 1 .75.75v12a.75.75 0 0 1-1.5 0v-12a.75.75 0 0 1 .75-.75ZM21 8.25a.75.75 0 0 0-1.5 0v10.5a.75.75 0 0 0 1.5 0V8.25Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
