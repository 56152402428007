import { useQuery } from '@tanstack/react-query';
import { getAllTasks } from '../../api/api';
import { Loader } from '../Loader/Loader';
import clsx from 'clsx';

export function CaseOverViewFullTaskList({ flowId }) {
  const {
    data: tasks,
    isFetching,
    isError,
    isSuccess,
  } = useQuery({
    queryFn: async () => getAllTasks(flowId, null) as any,
    queryKey: ['tasks', flowId],
  });

  if (isError) {
    return <div>Something went wrong</div>;
  }

  if (isFetching) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-4 py-2">
        <h1 className="px-6">All historical tasks</h1>
        {tasks?.data?.map((task) => {
          if (task?.taskCategory === 'user-task') {
            const status = taskStatusMapper(task);
            return (
              <div className="flex flex-row justify-between p-2 w-1/2 items-center border-b border-t border-gray-200">
                <span className="capitalize pl-4 text-sm">
                  {task?.taskType?.replaceAll('-', ' ')}
                </span>
                <div
                  className={clsx(
                    status.statusColor,
                    ' rounded-full px-4 py-1 flex items-center',
                  )}
                >
                  <span className="text-xs">{status?.statusText}</span>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export const taskStatusMapper = (task) => {
  switch (task?.status) {
    case 'completed':
      return {
        queue: 'completed-cases-queue',
        statusColor: 'bg-green-200 text-green-800',
        statusText: 'Done',
      };
    case 'active':
      return {
        queue: 'active-cases-queue',
        statusColor: 'bg-yellow-200 text-yellow-800',
        statusText: 'In progress',
      };
    case 'pending':
      return {
        queue: 'active-cases-queue',
        statusColor: 'bg-yellow-200 text-yellow-800',
        statusText: 'In progress',
      };
    case 'failed':
      return {
        queue: 'failed-cases-queue',
        statusColor: 'bg-red-200 text-red-800',
        statusText: 'Failed',
      };
    case 'cancelled':
      return {
        queue: 'failed-cases-queue',
        statusColor: 'bg-red-200 text-red-800',
        statusText: 'Cancelled',
      };
    default:
      return {
        queue: 'active-cases-queue',
        statusColor: 'bg-yellow-500',
        statusText: 'In progress',
      };
  }
};
