import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts" // Convert seconds to 'D days, H hours, M minutes, S seconds'

// Convert seconds to 'D days, H hours, M minutes, S seconds'
const formatDuration = (seconds) => {
  const days = Math.floor(seconds / 86400)
  const hours = Math.floor((seconds - days * 86400) / 3600)
  const minutes = Math.ceil((seconds - hours * 3600) / 60)

  const daysString = days > 0 ? `${days}d` : ""
  const hoursString = hours > 0 ? `${hours}t` : ""
  const minutesString = minutes > 0 ? `${minutes}m` : ""

  return `${daysString}${days > 0 && hours > 0 ? "," : ""}${hoursString}${
    hours > 0 ? "," : ""
  }${days > 0 ? "" : minutesString}`
}

export function ChartBarSequences({ data }) {
  return (
    <div
      className="relative w-full"
      style={{ paddingBottom: `${data.length * 30 + 10}px` }}
    >
      <div className="absolute bottom-0 left-0 right-0 top-0">
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 0,
              left: 0,
              right: 60,
              bottom: 0,
            }}
            layout="vertical"
          >
            <XAxis type={"number"} hide />
            <YAxis
              type="category"
              width={60}
              dataKey="date"
              axisLine={false}
              tickLine={false}
            />

            <Bar dataKey="value" fill={"#452268"} radius={9999} barSize={8}>
              <LabelList
                dataKey="value"
                position="right"
                formatter={formatDuration}
              />
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index % 2 === 0 ? "#AB80D6" : "#8FB0FF"}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
