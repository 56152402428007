import { useFormConnect } from '../../../../hooks/useFormConnect';
import { InputBox } from '../../Inputs/InputBox';
import { TextArea } from '../../Inputs/TextArea/TextArea';

export const Comment = ({
  name,
  label = '',
  placeholder = '',
  disabled = false,
}) => {
  const props = useFormConnect(name);
  return (
    <TextArea
      disabled={disabled}
      placeholder={placeholder}
      label={label}
      {...props}
    ></TextArea>
  );
};
