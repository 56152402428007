import axios from 'axios';

export async function fetchAttachmentsByFlowId(flowId: string) {
  return axios
    .get(`/api/flow/flows/${flowId}/attachments`)
    .then((response) => response.data);
}

export async function fetchAttachmentByKey(
  flowId: string,
  attachmentKey: string,
) {
  return axios
    .get(`/api/flow/flows/${flowId}/attachments/${attachmentKey}`)
    .then((response) => response.data);
}

export async function downloadAttachment(flowId, attachmentKey) {
  try {
    const { attachment } = await fetchAttachmentByKey(flowId, attachmentKey);
    const { data } = await axios.get(
      `/api/flow/flows/${flowId}/attachments/${attachmentKey}/download`,
      {
        responseType: 'blob',
      },
    );
    console.log(attachment);
    const file = new Blob([data], { type: attachment.mimetype });
    return URL.createObjectURL(file);
  } catch (e) {
    throw new Error(e as any);
  }
}
