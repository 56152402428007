import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FinancialStatement } from '../../types/creditscore';
import { formatMoney } from '../../utils/formatMoney';
import { BarStriped } from './ChartBarBalance.BarStriped';
import { ChartBalanceTooltip } from './ChartBarBalance.Tooltip';
import {
  ChartBalanceBarLabelAssets,
  ChartBalanceBarLabelLiabilities,
  ChartBalanceBarLabelLiabilitiesNegativeEquity,
} from './ChartBarBalance.BarLabel';
import { formatBarBalanceData } from './ChartBarBalance.formatData';

type ChartBarBalanceProps = {
  financialStatements: FinancialStatement[];
};

const formatYAxisTick = (value: number) => {
  return formatMoney(value);
};

function ChartBarBalance({ financialStatements }: ChartBarBalanceProps) {
  const chartData = formatBarBalanceData(financialStatements);

  const colors = {
    currentAssets: '#2969FF',
    fixedAssets: '#A2C9FF',
    debt: '#E96C00',
    equity: '#FDDFA5',
  };

  return (
    <div className="grid gap-2">
      <div className="flex justify-center gap-8 pt-4">
        <div className="flex p-1 rounded-md bg-blue-50 font-medium">
          <div className="flex gap-2 px-2 items-center">
            <span
              className="block h-4 w-4"
              style={{ backgroundColor: colors.currentAssets }}
            />
            <span>Current assets</span>
          </div>
          <div className="flex gap-2 px-2 items-center">
            <span
              className="block h-4 w-4"
              style={{ backgroundColor: colors.fixedAssets }}
            />
            <span>Fixed assets</span>
          </div>
        </div>
        <div
          className="flex p-1 rounded-md font-medium"
          style={{ backgroundColor: '#FEF7E9' }}
        >
          <div className="flex gap-2 px-2 items-center">
            <span
              className="block h-4 w-4"
              style={{ backgroundColor: colors.debt }}
            />
            <span>Debt</span>
          </div>
          <div className="flex gap-2 px-2 items-center">
            <span
              className="block h-4 w-4"
              style={{ backgroundColor: colors.equity }}
            />
            <span>Equity</span>
          </div>
        </div>
      </div>
      <div
        className="w-full pt-8"
        style={{
          height: '200px',
        }}
      >
        <ResponsiveContainer>
          <BarChart
            data={chartData}
            margin={{
              top: 15,
              right: 30,
              left: 30,
              bottom: 5,
            }}
          >
            <pattern
              id="pattern-stripe"
              width="8"
              height="8"
              patternUnits="userSpaceOnUse"
              patternTransform="rotate(45)"
            >
              <rect
                width="4"
                height="8"
                transform="translate(0,0)"
                fill="white"
              ></rect>
            </pattern>
            <mask id="mask-stripe">
              <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="url(#pattern-stripe)"
              />
            </mask>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="year" />
            <YAxis tickFormatter={(val) => formatYAxisTick(val)} />
            <Tooltip content={<ChartBalanceTooltip />} />

            <Bar
              dataKey="currentAssets"
              stackId="assets"
              fill={colors.currentAssets}
            ></Bar>
            <Bar
              dataKey="fixedAssets"
              stackId="assets"
              fill={colors.fixedAssets}
            >
              <LabelList
                content={<ChartBalanceBarLabelAssets />}
                position="center"
                dataKey="assets"
                name="assets"
              />
            </Bar>
            <Bar
              dataKey="debtWithoutEquity"
              stackId="liabilities"
              fill={colors.debt}
            >
              <LabelList
                content={<ChartBalanceBarLabelLiabilitiesNegativeEquity />}
                position="center"
                dataKey="liabilities"
                name="liabilities"
              />
            </Bar>
            <Bar
              dataKey="equity"
              stackId="liabilities"
              fill={colors.equity}
              shape={<BarStriped fill={colors.equity} />}
            >
              <LabelList
                content={<ChartBalanceBarLabelLiabilities />}
                position="center"
                dataKey="liabilities"
                name="liabilities"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export { ChartBarBalance };
