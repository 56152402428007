import {
  Button,
  DatePicker,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
} from "../ui"
import { format, parseISO } from "date-fns"
import nbLocale from "date-fns/locale/nb"
import { ChevronDownIcon } from "lucide-react"

export function DateRangePopover({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  disabled,
  t,
}) {
  const handleStartDateChange = (value) => {
    const actualDate = new Date(
      value?.getTime() + Math.abs(value?.getTimezoneOffset() * 60000)
    )
    const isoString = actualDate?.toISOString().split("T")[0]
    if (onChangeStartDate) {
      onChangeStartDate(isoString ?? "")
    }
  }

  const handleEndDateChange = (value) => {
    const actualDate = new Date(
      value?.getTime() + Math.abs(value?.getTimezoneOffset() * 60000)
    )
    const isoString = actualDate?.toISOString().split("T")[0]
    if (onChangeEndDate) {
      onChangeEndDate(isoString ?? "")
    }
  }

  const disabledStartDates = {
    after: new Date(),
  }

  const disabledEndDates = {
    before: startDate ? new Date(startDate) : new Date(),
    after: new Date(),
  }

  const formattedStartDate = startDate
    ? format(parseISO(startDate), "d. MMM", { locale: nbLocale })
    : format(new Date(), "d. mmm", { locale: nbLocale })

  const formattedEndDate = endDate
    ? format(parseISO(endDate), "d. MMM", { locale: nbLocale })
    : null

  return (
    <div>
      <Popover>
        <PopoverTrigger asChild>
          <Button disabled={disabled}>
            <span className="flex items-center gap-2">
              <span>
                <span>
                  {formattedStartDate && `Fra ${formattedStartDate}`}
                  {formattedEndDate && ` til ${formattedEndDate}`}
                </span>
              </span>
              <ChevronDownIcon />
            </span>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <div>
            <Stack>
              {startDate && (
                <div>
                  <span className="mb-1 block font-semibold">
                    {t("date-from")}
                  </span>
                  <DatePicker
                    onChange={handleStartDateChange}
                    disabledDays={disabledStartDates}
                    defaultValue={new Date(startDate)}
                  />
                </div>
              )}
              {endDate && (
                <div>
                  <span className="mb-1 block font-semibold">
                    {t("date-to")}
                  </span>
                  <DatePicker
                    onChange={handleEndDateChange}
                    disabledDays={disabledEndDates}
                    defaultValue={new Date(endDate)}
                  />
                </div>
              )}
            </Stack>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
