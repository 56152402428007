import { ValueOf } from "type-fest";

export const COUNTRY = {
  NORWAY: "norway",
  DENMARK: "denmark",
  FINLAND: "finland",
  SWEDEN: "sweden"
} as const;

export type Country = (typeof COUNTRY)[keyof typeof COUNTRY];

export const COUNTRY_CODE = {
  NORWAY: "no",
  SWEDEN: "se",
  FINLAND: "fi",
  DENMARK: "dk"
} as const;

export type CountryCode = ValueOf<typeof COUNTRY_CODE>;

export const APPLICATION_STATUS = {
  IN_PROGRESS: "InProgress",
  APPROVED: "Approved",
  AWAITING_SIGNATURE: "AwaitingSignature",
  DECLINED: "Declined",
  MANUAL_REVIEW: "ManualReview",
  CANCELLED: "Cancelled",
  ERROR: "Error",
  COMPLETED: "Completed",
  TIMED_OUT: "TimedOut"
};
export type ApplicationStatus = ValueOf<typeof APPLICATION_STATUS>;

export const BUSINESS_STATUS = {
  CREATED: "CREATED",
  CREDIT_SCORE: "CREDIT_SCORE",
  AUTHENTICATION: "AUTHENTICATION",
  AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
  AUTHENTICATION_COMPLETED: "AUTHENTICATION_COMPLETED",
  APPLICATION_INFORMATION: "APPLICATION_INFORMATION",
  MANUAL_HANDLING: "MANUAL_HANDLING",
  UPLOAD_SIGNING_RIGHTS_DOCUMENTATION: "UPLOAD_SIGNING_RIGHTS_DOCUMENTATION",
  EVALUATE_SIGNING_RIGHTS_DOCUMENTATION:
    "EVALUATE_SIGNING_RIGHTS_DOCUMENTATION",
  EVALUATE_SIGNING_RIGHTS_DOCUMENTATION_APPROVED:
    "EVALUATE_SIGNING_RIGHTS_DOCUMENTATION_APPROVED",
  EVALUATE_SIGNING_RIGHTS_DOCUMENTATION_REJECTED:
    "EVALUATE_SIGNING_RIGHTS_DOCUMENTATION_REJECTED",
  CREDIT_SCORE_REJECTED: "CREDIT_SCORE_REJECTED",
  NO_DNB_COMPANY_DATA: "NO_DUN_AND_BRADSTREET_RECORDS",
  NO_DNB_PERSON_DATA: "NO_DNB_PERSON_DATA",
  SANCTION_SCREENING: "SANCTION_SCREENING",
  COMPLIANCE_CHECK: "COMPLIANCE_CHECK",
  COMPLIANCE_CHECK_REJECTED: "COMPLIANCE_CHECK_REJECTED",
  APPROVE_CREDIT_DECISION: "APPROVE_CREDIT_DECISION",
  APPROVE_CREDIT_DECISION_DECLINED: "APPROVE_CREDIT_DECISION_DECLINED",
  MANUAL_HANDLING_REJECTED: "MANUAL_HANDLING_REJECTED",
  FOUR_EYES_VERIFICATION_LOW: "FOUR_EYES_VERIFICATION_LOW",
  FOUR_EYES_VERIFICATION_MEDIUM: "FOUR_EYES_VERIFICATION_MEDIUM",
  FOUR_EYES_VERIFICATION_HIGH: "FOUR_EYES_VERIFICATION_HIGH",
  SELECT_INVOICING_OPTION: "SELECT_INVOICING_OPTION",
  SIGNING: "SIGNING",
  CHOOSE_SIGNERS: "CHOOSE_SIGNERS",
  NO_ROARING_RECORDS: "NO_ROARING_RECORDS",
  SIGN_DOCUMENTS: "SIGN_DOCUMENTS",
  SIGNING_FAILED: "SIGNING_FAILED",
  SIGNING_PACKAGING_FAILED: "SIGNING_PACKAGING_FAILED",
  SIGNING_RESET: "SIGNING_RESET",
  SIGNING_COMPLETED: "SIGNING_COMPLETED",
  COMPLETED_APPROVED: "COMPLETED_APPROVED",
  COMPLETED_REJECTED: "COMPLETED_REJECTED",
  ERROR: "ERROR"
} as const;

export type BusinessStatus = ValueOf<typeof BUSINESS_STATUS>;

export const CUSTOMER_TYPE = {
  B2B: "b2b",
  B2C: "b2c"
} as const;

export type CustomerType = (typeof CUSTOMER_TYPE)[keyof typeof CUSTOMER_TYPE];

export const TOPIC = {
  CRIME: "crime",
  FRAUD: "crime.fraud",
  CYBERCRIME: "crime.cyber",
  FINANCIAL_CRIME: "crime.fin",
  ENVIRONMENTAL_VIOLATIONS: "crime.env",
  THEFT: "crime.theft",
  WAR_CRIMES: "crime.war",
  CRIMINAL_LEADERSHIP: "crime.boss",
  TERRORISM: "crime.terror",
  TRAFFICKING: "crime.traffick",
  DRUG_TRAFFICKING: "crime.traffick.drug",
  HUMAN_TRAFFICKING: "crime.traffick.human",
  OFFSHORE: "corp.offshore",
  SHELL_COMPANY: "corp.shell",
  PUBLIC_LISTED_COMPANY: "corp.public",
  DISQUALIFIED: "corp.disqual",
  GOVERNMENT: "gov",
  NATIONAL_GOVERNMENT: "gov.national",
  STATE_GOVERNMENT: "gov.state",
  MUNICIPAL_GOVERNMENT: "gov.muni",
  STATE_OWNED_ENTERPRISE: "gov.soe",
  INTERGOVERNMENTAL_ORGANIZATION: "gov.igo",
  HEAD_OF_GOVERNMENT_OR_STATE: "gov.head",
  CIVIL_SERVICE: "gov.admin",
  EXECUTIVE_BRANCH_OF_GOVERNMENT: "gov.executive",
  LEGISLATIVE_BRANCH_OF_GOVERNMENT: "gov.legislative",
  JUDICIAL_BRANCH_OF_GOVERNMENT: "gov.judicial",
  SECURITY_SERVICES: "gov.security",
  CENTRAL_BANKING_AND_FINANCIAL_INTEGRITY: "gov.financial",
  FINANCIAL_SERVICES: "fin",
  BANK: "fin.bank",
  FUND: "fin.fund",
  FINANCIAL_ADVISOR: "fin.adivsor",
  PEP: "role.pep",
  CLOSE_ASSOCIATE: "role.rca",
  JUDGE: "role.judge",
  CIVIL_SERVANT: "role.civil",
  DIPLOMAT: "role.diplo",
  LAWYER: "role.lawyer",
  ACCOUNTANT: "role.acct",
  SPY: "role.spy",
  OLIGARCH: "role.oligarch",
  JOURNALIST: "role.journo",
  ACTIVIST: "role.act",
  POLITICAL_PARTY: "pol.party",
  UNION: "pol.union",
  RELIGION: "rel",
  MILITARY: "mil",
  FROZEN_ASSET: "asset.frozen",
  SANCTIONED_ENTITY: "sanction",
  SANCTION_LINKED_ENTITY: "sanction.linked",
  EXPORT_CONTROLLED: "export.control",
  DEBARRED_ENTITY: "debarment",
  POI: "poi"
} as const;

export type Topic = ValueOf<typeof TOPIC>;

export const APPLICATION_TYPE = {
  CREDIT: "credit",
  ELEVATE_TO_ADMIN: "elevatetoadmin"
} as const;
