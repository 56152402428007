import { PROCESSES } from '../types/processes';
import { FEATURES } from '@flow/case-manager';

interface BaseSearchField {
  name: string;
  value: string;
  type?: 'select' | 'date' | 'text';
  process?: string[];
}
interface TextSearchfield extends BaseSearchField {
  type: 'text';
  queryOperator?: 'equals' | 'contains';
}

interface SelectSearchField extends BaseSearchField {
  type: 'select';
  options: { label: string; value: string }[];
  multiple?: boolean;
}
interface DateSearchField extends BaseSearchField {
  type: 'date';
  queryOperator: 'gt' | 'gte' | 'lt' | 'lte';
  min?: string;
  max?: string;
}

export type SearchField = TextSearchfield | SelectSearchField | DateSearchField;

interface SearchResultFields {
  name: string;
  value: string;
  process?: string[];
  mapping?: (value: any) => any;
}

const searchFields: SearchField[] = [
  {
    name: 'Customer name',
    value: 'data.currentContractDetails.Contract.Buyer.Organisation.Name',
    type: 'text',
    queryOperator: 'contains',
  },
  {
    name: 'Customer ID',
    value: 'data.currentContractDetails.Contract.Buyer.Organisation.VATnr',
    type: 'text',
  },
  {
    name: 'Supplier name',
    value: 'data.currentContractDetails.Contract.Seller.Organisation.Name',
    type: 'text',
    queryOperator: 'contains',
  },
  {
    name: 'Supplier ID',
    value: 'data.currentContractDetails.Contract.Seller.Organisation.VATnr',
    type: 'text',
  },
  {
    name: 'Contract type',
    value: 'referenceName',
    type: 'select',
    options: [
      { label: 'Asset', value: 'asset' },
      { label: 'Car', value: 'car' },
    ],
  },
];

const searchResultFields: SearchResultFields[] = [
  { name: 'Flow ID', value: 'flowId' },
  { name: 'Flow ID', value: 'flowId' },
];

export const searchConfiguration = {
  search: {
    fields: searchFields,
  },
  searchResults: {
    fields: searchResultFields,
  },
  searchProcesses: [PROCESSES.INITIATE_CONTRACT],
  searchQueryView: 'searchView',
  features: [FEATURES.CaseNotes, FEATURES.CaseAssignment],
};
