type SplitListItemProps = {
  label: string;
  children: React.ReactNode;
};

function SplitListItem({ label, children }: SplitListItemProps) {
  return (
    <li className="flex justify-between w-full py-2 gap-4">
      <span>{label}</span>
      <div>{children}</div>
    </li>
  );
}

export { SplitListItem };
