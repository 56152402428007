// @flow
import * as React from 'react';
// import SBLCard from './SBLCard';
import SBLHeader from './SBLHeader';
import { Applicant } from '../../types/ManualDecisionContext';
import SBLCard from './SBLCard';

export type SBLProps = {
  applicants: Applicant[];
  t: (key: string) => string;
};
export const SblContainer = ({ applicants, t }: SBLProps) => {
  return (
    <div className={'grid gap-y-10  w-full h-full p-2'}>
      {applicants?.map((applicant, index) => {
        const employers = applicant.sbl?.incomeData?.employerIncome;
        return (
          <div key={applicant?.name + index} className="flex flex-col gap-5">
            <SBLHeader
              name={applicant?.name ?? 'Intet navn funnet'}
              identifier={applicant.nationalId}
              incomeTotal={
                applicant?.sbl?.incomeData?.incomeAveragesAccepted?.total ?? 0
              }
              t={t}
            />
            {employers?.map((employer, index) => (
              <SBLCard
                key={employer?.virksomhetId + index}
                employer={employer}
                t={t}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};
