type KeyNumbersData = {
  year: string;
  profitPercentage: number;
  grossProfit: number;
  totalProfitability: number;
  interestCoverageRatio: number;
  debtServicingAbility: number;
};

// Lets start by trying to make a sane structure for the data that we should be able to use in the table
// We'll need to make a function to transform D&B data into this format. Note that D&B data is structured differently per country (!)
const fakeKeyNumbersData = [
  {
    year: '2015',
    profitPercentage: -0.025,
    grossProfit: 0.56,
    totalProfitability: -0.031,
    interestCoverageRatio: -1.02,
    debtServicingAbility: 0.2,
  },
  {
    year: '2016',
    profitPercentage: -0.025,
    grossProfit: 0.56,
    totalProfitability: -0.031,
    interestCoverageRatio: -1.02,
    debtServicingAbility: 0.2,
  },
  {
    year: '2017',
    profitPercentage: -0.025,
    grossProfit: 0.56,
    totalProfitability: -0.031,
    interestCoverageRatio: -1.02,
    debtServicingAbility: 0.2,
  },
];

const rowHeaders = [
  {
    key: 'profitPercentage',
    label: 'Profit percentage',
  },
  {
    key: 'grossProfit',
    label: 'Gross profit',
  },
  {
    key: 'totalProfitability',
    label: 'Total profitability',
  },
  {
    key: 'interestCoverageRatio',
    label: 'Interest coverage ratio',
  },
  {
    key: 'debtServicingAbility',
    label: 'Debt servicing ability',
  },
];

type TableResultKeyFiguresProps = {
  keyNumbersData?: KeyNumbersData[];
};

function TableResultKeyFigures({
  keyNumbersData = fakeKeyNumbersData,
}: TableResultKeyFiguresProps) {
  return (
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th className="w-4 border-b border-gray-200 bg-blue-50 px-2"></th>
          <th className="text-left w-auto border-b border-gray-200 bg-blue-50">
            Key numbers
          </th>
          {keyNumbersData.map((result) => {
            return (
              <th className="py-2 pr-4 w-1/4 text-right border-l border-b border-gray-200 bg-blue-50">
                {result.year}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="divide-y">
        {rowHeaders.map((rowHeader) => {
          return (
            <tr>
              {/* Use meta field to render correct icon. either '=' for sum, "-" for cost */}
              <td className={`w-4 py-2 px-2`}></td>
              <td
                className={`py-2 w-auto ${rowHeader.key.includes('.') ? 'pl-4' : 'font-bold'}`}
              >
                {rowHeader.label}
              </td>
              {keyNumbersData.map((resultYear) => {
                return (
                  <td className="py-2 pr-4 w-1/4 text-right border-l border-gray-100">
                    {rowHeader.key === 'debtServicingAbility'
                      ? resultYear[rowHeader.key]
                      : rowHeader.key === 'interestCoverageRatio'
                        ? resultYear[rowHeader.key]
                        : `${parseFloat((resultYear[rowHeader.key] * 100).toFixed(2))} %`}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export { TableResultKeyFigures };
