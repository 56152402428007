import { useFormConnect } from '../../../../hooks/useFormConnect';
import { CarIcon } from '../../../icons/Car';
import { PlusIcon } from '../../../icons/PlusIcon';
export function DateInput({
  label,
  id,
  disabled = false,
}: {
  label: string;
  id: string;
  disabled?: boolean;
}) {
  const { name, value, onChange, error } = useFormConnect(id);
  return (
    <div className="flex flex-col gap-1">
      <label htmlFor={id}>{label}</label>
      <div className="relative w-full">
        <input
          type="date"
          id={id}
          name={name}
          value={value}
          pattern="dd-MM-yyyy"
          onChange={onChange}
          className={
            'focus:outline-none h-input rounded-md bg-transparent  z-0 float-right w-full cursor-pointer justify-self-end border border-misc-ui-separator bg-input p-2.5 focus:border-transparent focus:ring-2'
          }
          disabled={disabled}
        />
        <div className="dateIcon bg-transparent">
          {/*           <PlusIcon />
           */}{' '}
        </div>
      </div>
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}
