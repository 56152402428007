export function TrendArrow() {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m13.038 5.548 9.705 10.783-1.486 1.338-8.295-9.217L7 14.414 1.293 8.707l1.414-1.414L7 11.586l6.038-6.038Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 9v9h-9v-2h7V9h2Z"
        fill="currentColor"
      />
    </svg>
  );
}
