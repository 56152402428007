import { format } from "date-fns"
import nbLocale from "date-fns/locale/nb"

export function Greeting({ t }) {
  const friendlyDate = format(new Date(), "EEEE d. LLLL yyyy", {
    locale: nbLocale,
  })

  return (
    <div>
      <span className="text-sm first-letter:uppercase">
        {friendlyDate}
      </span>
      <h1 className="text-4xl font-semibold">{t("good-morning")}</h1>
      <span className="text-sm">{t("kpi-dashboard-header")}</span>
    </div>
  )
}
