import { ChartAggregateCounts } from '../ui';

export function AggregateCounts({ counts }) {
  const total = counts.reduce((acc, curr) => acc + curr.statistics.count, 0);

  const aggregate = counts.map((count) => {
    return {
      name: count.name,
      value: count.statistics.count,
    };
  });

  return <ChartAggregateCounts data={aggregate} totalCount={total} />;
}
