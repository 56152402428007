import { useQuery } from '@tanstack/react-query';
import {
  downloadAttachment,
  fetchAttachmentByKey,
} from '../../../utils/attachments';
import { Loader } from '../../Loader/Loader';

export function Attachment({
  flow,
  attachmentKey = 'invoice',
  mimeType = 'application/pdf',
}) {
  const { data, error, isFetching } = useQuery({
    queryFn: async () => downloadAttachment(flow?.flow?.flowId, attachmentKey),
    queryKey: [flow?.task?.taskId],
  });

  if (isFetching) {
    return (
      <div className=" w-full p-8 flex justify-center flex-col items-center gap-2">
        <Loader />
        <p>Fetching invoice</p>
      </div>
    );
  }

  const isImage = mimeType.includes('image');
  const isPdf = mimeType.includes('pdf');
  return (
    <div>
      {isImage && <img src={data} alt="file" className="w-full" />}
      {isPdf && (
        <iframe className={'h-screen w-full'} title="file" src={data} />
      )}
    </div>
  );
}
