import { FormProvider, useForm } from 'react-hook-form';
import { Comment } from '../_shared/forms/fields/Comment';
import { Button } from '../Button/Button';
import { ThreeOneLayout } from '../Layout/ThreeOneLayout';
import { Box } from '../Box/Box';
import { RadioButtonList } from '../_shared/Inputs/RadioButtonList/RadioButtonList';

export function AssetDeliveryTaskForm({ flowProps }) {
  const { task } = flowProps;
  const method = useForm({
    defaultValues: {
      comment: '',
      assetSplit: 'all',
    },
  });
  const { handleSubmit } = method;
  const onSubmit = (data) => {
    flowProps.complete(data);
  };

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <ThreeOneLayout>
          <ThreeOneLayout.Left>
            <Box className="grid gap-2">
              <h4 className="text-black font-medium">Type of payment</h4>
              <RadioButtonList
                horizontal={true}
                disabled
                options={[
                  { label: 'All assets', value: 'all' },
                  {
                    label: 'Split assets in separate POD',
                    value: 'separate',
                  },
                ]}
                id="assetSplit"
              />
            </Box>
            <Box className="grid gap-2 grid-cols-4">
              <h3 className="text-black font-medium">POD Details</h3>
              <h5 className={`col-start-1`}>{'Brand'}</h5>
              <h5>{'Model'}</h5>
              <h5>{'Variant'}</h5>
              <h5>{'Price'}</h5>
              {task.context.assets.map((asset, index) => {
                return (
                  <>
                    <div key={`brand-${index}`}>
                      {asset.BrandName ?? asset.Brand}
                    </div>
                    <div key={`model-${index}`}>
                      {asset.ModelName ?? asset.Model}
                    </div>
                    <div key={`variant-${index}`}>{asset.VariantName}</div>
                    <div key={`price-${index}`}>{asset.Price}</div>
                  </>
                );
              })}
            </Box>
          </ThreeOneLayout.Left>
          <ThreeOneLayout.Right>
            <div></div>
            <div className="flex flex-col gap-4">
              <label
                className=" font-medium text-black"
                id="assessment.status"
                aria-label="decision"
              >
                Decision
              </label>
              <RadioButtonList
                id="assessment.status"
                options={[
                  { label: 'Send proof of delivery', value: 'sendPod' },
                  { label: 'Dont send POD on this case', value: 'noPod' },
                ]}
              />
              <Comment
                name="comment"
                label="Comment"
                placeholder="Enter a comment"
                disabled={task.status === 'completed'}
              />
              <Button type="submit">Submit</Button>
            </div>
          </ThreeOneLayout.Right>
        </ThreeOneLayout>
      </form>
    </FormProvider>
  );
}
