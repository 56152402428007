import { useFlowContext } from '../../hooks/useFlowContext';

interface RiskClassProps {
  riskClass: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined | null;
}

function RiskNumber({ children }: { children: React.ReactNode }) {
  return (
    <span className="absolute bottom-1/2 left-1/2 transform -translate-x-1/2 translate-y-1/2 font-semibold text-4xl">
      {children}
    </span>
  );
}

export function RedTriangle() {
  return (
    <svg
      width="96"
      height="84"
      viewBox="0 0 96 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.0001 6.06812L6.43089 78.0681H89.5693L48.0001 6.06812ZM51.4642 2.06812C49.9246 -0.598548 46.0756 -0.598555 44.536 2.06811L1.23474 77.0681C-0.304862 79.7348 1.61963 83.0681 4.69883 83.0681H91.3014C94.3806 83.0681 96.3051 79.7348 94.7655 77.0681L51.4642 2.06812Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function YellowTriangle() {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0001 5.58008C22.7813 5.58008 5.58008 22.7813 5.58008 44.0001C5.58008 65.2189 22.7813 82.4201 44.0001 82.4201C65.2189 82.4201 82.4201 65.2189 82.4201 44.0001C82.4201 22.7813 65.2189 5.58008 44.0001 5.58008ZM0.580078 44.0001C0.580078 20.0199 20.0199 0.580078 44.0001 0.580078C67.9803 0.580078 87.4201 20.0199 87.4201 44.0001C87.4201 67.9803 67.9803 87.4201 44.0001 87.4201C20.0199 87.4201 0.580078 67.9803 0.580078 44.0001Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function GreenTriangle() {
  return (
    <svg
      width="82"
      height="82"
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2801 5.1001C7.41926 5.1001 5.1001 7.41926 5.1001 10.2801V71.7201C5.1001 74.5809 7.41926 76.9001 10.2801 76.9001H71.7201C74.5809 76.9001 76.9001 74.5809 76.9001 71.7201V10.2801C76.9001 7.41926 74.5809 5.1001 71.7201 5.1001H10.2801ZM0.100098 10.2801C0.100098 4.65784 4.65784 0.100098 10.2801 0.100098H71.7201C77.3424 0.100098 81.9001 4.65784 81.9001 10.2801V71.7201C81.9001 77.3424 77.3424 81.9001 71.7201 81.9001H10.2801C4.65784 81.9001 0.100098 77.3424 0.100098 71.7201V10.2801Z"
        fill="currentColor"
      />
    </svg>
  );
}

function RiskClass({ riskClass }: RiskClassProps) {
  const { t } = useFlowContext();
  if (!riskClass) {
    return (
      <div className="text-surface-state-success flex flex-col justify-center items-center text-sm gap-2">
        <div className="inline-flex relative"></div>
        <h2 className="text-content-secondary uppercase font-medium">
          {t('risk-class-not-found')}
        </h2>
      </div>
    );
  }
  if (riskClass < 4) {
    return (
      <div className="text-surface-state-success flex flex-col justify-center items-center text-sm gap-2">
        <div className="inline-flex relative">
          <GreenTriangle />
          <RiskNumber>{riskClass}</RiskNumber>
        </div>
        <h2 className="text-content-secondary uppercase font-medium">
          {t('risk-class')}
        </h2>
      </div>
    );
  }
  if (riskClass < 7) {
    return (
      <div className="text-surface-state-warning flex flex-col justify-center items-center text-sm gap-2">
        <div className="inline-flex relative">
          <YellowTriangle />
          <RiskNumber>{riskClass}</RiskNumber>
        </div>
        <h2 className="text-content-secondary uppercase font-medium">
          {t('risk-class')}
        </h2>
      </div>
    );
  }
  return (
    <div className="text-surface-state-danger flex flex-col justify-center items-center text-sm gap-2">
      <div className="inline-flex relative">
        <RedTriangle />
        <RiskNumber>{riskClass}</RiskNumber>
      </div>
      <h2 className="text-content-secondary uppercase font-medium">
        {t('risk-class')}
      </h2>
    </div>
  );
}

export { RiskClass };
