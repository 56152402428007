import { Button } from "../ui"
import { useQueryErrorResetBoundary } from "@tanstack/react-query"
import { isAxiosError } from "axios"
import { ErrorBoundary } from "react-error-boundary"

export function FeaturedMetricsError({ children, t }) {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div className="rounded-lg bg-white p-4 text-black">
          <div className="flex justify-center">
            <div className="grid gap-4">
              <div>
                <strong className="block text-xl font-semibold text-negative-600">
                  {t("fetch-error")}
                </strong>

                {isAxiosError(error) ? (
                  <span className="text-negative-500">
                    {error?.response?.status}: {error.response?.statusText}
                  </span>
                ) : null}
              </div>
              <div>
                <Button
                  variant={"secondary"}
                  onClick={() => resetErrorBoundary()}
                >
                  {t("retry")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    >
      {children}
    </ErrorBoundary>
  )
}
