import { ChooseSignersTaskForm } from '../../components/Views/ChooseSignersTaskForm';
import { FlowContext } from '../../hooks/useFlowContext';

export function ChooseSigners(props) {
  return (
    <FlowContext.Provider value={props}>
      <ChooseSignersTaskForm flowProps={props} />
    </FlowContext.Provider>
  );
}
