interface BarPdLgdProps {
  pd: number;
  lgd: number;
}

const getPdPercentage = (pd: number) => {
  if (pd >= 0 && pd < 0.02) {
    return 5;
  }
  if (pd >= 0.02 && pd < 0.04) {
    return 20;
  }
  if (pd >= 0.04 && pd < 0.06) {
    return 40;
  }
  if (pd >= 0.06 && pd < 0.08) {
    return 60;
  }
  if (pd >= 0.08 && pd < 0.1) {
    return 80;
  }
  if (pd >= 0.1) {
    return 100;
  }
  return 0;
};

function BarPdLgd({ pd, lgd }: BarPdLgdProps) {
  const pdPercentage = getPdPercentage(pd);

  return (
    <div className="relative pb-16">
      <div className="grid gap-2">
        <h2 className="text-content-secondary uppercase font-medium text-sm">
          PD / LGD
        </h2>
        {!pd ? (
          <div className="text-content-secondary text-sm">
            <span className="text-content-secondary text-sm">
              Ingen PD og LGD hentet ennå
            </span>
          </div>
        ) : (
          <div className="flex h-8 relative rounded-md">
            <div className="bg-bar-success w-full" />
            <div className="bg-bar-warning w-full" />
            <div className="bg-bar-danger w-full" />
            <div className="bg-bar-dangerDark w-full" />
            <div
              className="absolute flex justify-center flex-col items-center text-sm gap-2 transform -translate-x-1/2"
              style={{ left: `${pdPercentage}%`, top: '-0.5rem' }}
            >
              <div className="w-2 h-12 bg-white opacity-80 shadow-md border border-gray-500 rounded-full"></div>
              <ul>
                <li>
                  <span className="font-medium text-sm uppercase text-content-secondary">
                    PD
                  </span>{' '}
                  <span className="font-medium text-content">
                    {(pd * 100).toFixed(2)} %
                  </span>
                </li>
                <li>
                  <span className="font-medium text-sm uppercase text-content-secondary">
                    LGD
                  </span>{' '}
                  <span className="font-medium text-content">
                    {(lgd * 100).toFixed(2)} %
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export { BarPdLgd };
