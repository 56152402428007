import { forwardRef, useState } from 'react';
import clsx from 'clsx';
import { ConnectedProps } from '../InputBox';
import { OfficeBuildingsIcon } from '../../../icons/OfficeBuildings';
import { formatDate } from '../../../../utils/formatDate';

export type TextAreaProps<T> = {
  name?: string;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
  inputClassNames?: string;
  userType?: string;
  reporter: {
    name: string;
    id: string;
  };
  date?: string;
} & ConnectedProps<T>;

// eslint-disable-next-line react/display-name
export const DecisionTextArea = forwardRef<
  HTMLInputElement,
  TextAreaProps<string>
>(
  ({
    onChange,
    value,
    name,
    label,
    placeholder,
    error,
    disabled,
    inputClassNames,
    userType,
    reporter,
    date,
  }) => {
    const errorId = `${name}-error`;
    const [hover, setHover] = useState(false);
    const roleStyle =
      userType === 'FIRST' ? ' bg-yellow-100' : 'bg-primary-100';

    const header = userType === 'FIRST' ? 'Caseworker' : '4 Eyes';
    return (
      <div className="rounded-md flex flex-col borderBottomRadius p-2 w-full resize-none">
        <span
          className={clsx(
            'text-black borderTopRadius p-3 flex items-center gap-2 ',
            roleStyle,
          )}
        >
          <OfficeBuildingsIcon />
          {header}
        </span>
        <div className=" flex flex-col gap-0 shadow-md">
          <textarea
            name={name}
            id={name}
            placeholder={placeholder}
            autoComplete="off"
            value={value}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
            className={'bg-white h-28 w-full p-2 resize-none'}
          />
          <div
            onMouseLeave={() => setHover(false)}
            onMouseEnter={() => setHover(true)}
            className="p-2 bg-white relative"
          >
            {hover && (
              <div className="relative w-fit">
                <span className="absolute -top-10 p-2 bg-yellow-200 shadow-md">
                  {reporter?.id}
                  <div className=" -rotate-45 w-2 h-2 bg-yellow-200 absolute left-1/2 -bottom-1 -translate-x-1/2 transform"></div>
                </span>
              </div>
            )}
            <span className="cursor-pointer">
              {' '}
              {reporter?.name ?? reporter}
            </span>
            , {date?.split('T')?.[0]}
          </div>
        </div>
        {error && (
          <p className="pt-2" id={errorId}>
            {error}
          </p>
        )}
      </div>
    );
  },
);
