import axios from "axios"

export const fetchPhases = async ({
  taskAnalysisKey,
  startDate,
  endDate,
  granularity,
  variableQueryParam,
}) =>
  await axios
    .get(
      `/api/kpi/task-analysis/${taskAnalysisKey}?refresh=true&startDate=${startDate}${
        endDate ? `&endDate=${endDate}` : ""
      }&granularity=${granularity}${variableQueryParam}`
    )
    .then((response) => response.data)

export const fetchConfig = async () =>
  await axios.get("/api/kpi/config?refresh=true").then((response) => {
    return response.data
  })

export const fetchMetrics = async ({
  startDate,
  endDate,
  variableQueryParam,
}) =>
  await axios
    .get(
      `/api/kpi/metrics?refresh=true&startDate=${startDate}&endDate=${endDate}${variableQueryParam}`
    )
    .then((response) => response.data)

export const fetchServices = async ({
  startDate,
  endDate,
  variableQueryParam,
}) =>
  await axios
    .get(
      `/api/kpi/services?startDate=${startDate}${
        endDate ? `&endDate=${endDate}` : ""
      }${variableQueryParam}`
    )
    .then((response) => response.data)
