import { SvgIconProps } from '../../types/svgIconProps';

export function TableIcon({ size = 24 }: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 24 24"
    >
      <g
        strokeLinecap="square"
        strokeWidth="2"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="miter"
        className="nc-iconWrapper"
        strokeMiterlimit="10"
      >
        <line
          x1="2"
          y1="8"
          x2="22"
          y2="8"
          data-cap="butt"
          strokeLinecap="butt"
          stroke="currentColor"
        ></line>
        <line
          x1="2"
          y1="15"
          x2="22"
          y2="15"
          data-cap="butt"
          strokeLinecap="butt"
          stroke="currentColor"
        ></line>
        <line
          x1="8"
          y1="2"
          x2="8"
          y2="22"
          data-cap="butt"
          strokeLinecap="butt"
          stroke="currentColor"
        ></line>
        <path d="M20,22H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2H20a2,2,0,0,1,2,2V20A2,2,0,0,1,20,22Z"></path>
      </g>
    </svg>
  );
}
