type SplitListProps = {
  heading: string;
  children: React.ReactNode;
};

function SplitList({ children, heading }: SplitListProps) {
  return (
    <div className="flex flex-col gap-4 w-full">
      <h2 className=" font-medium text-black">{heading}</h2>
      <ul className="w-full divide-y">{children}</ul>
    </div>
  );
}

export { SplitList };
