import { isNil } from 'lodash';

export interface Mask {
  parser: (string: string) => string;
  formatter: (string: string) => string;
}

// Phone mask
const phoneAccept = /(\+?)\d+/g;
const phoneParser = (str: string) => (str.match(phoneAccept) || []).join('');

const phoneMask = {
  parser: phoneParser,
  formatter: (value: string): string => {
    const digits = phoneParser(value).substring(0, 11);
    const chars = digits.split('');
    return chars
      .reduce(
        (r, v, index) =>
          index === 2 || index === 4 || index === 6 ? `${r} ${v}` : `${r}${v}`,
        '',
      )
      .substring(0, 11);
  },
};

// Currency mask
const integerAccept = /\d+/g;
const currencyParser = (str: string) =>
  (str.match(integerAccept) || []).join('');

const currencyMask = {
  parser: currencyParser,
  formatter: (value: number | string | null): string => {
    value = isNil(value) ? '' : value.toString();

    const parsed = currencyParser(value);
    const number = Number.parseInt(parsed, 10);
    if (Number.isNaN(number)) {
      return '';
    }
    return number.toLocaleString('no');
  },
};

// SSN Mask
const ssnAccept = /\d+/g;
const ssnParser = (str: string) => (str.match(ssnAccept) || []).join('');

const ssnMask = {
  parser: ssnParser,
  formatter: (value: string): string => {
    const parsed = ssnParser(value);
    const chars = parsed.split('');
    return chars
      .reduce((r, v, index) => (index === 6 ? `${r} ${v}` : `${r}${v}`), '')
      .substring(0, 11);
  },
};

// Account number mask
const accountNumberAccept = /\d+/g;
const accountNumberParser = (str: string = '') => {
  str = isNil(str) ? '' : str;
  return (str.match(accountNumberAccept) || []).join('');
};

const accountNumberMask = {
  parser: accountNumberParser,
  formatter: (value: string): string => {
    const parsed = accountNumberParser(value);
    const chars = parsed.split('');
    return chars
      .reduce(
        (r, v, index) =>
          index === 4 || index === 6 ? `${r} ${v}` : `${r}${v}`,
        '',
      )
      .substring(0, 13);
  },
};

const ageAccept = /\d+/g;
const ageParser = (str: string = '') => {
  str = isNil(str) ? '' : str;
  return (str.match(ageAccept) || []).join('');
};

const ageMask = {
  parser: ageParser,
  formatter: (value: string): string => {
    const parsed = ageParser(value);
    if (parsed === '') {
      return '';
    }
    return `${parsed} år`.substring(0, 6);
  },
};

export { phoneMask, currencyMask, ssnMask, accountNumberMask, ageMask };
