import { CreditScore } from '../../types/creditscore';
import { AssetDetails } from '../AssetDetails/AssetDetails';
import { Box } from '../Box/Box';
import { BoxWithHeader } from '../Box/BoxWithHeader';
import { CompanyAndContact } from '../CompanyAndContact/CompanyAndContact';
import { CreditBar } from '../CreditBar/CreditBar';
import { CreditDecision } from '../CreditDecision/CreditDecision';
import { CreditScoreGraph } from '../CreditScoreGraph/CreditScoreGraph';
import { PaymentDetails } from '../PaymentDetails/PaymentDetails';
import { CarIcon } from '../icons/Car';
import { TrendArrow } from '../icons/TrendArrow';

type CreditHandlingOverviewProps = {
  creditScoreData: CreditScore;
  variables: any;
  flowProps: any;
  newCreditData: any;
  newCreditLimit: any;
  context: any;
};

export const InternalToInternalMapping = {
  G1: 'A1',
  G2: 'A2',
  G3: 'A3',
  G4: 'A4',
  G5: 'A5',
  G6: 'A6',
  G7: 'A7',
  M1: 'B1',
  M2: 'B2',
  M3: 'B3',
  M4: 'B4',
  B1: 'C1',
  B2: 'C2',
  B3: 'C3',
  B4: 'C4',
  B5: 'C5',
  B6: 'C6',
  B7: 'C7',
  D1: 'D1',
  D2: 'D2',
  D3: 'D3',
  D4: 'D4',
  US: 'US',
};

export const InternalScoreMapping = [
  {
    A1: 100,
    A2: 95,
    A3: 90,
    A4: 85,
    A5: 80,
    A6: 60,
    A7: 55,
    B1: 65,
    B2: 30,
    B3: 20,
    B4: 10,
    C1: 90,
    C2: 85,
    C3: 80,
    C4: 75,
    C5: 75,
    C6: 60,
    C7: 55,
    D1: 65,
    D2: 40,
    D3: 30,
    D4: 10,
    US: 60,
  },
  {
    G1: 100,
    G2: 95,
    G3: 90,
    G4: 85,
    G5: 80,
    G6: 60,
    G7: 55,
    M1: 65,
    M2: 30,
    M3: 20,
    M4: 10,
    B1: 90,
    B2: 85,
    B3: 80,
    B4: 75,
    B5: 75,
    B6: 60,
    B7: 55,
    D1: 65,
    D2: 40,
    D3: 30,
    D4: 10,
    US: 60,
  },
];

export function CreditHandlingOverview({
  creditScoreData,
  newCreditData,
  newCreditLimit,
  variables,
  flowProps,
  context,
}: CreditHandlingOverviewProps) {
  const creditLimit = newCreditData?.dbInternalCustomerCredit?.creditLimit || 0;
  const creditLimitUtilized =
    newCreditData?.dbInternalCustomerCredit?.creditLimitUtilized || 0;

  const askedCreditAmount = variables?.askedCreditAmount
    ? variables?.askedCreditAmount
    : 0;
  const externalScore =
    context.externalScore?.['credit-score-b2b-denmark']?.[
      'credit-rating-b2b-denmark'
    ]?.input?.creditScore;
  const internalScore =
    context.preScore?.['internal-score-b2b-denmark']?.input.internalCreditScore;
  const internalNumberScore =
    InternalScoreMapping[context.internalType ?? 0][internalScore];

  const assetInformation =
    flowProps?.currentContractDetails?.Contract?.LeasingAssetsContract;
  const assets = assetInformation?.Assets;
  return (
    <div className="grid gap-4">
      <div className="grid grid-cols-1fr_2fr gap-4 items-stretch">
        <CreditScoreGraph
          score={
            internalNumberScore
              ? (externalScore + internalNumberScore) / 2
              : externalScore
          }
        />
        <Box className="w-full p-4 bg-white rounded-md shadow-md flex flex-col justify-center">
          <CreditBar
            availableCredit={creditLimit - creditLimitUtilized}
            totalCredit={newCreditLimit ? newCreditLimit : creditLimit}
            currentCreditLimit={creditLimit}
            usedCredit={creditLimitUtilized}
            currentApplicationCredit={askedCreditAmount - creditLimitUtilized}
          />
        </Box>
      </div>
      <div>
        <BoxWithHeader icon={<TrendArrow />} title="Credit decision">
          <CreditDecision context={context} />
        </BoxWithHeader>
      </div>
      <CompanyAndContact
        newData={newCreditData}
        companyInformation={flowProps?.currentContractDetails}
      />

      <div className="flex flex-row gap-2">
        <BoxWithHeader icon={<TrendArrow />} title="Payment Details">
          <PaymentDetails
            isAsset={assets === undefined ? false : true}
            details={flowProps?.currentContractDetails}
          />
        </BoxWithHeader>
        {assets === undefined ? (
          <BoxWithHeader icon={<TrendArrow />} title="Asset Details">
            <AssetDetails details={flowProps?.currentContractDetails} />
          </BoxWithHeader>
        ) : (
          <>
            {assets.map((asset, index) => {
              return (
                <BoxWithHeader icon={<CarIcon />} title={`Asset ${index + 1}`}>
                  <AssetDetails isAsset={true} details={asset} />
                </BoxWithHeader>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
