import { ChartBarSequences, Stack } from "../ui"

export function TaskAnalyticsItem({ phase, t, statisticalMethod }) {
  const getWeekNumber = (date) => {
    const d = new Date(date)
    const dayNum = d.getUTCDay() || 7
    d.setUTCDate(d.getUTCDate() + 4 - dayNum)
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
    return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)
  }

  const formattedStatistics = phase.periods.map((sequence) => {
    return {
      date: `Week ${getWeekNumber(sequence.beforeDate)}`,
      value: sequence.statistics[statisticalMethod],
    }
  })

  return (
    <div className="bg-white p-4 shadow-md">
      <Stack>
        <div>
          <h3 className="font-semibold">{phase.name}</h3>
          <span className="text-xs text-neutral-600">
            {t(phase.startActivity)} - {t(phase.endActivity)}
          </span>
        </div>
        <div>
          <ChartBarSequences data={formattedStatistics} />
        </div>
      </Stack>
    </div>
  )
}
