import { FinancialStatement } from '../../types/creditscore';
import { formatMoney } from '../../utils/formatMoney';
import { transformBalanceData } from './TableBalance.transformData';

const rowHeaders = [
  {
    key: 'fixedAssets',
    label: 'Fixed assets',
  },
  {
    key: 'fixedAssets.goodwill',
    label: 'Goodwill',
  },
  {
    key: 'fixedAssets.capitlizedCostForRnD',
    label: 'Capitlized cost for R&D',
  },
  {
    key: 'fixedAssets.intangibleRights',
    label: 'Intangible rights',
  },
  {
    key: 'fixedAssets.otherIntangibleAssets',
    label: 'Other intangible assets',
  },
  {
    key: 'fixedAssets.landAndBuildings',
    label: 'Land and buildings',
  },
  {
    key: 'fixedAssets.machineryAndTools',
    label: 'Machinery and tools',
  },
  {
    key: 'fixedAssets.plantUnderConstruction',
    label: 'Plant under construction',
  },
  {
    key: 'fixedAssets.otherTangibleAssets',
    label: 'Other tangible assets',
  },
  {
    key: 'fixedAssets.revivableFromGroup',
    label: 'Recievable from group and assicoiated companies',
  },
  {
    key: 'fixedAssets.ownShare',
    label: 'Own share',
  },
  {
    key: 'fixedAssets.otherFinancialAssets',
    label: 'Other financial assets',
  },
  {
    key: 'currentAssets',
    label: 'Current assets',
  },
  {
    key: 'currentAssets.inventories',
    label: 'Inventories',
  },
  {
    key: 'currentAssets.workInProgress',
    label: 'Work in progress',
  },
  {
    key: 'currentAssets.propertyForSale',
    label: 'Property for sale',
  },
  {
    key: 'currentAssets.accountsReceivable',
    label: 'Accounts receivable',
  },
  {
    key: 'currentAssets.receivableFromGroup',
    label: 'Recievable from group and assicoiated companies',
  },
  {
    key: 'currentAssets.otherReceivables',
    label: 'Other receivables',
  },
  {
    key: 'currentAssets.accruedRevenues',
    label: 'Accrued revenues and deferred expenses',
  },
  {
    key: 'currentAssets.securities',
    label: 'Securities',
  },
  {
    key: 'currentAssets.cash',
    label: 'Cash and cash equivalents',
  },
  {
    key: 'currentAssets.otherCurrentAssets',
    label: 'Other current assets',
  },
  {
    key: 'totalAssets',
    label: 'Total assets',
  },
  {
    key: 'liabilities',
    label: 'Liabilities',
  },
  {
    key: 'liabilities.longTermLiabilities',
    label: 'Long term liabilities',
    type: 'subheading',
  },
  {
    key: 'liabilities.longTermLiabilities.subordinatedLoansTotal',
    label: 'Subordinated loans total',
  },
  {
    key: 'liabilities.longTermLiabilities.liabilitiesToCreditInstitutions',
    label: 'Liabilities to credit institutions',
  },
  {
    key: 'liabilities.longTermLiabilities.liabilitiesToBank',
    label: 'Liabilities to bank',
  },
  {
    key: 'liabilities.longTermLiabilities.liabilitiesToGroupCompaniesAndAssociates',
    label: 'Liabilities to group companies and associates',
  },
  {
    key: 'liabilities.longTermLiabilities.liabilitiesToOwners',
    label: 'Liabilities to owners',
  },
  {
    key: 'liabilities.longTermLiabilities.otherLongTermLiabilities',
    label: 'Other long term liabilities',
  },
  {
    key: 'liabilities.currentLiabilities',
    label: 'Current liabilities',
    type: 'subheading',
  },
  {
    key: 'liabilities.currentLiabilities.liabilitiesToCreditInstitutions',
    label: 'Liabilities to credit institutions',
  },
  {
    key: 'liabilities.currentLiabilities.liabilitiesToBank',
    label: 'Liabilities to bank',
  },
  {
    key: 'liabilities.currentLiabilities.liabilitiesToGroupCompaniesAndAssociates',
    label: 'Liabilities to group companies and associates',
  },
  {
    key: 'liabilities.currentLiabilities.liabilitiesToOwners',
    label: 'Liabilities to owners',
  },
  {
    key: 'liabilities.currentLiabilities.accountsPayable',
    label: 'Accounts payable',
  },
  {
    key: 'liabilities.currentLiabilities.companyTaxes',
    label: 'Company taxes',
  },
  {
    key: 'liabilities.currentLiabilities.dividends',
    label: 'Dividends',
  },
  {
    key: 'liabilities.currentLiabilities.advancePayments',
    label: 'Advance payments',
  },
  {
    key: 'liabilities.currentLiabilities.onAccountForWorkInProgress',
    label: 'On account for work in progress',
  },
  {
    key: 'liabilities.currentLiabilities.otherLiabilities',
    label: 'Other liabilities',
  },
  {
    key: 'liabilities.currentLiabilities.accruedRevenuesAndDeferredExpenses',
    label: 'Accrued revenues and deferred expenses',
  },
  {
    key: 'liabilities.currentLiabilities.otherCurrentLiabilities',
    label: 'Other current liabilities',
  },
  {
    key: 'equity',
    label: 'Equity',
  },
  {
    key: 'equity.shareCapital',
    label: 'Share capital',
  },
  {
    key: 'equity.revaluationReserve',
    label: 'Revaluation reserve',
  },
  {
    key: 'equity.transferredEarnings',
    label: 'Transferred earnings',
  },
  {
    key: 'equity.expectedDividend',
    label: 'Expected dividend',
  },
  {
    key: 'equity.otherReserves',
    label: 'Other reserves',
  },
  {
    key: 'equity.equityBeforeMinorityInterest',
    label: 'Equity before minority interest',
  },
  {
    key: 'equity.minorityInterest',
    label: 'Minority interest',
  },
  {
    key: 'equity.deferredTax',
    label: 'Deferred tax',
  },
  {
    key: 'equity.pensions',
    label: 'Pensions',
  },
  {
    key: 'equity.otherProvisions',
    label: 'Other provisions',
  },
  {
    key: 'totalLiabilitiesAndEquity',
    label: 'Total liabilities and equity',
  },
];

const getDeepValue = (obj, path) => {
  const value = path.split('.').reduce((acc, key) => {
    return acc[key];
  }, obj);

  return value;
};

const numberOfDots = (str) => {
  return str.split('.').length - 1;
};

type TableBalanceProps = {
  data: FinancialStatement[];
};

function TableBalance({ data }: TableBalanceProps) {
  const balanceData = transformBalanceData(data);

  return (
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th className="w-4 border-b border-gray-200 bg-blue-50 px-2">
            <span className="sr-only">Icon</span>
          </th>
          <th className="text-left w-auto border-b border-gray-200 bg-blue-50">
            Balances
          </th>
          {balanceData.map((result) => {
            return (
              <th className="py-2 pr-4 text-right border-l border-b border-gray-200 bg-blue-50">
                {result.year}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="divide-y">
        {rowHeaders.map((rowHeader) => {
          return (
            <tr>
              {/* Use meta field to render correct icon. either '=' for sum, "-" for cost */}
              <td className={`w-4 py-2 px-2`}>
                {getDeepValue(balanceData[0], rowHeader.key)?.meta?.type ===
                'expandable'
                  ? null
                  : getDeepValue(balanceData[0], rowHeader.key)?.meta?.type ===
                      'sum'
                    ? '='
                    : getDeepValue(balanceData[0], rowHeader.key)?.meta
                          ?.type === 'cost'
                      ? '-'
                      : null}
              </td>
              <td
                className={`py-2 w-auto ${numberOfDots(rowHeader.key) === 1 ? 'pl-4' : numberOfDots(rowHeader.key) === 2 ? 'pl-8' : 'font-bold'} ${rowHeader.type === 'subheading' ? 'font-bold' : ''} `}
              >
                {rowHeader.label}
              </td>
              {balanceData.map((resultYear) => {
                return (
                  <td
                    className={`py-2 pr-4 text-right border-l border-gray-100 
                    ${numberOfDots(rowHeader.key) === 0 ? 'font-bold' : ''}
                    ${rowHeader.type === 'subheading' ? 'font-bold' : ''}
                  `}
                  >
                    {getDeepValue(resultYear, rowHeader.key).value
                      ? formatMoney(
                          getDeepValue(resultYear, rowHeader.key).value,
                        )
                      : '-'}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export { TableBalance };
