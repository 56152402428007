import { cn } from "../../../utils"
import { cva } from "class-variance-authority"
import { Slot } from "@radix-ui/react-slot"
import { forwardRef } from "react"

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md font-semibold transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
  {
    variants: {
      variant: {
        primary: [
          "bg-primary text-white border border-2 border-primary-600",
          "hover:bg-primary-600 hover:border-primary-700",
        ],
        secondary: [
          "text-primary border border-2 border-primary",
          "hover:border-primary-700 hover:text-primary-700",
        ],
        subtle: [
          "bg-white text-neutral-black border border-neutral-400",
        ],
        destructive: "bg-negative text-white hover:bg-destructive/90",
        ghost: ["hover:bg-accent hover:text-accent-foreground"],
        link: "underline-offset-4 hover:underline text-primary",
      },
      size: {
        md: "h-10 py-2 px-4",
        sm: "h-9 px-3 rounded-lg",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  }
)

const Button = forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
