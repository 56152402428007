import { TaskManualCreditEvaluation } from '../../components/TaskModals/TaskManualCreditEvaluation/TaskManualCreditEvaluation';
import { CreditHandlingTaskForm } from '../../components/Views/CreditHandlingTaskForm';
import { FlowContext } from '../../hooks/useFlowContext';

export function CreditHandling(props) {
  const isOrg =
    !!props?.flow?.data?.currentContractDetails?.Contract?.Buyer?.Organisation
      ?.VATnr;
  return (
    <FlowContext.Provider value={props}>
      {isOrg ? (
        <CreditHandlingTaskForm flowProps={props} />
      ) : (
        <TaskManualCreditEvaluation />
      )}
    </FlowContext.Provider>
  );
}
