import { AggregateCounts } from './FeaturedMetric.AggregateCounts';
import { MetricItem } from './FeaturedMetrics.Metric';
import { fetchMetrics } from '../../api/api';
import { Stack } from '../ui';
import { cn } from '../../utils';
import { useQuery } from '@tanstack/react-query';
import { Fragment } from 'react';

export function FeaturedMetrics({ t, startDate, endDate, variableQueryParam }) {
  const { data, isSuccess } = useQuery({
    queryKey: ['metrics', startDate, endDate, variableQueryParam],
    queryFn: () =>
      fetchMetrics({
        startDate,
        endDate,
        variableQueryParam,
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    suspense: true,
  });

  const { counts } = data ?? {};

  if (!counts) {
    return null;
  }

  return (
    <div>
      <Stack>
        <div
          className={cn(
            'lg -8 -mb-16 rounded-lg bg-white p-4 text-neutral-black shadow-xl',
          )}
        >
          <div className="flex flex-row justify-around">
            {counts?.map((metric, index) => (
              <Fragment key={t(metric.name)}>
                <div
                  className="flex basis-full items-center justify-center flex-col"
                  key={metric.name}
                >
                  <MetricItem metric={metric} />
                </div>

                <div className="mr-4 w-px bg-neutral-400" />
              </Fragment>
            ))}
            <AggregateCounts counts={counts} />
          </div>
        </div>
      </Stack>
    </div>
  );
}
