interface CircleIconProps {
  number: number | undefined;
  include0?: boolean;
}

export function CircleIcon({ number, include0 }: CircleIconProps) {
  if ((number === 0 && !include0) || !number) {
    return null;
  }
  return (
    <div className="relative inline-block">
      <div className=" min-w-5 w-auto h-5 px-1 bg-sea-light rounded-full flex items-center justify-center text-blue-600 font-normal cursor-pointer">
        {number}
      </div>
    </div>
  );
}
