import { standarizeString } from '../utils/standarizeString';
import { Skeleton } from './Skeleton';

export function CountryDisplay({ country }: { country: string | undefined }) {
  if (!country) {
    return (
      <div className="">
        <Skeleton className="w-20 h-5" />
      </div>
    );
  }
  return <div>{standarizeString(country)}</div>;
}
