import { ValueOf } from '../utils/tsUtils';

export const Role = {
  Admin: 'admin',
  User: 'user',
  Worker: 'worker',
  Manager: 'manager',
} as const;

export type TypeRole = ValueOf<typeof Role>;
