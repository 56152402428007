export function LoadingSpinner({
  width = 16,
  height = 16,
  className = ""
}: {
  width?: number;
  height?: number;
  className?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`animate-spin ${className}`}
    >
      <circle cx="8" cy="8" r="8" fill="#DAD9D8" />
      <path
        d="M4.3927 7.33373C4.54233 6.5211 4.96206 5.78283 5.58384 5.23862C6.20561 4.69441 6.99297 4.37617 7.81827 4.3355C8.64356 4.29483 9.4584 4.53411 10.1307 5.01455C10.8029 5.49499 11.2932 6.18841 11.522 6.9824M11.6654 4.33373V7.0004L9.01936 6.67173M11.6047 8.66707C11.4551 9.4797 11.0353 10.218 10.4136 10.7622C9.79179 11.3064 9.00442 11.6246 8.17913 11.6653C7.35383 11.706 6.53899 11.4667 5.86672 10.9862C5.19446 10.5058 4.70417 9.81239 4.47536 9.0184M4.33203 11.6671V9.0004L6.97803 9.32907"
        stroke="#2B2B2B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/**
   * @keyframes spin {
    from {transform: rotate(0deg)}
    to {transform: rotate(360deg)}
  } */