import { FormProvider, useForm } from 'react-hook-form';
import { RadioButtonList } from '../_shared/Inputs/RadioButtonList/RadioButtonList';
import { Text } from '../_shared/forms/fields/Text';
import { Button } from '../Button/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { Box } from '../Box/Box';
import { useQuery } from '@tanstack/react-query';
import { getAllFlows } from '../../api/api';
import { LoadingSpinner } from '../LoadingSpinner';
import { Loader } from '../Loader/Loader';
import { ComboBox } from '../_shared/Inputs/ComboBox/ComboBox';
import { Attachment } from '../_shared/Attachment/Attachment';
import { ThreeOneLayout } from '../Layout/ThreeOneLayout';
import { Comment } from '../_shared/forms/fields/Comment';

export function ManuallyAssignInvoiceTaskForm({ flowProps }) {
  const task = flowProps?.task;
  const method = useForm({
    resolver: zodResolver(
      z
        .object({
          referenceId: z.string(),
          flowId: z.string().optional(),
          decision: z.string({ invalid_type_error: 'Please select an option' }),
          comment: z.string().optional(),
        })
        .superRefine((val, ctx) => {
          if (val.decision === 'reject' && !val.comment) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Comment is required',
              path: ['comment'],
            });
          }
        }),
    ),
    defaultValues: {
      flowId: task?.data?.flowId || '',
      decision: task?.data?.decision || null,
      comment: task?.data?.comment || '',
      referenceId: task?.data?.referenceId || '',
    },
  });

  const { handleSubmit, formState, watch, setValue } = method;

  const { complete, save } = flowProps;
  const onSubmit = (data) => {
    complete(data);
  };

  const status = watch('decision');
  const comment = watch('comment');
  const invoice = task?.context?.invoice;

  const { data, isFetching } = useQuery({
    queryKey: ['flows-invoice'],
    queryFn: async () =>
      getAllFlows({
        flowDefinitionId: 'initiate-contract',
        status: 'active',
        'data.invoice.businessStatus': 'isAvailableForInvoice',
      }),
  });

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <ThreeOneLayout>
          <ThreeOneLayout.Left>
            <Box className="flex flex-col gap-4">
              <h3 className="text-black font-medium">Invoice data</h3>
              <div className="flex flex-row gap-4">
                {invoice.accountingSupplierParty?.party?.name && (
                  <div>
                    <h4 className="text-gray-700 capitalize font-medium">
                      Company
                    </h4>
                    <p>{invoice.accountingSupplierParty?.party?.name}</p>
                    <p>
                      {
                        invoice.accountingSupplierParty?.party?.contact
                          ?.electronicMail
                      }
                    </p>
                  </div>
                )}
                <div>
                  <h4 className="text-gray-700 capitalize font-medium">
                    Customer
                  </h4>
                  <p>{invoice.accountingCustomerParty?.party?.name}</p>
                  <p>
                    {
                      invoice.accountingCustomerParty?.party?.contact
                        ?.electronicMail
                    }
                  </p>
                </div>
              </div>
            </Box>
            <Box className="flex flex-col gap-4">
              {isFetching ? (
                <div className="w-full flex items-center justify-center">
                  <Loader />
                </div>
              ) : (
                <div className="flex flex-col gap-4">
                  <h3 className="text-black font-medium">Assign to case</h3>

                  <ComboBox
                    setValue={setValue}
                    options={data}
                    name="referenceId"
                  />
                </div>
              )}
            </Box>
            <Box className="p-0" padding="">
              <h4 className="w-full p-4 text-black font-medium border-b border-gray-400">
                Invoice
              </h4>
              <Attachment
                flow={flowProps}
                attachmentKey="invoice"
                mimeType="application/pdf"
              />
            </Box>
          </ThreeOneLayout.Left>
          <ThreeOneLayout.Right>
            <div></div>
            <div className="flex flex-col gap-4">
              <label
                className=" font-medium text-black"
                id="decision"
                aria-label="decision"
              >
                Decision
              </label>
              <RadioButtonList
                id="decision"
                options={[
                  { label: 'Assign', value: 'approve' },
                  { label: 'Reject', value: 'reject' },
                ]}
              />

              <Comment
                placeholder="(Optional)"
                name="comment"
                label="Rejection reason"
              />

              <div className="flex gap-2">
                <Button intent="primary" type="submit">
                  Complete
                </Button>
                <Button
                  onClick={() => flowProps?.save({ approved: status, comment })}
                  intent="secondary"
                  type="button"
                >
                  Save and close
                </Button>
              </div>
            </div>
          </ThreeOneLayout.Right>
        </ThreeOneLayout>
      </form>
    </FormProvider>
  );
}
