import { FinancialStatement } from '../../types/creditscore';

export function transformBalanceData(data: FinancialStatement[]) {
  return data.map((statement) => {
    return {
      year: statement.closingPeriod.year,
      fixedAssets: {
        value:
          statement.balanceSheet.assets.nonCurrentAssets
            .tangibleFixedAssetsTotal,
        goodwill: {
          value: statement.balanceSheet.assets.nonCurrentAssets.goodwill,
        },
        capitlizedCostForRnD: {
          value:
            statement.balanceSheet.assets.nonCurrentAssets
              .capitalisedCostForRnd,
        },
        intangibleRights: {
          value:
            statement.balanceSheet.assets.nonCurrentAssets.intangibleRights,
        },
        otherIntangibleAssets: {
          value:
            statement.balanceSheet.assets.nonCurrentAssets
              .otherIntangibleAssets,
        },
        landAndBuildings: {
          value:
            statement.balanceSheet.assets.nonCurrentAssets.landAndBuildings,
        },
        machineryAndTools: {
          value:
            statement.balanceSheet.assets.nonCurrentAssets.machineryAndTools,
        },
        plantUnderConstruction: {
          value:
            statement.balanceSheet.assets.nonCurrentAssets
              .plantUnderConstruction,
        },
        otherTangibleAssets: {
          value:
            statement.balanceSheet.assets.nonCurrentAssets.otherTangibleAssets,
        },
        revivableFromGroup: {
          value:
            statement.balanceSheet.assets.nonCurrentAssets
              .receivableFromGroupAndAssociatedCompanies,
        },
        ownShare: {
          value: statement.balanceSheet.assets.nonCurrentAssets.ownShares,
        },
        otherFinancialAssets: {
          value:
            statement.balanceSheet.assets.nonCurrentAssets.otherFinancialAssets,
        },
      },
      currentAssets: {
        value: statement.balanceSheet.assets.currentAssets.currentAssetsTotal,
        inventories: statement.balanceSheet.assets.currentAssets.inventories,
        workInProgress:
          statement.balanceSheet.assets.currentAssets.workInProgress,
        propertyForSale:
          statement.balanceSheet.assets.currentAssets.propertyForSale,
        accountsReceivable:
          statement.balanceSheet.assets.currentAssets.accountsReceivable,
        receivableFromGroup:
          statement.balanceSheet.assets.currentAssets
            .receivableFromGroupAndAssociatedCompanies,
        otherReceivables:
          statement.balanceSheet.assets.currentAssets.otherReceivables,
        accruedRevenues:
          statement.balanceSheet.assets.currentAssets
            .accruedRevenuesAndDeferredExpenses,
        securities: statement.balanceSheet.assets.currentAssets.securities,
        cash: statement.balanceSheet.assets.currentAssets
          .cashAndCashEquivalents,
        otherCurrentAssets:
          statement.balanceSheet.assets.currentAssets.otherCurrentAssets,
      },
      totalAssets: {
        value: statement.balanceSheet.assets.assetsTotal,
        meta: {
          type: 'sum',
        },
      },
      liabilities: {
        value:
          statement.balanceSheet.liabilitiesAndEquity.liabilities
            .currentLiabilities.currentLiabilitiesTotal +
          statement.balanceSheet.liabilitiesAndEquity.liabilities
            .nonCurrentLiabilities.longTermLiabilitiesTotal,
        longTermLiabilities: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.liabilities
              .nonCurrentLiabilities.longTermLiabilitiesTotal,
          subordinatedLoansTotal: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .nonCurrentLiabilities.subordinatedLoansTotal,
          },
          liabilitiesToCreditInstitutions: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .nonCurrentLiabilities.liabilitiesToCreditInstitutions,
          },
          liabilitiesToBank: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .nonCurrentLiabilities.liabilitiesToBank,
          },
          liabilitiesToGroupCompaniesAndAssociates: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .nonCurrentLiabilities.liabilitiesToGroupCompaniesAndAssociates,
          },
          liabilitiesToOwners: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .nonCurrentLiabilities.liabilitiesToOwners,
          },
          otherLongTermLiabilities: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .nonCurrentLiabilities.otherLongTermLiabilities,
          },
        },
        currentLiabilities: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.liabilities
              .currentLiabilities.currentLiabilitiesTotal,
          liabilitiesToCreditInstitutions: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.liabilitiesToCreditInstitutions,
          },
          liabilitiesToBank: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.liabilitiesToBank,
          },
          liabilitiesToGroupCompaniesAndAssociates: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.liabilitiesToGroupCompaniesAndAssociates,
          },
          liabilitiesToOwners: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.liabilitiesToOwners,
          },
          accountsPayable: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.accountsPayable,
          },
          companyTaxes: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.companyTaxes,
          },
          dividends: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.dividends,
          },
          advancePayments: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.advancePayments,
          },
          onAccountForWorkInProgress: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.onAccountForWorkInProgress,
          },
          otherLiabilities: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.otherLiabilities,
          },
          accruedRevenuesAndDeferredExpenses: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.accruedRevenuesAndDeferredExpenses,
          },
          otherCurrentLiabilities: {
            value:
              statement.balanceSheet.liabilitiesAndEquity.liabilities
                .currentLiabilities.otherCurrentLiabilities,
          },
        },
        meta: {
          type: 'subheading',
        },
      },
      equity: {
        value:
          statement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
            .equityTotal,
        shareCapital: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
              .shareCapital,
        },
        revaluationReserve: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
              .revaluationReserve,
        },
        transferredEarnings: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
              .transferredEarnings,
        },
        expectedDividend: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
              .expectedDividend,
        },
        otherReserves: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
              .otherReserves,
        },
        equityBeforeMinorityInterest: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
              .equityBeforeMinorityInterest,
        },
        minorityInterest: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
              .minorityInterest,
        },
        deferredTax: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
              .deferredTax,
        },
        pensions: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
              .pensions,
        },
        otherProvisions: {
          value:
            statement.balanceSheet.liabilitiesAndEquity.shareholdersEquity
              .otherProvisions,
        },
      },
      totalLiabilitiesAndEquity: {
        value:
          statement.balanceSheet.liabilitiesAndEquity.equityAndLiabilitiesTotal,
        meta: {
          type: 'sum',
        },
      },
    };
  });
}
