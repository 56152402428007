import {
  Area,
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Line,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FinancialStatement } from '../../types/creditscore';

type ChartAreaResultProps = {
  financialStatements: FinancialStatement[];
};

function ChartAreaResult({ financialStatements }: ChartAreaResultProps) {
  const chartData = financialStatements
    .map((financialStatement) => {
      return {
        name: financialStatement.closingPeriod.year,
        resultBeforeTax: financialStatement.incomeStatement.annualResult,
      };
    })
    .reverse();

  return (
    <div className="grid gap-2">
      <h4 className="text-center text-gray-500 text-base">
        Result before taxes
      </h4>
      <div
        className="w-full"
        style={{
          height: '200px',
        }}
      >
        <ResponsiveContainer>
          <ComposedChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip cursor={{ fill: '#EDEDED' }} />
            <Area type="monotone" dataKey="resultBeforeTax" fill="#467EFF33" />
            <Line
              type="monotone"
              dataKey="resultBeforeTax"
              stroke="#467EFF"
              strokeWidth={2}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export { ChartAreaResult };
