import { formatMoney } from '../../utils/formatMoney';
import { useFlowContext } from '../../hooks/useFlowContext';

interface BarExposureProps {
  existingAmount: number;
  newAmount: number;
}

function BarExposure({ existingAmount, newAmount }: BarExposureProps) {
  const totalAmount = existingAmount + newAmount;

  const { t } = useFlowContext();
  return (
    <div>
      <div className="grid gap-2">
        <div className="flex justify-between">
          <h2 className="text-content-secondary uppercase font-medium text-sm">
            {t('exposure')}
          </h2>
          <span className="text-content-secondary font-medium text-sm">
            {formatMoney(totalAmount)}
          </span>
        </div>
        <div
          className="grid h-8 relative"
          style={{
            gridTemplateColumns: `${(existingAmount / totalAmount) * 100}% ${(newAmount / totalAmount) * 100}%`,
          }}
        >
          <div className="bg-surface-state-action-dark" />
          <div className="bg-surface-state-action-light" />
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-1 text-sm">
            <div className="rounded-full h-4 w-4 bg-surface-state-action-dark" />
            {t('exposure-existing')}
            <span className="font-medium">{formatMoney(existingAmount)}</span>
          </div>
          <div className="flex items-center gap-1 text-sm">
            <div className="rounded-full h-4 w-4 bg-surface-state-action-light" />
            {t('exposure-new')}{' '}
            <span className="font-medium">{formatMoney(newAmount)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export { BarExposure };
