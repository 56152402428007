export function CaseSummaryContact({
  customer,
  carUser,
  salesPerson,
  supplier,
}) {
  return (
    <div className="flex flex-row gap-6 text-xs w-full">
      {!!customer && (
        <div>
          <p className=" text-gray-800 uppercase font-medium">Customer</p>
          <p className=" text-gray-800">{customer?.orgName}</p>
          <p className=" text-gray-800">{customer?.email}</p>
        </div>
      )}
      {carUser?.name && carUser?.email && (
        <div>
          <p className=" text-gray-800 uppercase font-medium">Car user</p>
          <p>{carUser?.name}</p>
          <p className=" text-gray-800">{carUser?.email}</p>
        </div>
      )}
      {salesPerson?.name && (
        <div>
          <p className=" text-gray-800 uppercase font-medium">Sales person</p>
          <p>{salesPerson?.name}</p>
          <p className=" text-gray-800">{salesPerson?.email}</p>
        </div>
      )}
      {supplier?.vatNo && (
        <div>
          <p className=" text-gray-800 uppercase font-medium">Vendor</p>
          <p className=" text-gray-800">{supplier?.name}</p>
          <p className=" text-gray-800">{supplier?.email}</p>
        </div>
      )}
    </div>
  );
}
