import { useState } from 'react';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { useFormConnect } from '../../../../hooks/useFormConnect';
import clsx from 'clsx';

type RadioButtonListProps = {
  id: string;
  disabled?: boolean;
  horizontal?: boolean;
  options: {
    label: string;
    value: any;
  }[];
};

export function RadioButtonList({
  id,
  options,
  disabled = false,
  horizontal = false,
}: RadioButtonListProps) {
  const { value, onChange, name, error } = useFormConnect(id);
  const { t } = useFlowContext();
  return (
    <div>
      <fieldset className="cursor-pointer" disabled={disabled}>
        <legend className="sr-only">Radio buttons</legend>
        <div className={horizontal ? 'flex flex-row gap-4' : 'grid gap-2 '}>
          {options.map((option) => {
            return (
              <div key={option.value} className="flex items-center gap-2">
                <input
                  value={option.value}
                  id={option.label}
                  name={name}
                  type="radio"
                  checked={option.value === value}
                  className="h-4 min-w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  onChange={(e) => onChange(e.target.value)}
                />
                <label
                  htmlFor={option.label}
                  className=" block text-sm text-gray-700"
                >
                  {option.label}
                </label>
              </div>
            );
          })}
          {error && <span className="block text-red-500">{t(error)}</span>}
        </div>
      </fieldset>
    </div>
  );
}
