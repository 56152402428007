import { Stack } from "../ui"
import { Children } from "react"

function Header({ children }) {
  return (
    <header className="bg-primary-900 p-8 pt-20 text-white">
      <Stack>{children}</Stack>
    </header>
  )
}

function Main({ children }) {
  return <main className="p-8">{children}</main>
}

export function Layout({ children }) {
  const slots = { header: null, main: null }

  Children.forEach(children, (child) => {
    switch (child.type) {
      case Header: {
        slots.header = child
        break
      }
      default: {
        slots.main = child
      }
    }
  })

  return (
    <div className="supports-[min-height:100svh]:min-h-svh min-h-screen bg-background text-neutral-black overflow-y-auto pb-12">
      {slots.header}
      {slots.main}
    </div>
  )
}

Layout.Header = Header
Layout.Main = Main
