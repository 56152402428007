export function CarIcon({ currentColor = 'currentColor' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <g fill={currentColor} clipPath="url(#a)">
        <path d="M3.5 8.75a.875.875 0 1 1-1.75 0 .875.875 0 0 1 1.75 0ZM11.375 9.625a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75Z" />
        <path
          fillRule="evenodd"
          d="M.006 6.492a.434.434 0 0 0-.006.08v6.115c0 .242.196.438.438.438h3.5a.438.438 0 0 0 .437-.438v-1.312h5.25v1.313c0 .241.196.437.438.437h3.5a.438.438 0 0 0 .437-.438V6.574a.436.436 0 0 0-.026-.159l-1.547-4.641a1.313 1.313 0 0 0-1.245-.898H2.818c-.565 0-1.066.361-1.245.898L.026 6.414a.435.435 0 0 0-.02.078ZM2.403 2.05a.437.437 0 0 1 .415-.299h8.364c.188 0 .356.12.415.3l1.359 4.075H1.045l1.358-4.076Zm8.097 9.326h2.625v.875H10.5v-.875Zm2.625-.875H.875V7h12.25v3.5Zm-12.25.875H3.5v.875H.875v-.875Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
