interface TaskLayoutProps {
  children: React.ReactNode;
}

function TaskLayout({ children }: TaskLayoutProps) {
  return (
    <div className="grid grid-cols-task-layout bg-surface-panel-level2 min-h-full">
      {children}
    </div>
  );
}

function TaskLayoutMain({ children }: TaskLayoutProps) {
  return <div>{children}</div>;
}

function TaskLayoutDecision({ children }: TaskLayoutProps) {
  return (
    <div className="bg-surface shadow-lg p-6">
      <div className="flex flex-col justify-between">{children}</div>
    </div>
  );
}

export { TaskLayout, TaskLayoutMain, TaskLayoutDecision };
