import { FinancialStatement } from '../../types/creditscore';

type ResultMeta = {
  type: string;
};

type ResultValue = {
  value: number;
};

type ResultData = {
  year: string;
  operatingIncome: {
    value: number;
    salesRevenue: { value: number };
    otherIncome: { value: number };
    meta?: ResultMeta;
  };
  operatingCosts: {
    value: number;
    costOfGoods: ResultValue;
    salaryCosts: ResultValue;
    writeDowns: ResultValue;
    otherOperatingExpenses: ResultValue;
    meta?: ResultMeta;
  };
  operatingProfitBeforeDepreciation: {
    value: number;
    meta?: ResultMeta;
  };
  depreciation: {
    value: number;
    meta?: ResultMeta;
  };
  operatingProfit: ResultValue;
  netInterestCost: {
    value: number;
    interestIncome: ResultValue;
    interestCost: ResultValue;
  };
  resultBeforeTaxes: ResultValue;
  annualResult: {
    value: number;
    dividend: ResultValue;
    groupContribution: ResultValue;
    transferredToEquity: ResultValue;
  };
};

// Lets start by trying to make a sane structure for the data that we should be able to use in the table
// We'll need to make a function to transform D&B data into this format. Note that D&B data is structured differently per country (!)
const fakeResultData = [
  {
    year: '2015',
    operatingIncome: {
      value: 1000,
      salesRevenue: { value: 300 },
      otherIncome: { value: 700 },
      meta: {
        type: 'expandable',
      },
    },
    operatingCosts: {
      value: 30000,
      costOfGoods: { value: 1300 },
      salaryCosts: { value: 20000 },
      writeDowns: { value: 0 },
      otherOperatingExpenses: { value: 7700 },
      meta: {
        type: 'expandable',
      },
    },
    operatingProfitBeforeDepreciation: {
      value: 765,
      meta: {
        type: 'sum',
      },
    },
    depreciation: {
      value: 1161,
      meta: {
        type: 'cost',
      },
    },
    operatingProfit: {
      value: -396,
      meta: {
        type: 'sum',
      },
    },
    netInterestCost: {
      value: 374,
      interestIncome: { value: 7 },
      interestCost: { value: 381 },
    },
    resultBeforeTaxes: {
      value: -770,
      meta: {
        type: 'sum',
      },
    },
    annualResult: {
      value: -770,
      dividend: {
        value: 0,
      },
      groupContribution: {
        value: 0,
      },
      transferredToEquity: {
        value: -770,
      },
    },
  },
  {
    year: '2016',
    operatingIncome: {
      value: 1000,
      salesRevenue: { value: 300 },
      otherIncome: { value: 700 },
      meta: {
        type: 'expandable',
      },
    },
    operatingCosts: {
      value: 30000,
      costOfGoods: { value: 1300 },
      salaryCosts: { value: 20000 },
      writeDowns: { value: 0 },
      otherOperatingExpenses: { value: 7700 },
      meta: {
        type: 'expandable',
      },
    },
    operatingProfitBeforeDepreciation: {
      value: 765,
      meta: {
        type: 'sum',
      },
    },
    depreciation: {
      value: 1161,
      meta: {
        type: 'cost',
      },
    },
    operatingProfit: {
      value: -396,
      meta: {
        type: 'sum',
      },
    },
    netInterestCost: {
      value: 374,
      interestIncome: { value: 7 },
      interestCost: { value: 381 },
    },
    resultBeforeTaxes: {
      value: -770,
      meta: {
        type: 'sum',
      },
    },
    annualResult: {
      value: -770,
      dividend: {
        value: 0,
      },
      groupContribution: {
        value: 0,
      },
      transferredToEquity: {
        value: -770,
      },
    },
  },
  {
    year: '2017',
    operatingIncome: {
      value: 1000,
      salesRevenue: { value: 300 },
      otherIncome: { value: 700 },
      meta: {
        type: 'expandable',
      },
    },
    operatingCosts: {
      value: 30000,
      costOfGoods: { value: 1300 },
      salaryCosts: { value: 20000 },
      writeDowns: { value: 0 },
      otherOperatingExpenses: { value: 7700 },
      meta: {
        type: 'expandable',
      },
    },
    operatingProfitBeforeDepreciation: {
      value: 765,
      meta: {
        type: 'sum',
      },
    },
    depreciation: {
      value: 1161,
      meta: {
        type: 'cost',
      },
    },
    operatingProfit: {
      value: -396,
      meta: {
        type: 'sum',
      },
    },
    netInterestCost: {
      value: 374,
      interestIncome: { value: 7 },
      interestCost: { value: 381 },
    },
    resultBeforeTaxes: {
      value: -770,
      meta: {
        type: 'sum',
      },
    },
    annualResult: {
      value: -770,
      dividend: {
        value: 0,
      },
      groupContribution: {
        value: 0,
      },
      transferredToEquity: {
        value: -770,
      },
    },
  },
];

const rowHeaders = [
  {
    key: 'operatingIncome',
    label: 'Operating income',
  },
  {
    key: 'operatingIncome.salesRevenue',
    label: 'Sales revenue',
  },
  {
    key: 'operatingIncome.otherIncome',
    label: 'Other income',
  },
  {
    key: 'operatingCosts',
    label: 'Operating costs',
  },
  {
    key: 'operatingCosts.costOfGoods',
    label: 'Cost of goods',
  },
  {
    key: 'operatingCosts.salaryCosts',
    label: 'Salary costs',
  },
  {
    key: 'operatingCosts.writeDowns',
    label: 'Write downs',
  },
  {
    key: 'operatingCosts.otherOperatingExpenses',
    label: 'Other operating expenses',
  },
  {
    key: 'operatingProfitBeforeDepreciation',
    label: 'Operating profit before depreciation',
  },
  {
    key: 'depreciation',
    label: 'Depreciation',
  },
  {
    key: 'operatingProfit',
    label: 'Operating profit',
  },
  {
    key: 'netInterestCost',
    label: 'Net interest cost',
  },
  {
    key: 'netInterestCost.interestIncome',
    label: 'Interest income',
  },
  {
    key: 'netInterestCost.interestCost',
    label: 'Interest cost',
  },
  {
    key: 'resultBeforeTaxes',
    label: 'Result before taxes',
  },
  {
    key: 'annualResult',
    label: 'Annual result',
  },
  {
    key: 'annualResult.dividend',
    label: 'Dividend',
  },
  {
    key: 'annualResult.groupContribution',
    label: 'Group contribution',
  },
  {
    key: 'annualResult.transferredToEquity',
    label: 'Transferred to equity',
  },
  {
    key: 'annualResult',
    label: 'Annual result',
  },
  {
    key: 'annualResult.dividend',
    label: 'Dividend',
  },
  {
    key: 'annualResult.groupContribution',
    label: 'Group contribution',
  },
];

const getDeepValue = (obj, path) => {
  const value = path.split('.').reduce((acc, key) => {
    return acc[key];
  }, obj);

  return value;
};

type TableResultProps = {
  resultData?: ResultData[];
  data: FinancialStatement[];
};

function TableResult({ resultData = fakeResultData, data }: TableResultProps) {
  return (
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th className="w-4 border-b border-gray-200 bg-blue-50 px-2">
            <span className="sr-only">Icon</span>
          </th>
          <th className="text-left w-auto border-b border-gray-200 bg-blue-50">
            Result
          </th>
          {resultData.map((result) => {
            return (
              <th className="py-2 pr-4 w-1/4 text-right border-l border-b border-gray-200 bg-blue-50">
                {result.year}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="divide-y">
        {rowHeaders.map((rowHeader) => {
          return (
            <tr>
              {/* Use meta field to render correct icon. either '=' for sum, "-" for cost */}
              <td className={`w-4 py-2 px-2`}>
                {getDeepValue(resultData[0], rowHeader.key)?.meta?.type ===
                'expandable'
                  ? null
                  : getDeepValue(resultData[0], rowHeader.key)?.meta?.type ===
                      'sum'
                    ? '='
                    : getDeepValue(resultData[0], rowHeader.key)?.meta?.type ===
                        'cost'
                      ? '-'
                      : null}
              </td>
              <td
                className={`py-2 w-auto ${rowHeader.key.includes('.') ? 'pl-4' : 'font-bold'}`}
              >
                {rowHeader.label}
              </td>
              {resultData.map((resultYear) => {
                return (
                  <td className="py-2 pr-4 w-1/4 text-right border-l border-gray-100">
                    {getDeepValue(resultYear, rowHeader.key).value ?? '-'}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export { TableResult };
