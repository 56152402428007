import { ManuallyAssignInvoiceTaskForm } from '../../components/Views/ManuallyAssignInvoiceTaskForm';
import { FlowContext } from '../../hooks/useFlowContext';

export function ManuallyAssignInvoice(props) {
  return (
    <FlowContext.Provider value={props}>
      <ManuallyAssignInvoiceTaskForm flowProps={props} />
    </FlowContext.Provider>
  );
}
