import { formatMoney } from '../../../utils/formatMoney';
import { BarExposure } from '../../BarExposure/BarExposure';
import { BarPdLgd } from '../../BarPdLgd/BarPdLgd';
import { Box } from '../../Box/Box';
import { BoxDataList } from '../../BoxDataList/BoxDataList';
import { RiskClass } from '../../RiskClass/RiskClass';
import { TableDecisionHits } from '../../TableDecisionHits/TableDecisionHits';
import { formatPercentage } from '../../../utils/formatPercentage';
import { TableAffordability } from '../../TableAffordability/TableAffordability';
import { ManualDecisionContext } from '../../../types/ManualDecisionContext';

function TaskManualCreditEvaluationOverview({
  context,
}: {
  context: ManualDecisionContext;
}) {
  return (
    <div className="grid gap-4">
      <div className="grid grid-cols-1fr_1fr_auto gap-4">
        <Box>
          <BarPdLgd lgd={context.pd.lgd ?? 0.05} pd={context.pd.pd} />
        </Box>
        <Box>
          <BarExposure
            existingAmount={context.exposure.existingAmount}
            newAmount={context.exposure.newAmount}
          />
        </Box>
        <Box>
          <RiskClass riskClass={context.riskClass} />
        </Box>
      </div>
      <div className="flex justify-between gap-4">
        <Box className="w-full">
          <TableDecisionHits decisionHits={context.decisionHits} />
        </Box>
      </div>
      <div className="flex justify-between gap-4">
        <Box className="w-full">
          <TableAffordability
            {...context.affordability}
            income={context.affordability.income.application}
          />
        </Box>
      </div>
      <div className="grid grid-cols-1fr_2fr gap-4">
        <BoxDataList
          heading="Kalkulering"
          className="bg-white"
          data={[
            {
              label: 'Etableringsgebyr',
              value: formatMoney(context.calculation.establishmentFee),
            },
            {
              label: 'Termingebyr',
              value: formatMoney(context.calculation.instalmentFee),
            },
            {
              label: 'Første terminbeløp',
              value: formatMoney(
                context.calculation.instalmentSeries[0].amount,
              ),
            },
            {
              label: 'Terminer',
              value: `${context.calculation.instalmentSeries[0].length}`,
            },
            {
              label: 'Finansiert beløp',
              value: formatMoney(context.calculation.financingAmount),
            },
            {
              label: 'Nominell rente',
              value: formatPercentage(context.calculation.nominalInterest),
            },
            {
              label: 'Effektiv rente',
              value: formatPercentage(context.calculation.effectiveInterest),
            },
          ]}
        />
        <BoxDataList
          heading="Økonomi"
          className="bg-white"
          data={[
            {
              label: 'Månedslønn',
              value: formatMoney(context.economy.monthlyIncome),
            },
            {
              label: 'Bruttoinntekt',
              value: formatMoney(
                context.economy.grossIncome ||
                  context.economy.monthlyIncome * 12,
              ),
            },
            {
              label: 'Husholdningens inntekt',
              value: formatMoney(
                context.economy.householdIncome ||
                  context.economy.monthlyIncome * 12,
              ),
            },
            {
              label: 'Husholdningens gjeld',
              value: formatMoney(context.economy.householdDebt || 0),
            },
            {
              label: 'Usikret gjeld',
              value: formatMoney(context.economy.unsecuredDebt || 0),
            },
            {
              label: 'Leasingutgifter',
              value: formatMoney(context.economy.monthlyLeasingCost || 0),
            },
            {
              label: 'Totalt billån',
              value: formatMoney(context.economy.totalCarLoan || 0),
            },
          ]}
        />
      </div>
      <div className="grid grid-cols-1fr gap-4">
        <BoxDataList
          heading="Finansieringsobjekt"
          className="bg-white"
          data={[
            {
              label: 'Merke / Modell / Variant / År',
              value: `${context.financingObject.brand} ${context.financingObject.model} ${context.financingObject.variant} ${context.financingObject.modelYear}`,
            },
            {
              label: 'Kilometerstand',
              value: `${context.financingObject.mileage}`,
            },
            {
              label: 'Kjøpspris',
              value: formatMoney(context.financingObject.purchasePrice),
            },
            {
              label: 'Gebyr',
              value: formatMoney(
                context.calculation.financingAmount -
                  context.financingObject.purchasePrice -
                  context.financingObject.advance,
              ),
            },
            {
              label: 'Egenkapital',
              value: formatMoney(context.financingObject.advance),
            },
            {
              label: 'Tilstand',
              value: `${context.financingObject.isNew ? 'Ny' : 'Brukt'}`,
            },
          ]}
        />
      </div>
    </div>
  );
}

export { TaskManualCreditEvaluationOverview };
