import { useMemo } from 'react';
import { GreenIcon } from '../icons/GreenDecisionIcon';
import { PersonIcon } from '../icons/PersonIcon';
import { RedIcon } from '../icons/RedDecisionIcon';
import { Card } from '../Card';
import { useFlowContext } from '../../hooks/useFlowContext';
import { formatMoney } from '../../utils/formatMoney';
import { cn } from '../../utils/cn';

const usedKeys = [
  'SkatteAr',
  'BruttoInntekt',
  'Inntekt',
  'Skatt',
  'Formue',
  'SkatteKlasse',
  'KommuneNavn',
  'Kommunenr',
];

const moneyKeys = ['Inntekt', 'BruttoInntekt', 'Skatt', 'Formue'];

function reduceEquations(arr: { [key: string]: any }[]): {
  [key: string]: any[];
} {
  return arr?.reduce((obj, element) => {
    usedKeys?.forEach((key) => {
      if (obj[key]) {
        obj[key]?.push(element[key]);
      } else {
        obj[key] = [element[key]];
      }
      if (key === 'Kommunenr') {
        if (!obj?.Kommune) {
          obj.Kommune = [];
        }
        obj?.Kommune.push(`${element?.Kommunenr} - ${element?.KommuneNavn}`);
      }
    });
    delete obj?.Kommunenr;
    delete obj?.KommuneNavn;
    return obj;
  }, {});
}

export default function EquationCard({ stakeholder }: { stakeholder: any }) {
  const { t } = useFlowContext();

  const approved = stakeholder?.creditData?.Scoring?.Beslutning === 'Godkjent';
  const score = stakeholder?.creditData?.Scoring?.Score;

  const equations = useMemo(() => {
    return reduceEquations(stakeholder?.creditData?.Ligning) || {};
  }, [stakeholder?.creditData?.Ligning]);

  return (
    <Card className={cn(!approved && 'border-surface-state-danger')}>
      <Card.Header
        className={`flex justify-between border-b  ${
          approved
            ? 'bg-surface-secondary border-stroke-secondary'
            : 'bg-surface-state-danger-light border-surface-state-danger'
        }`}
      >
        <div
          className={`flex items-center gap-3 ${
            approved
              ? 'text-content-state-action-dark'
              : 'text-content-state-danger-dark'
          }`}
        >
          <PersonIcon height={24} width={24} />
          {stakeholder?.name}
        </div>
        <div
          className={`flex items-center gap-3 ${
            approved
              ? 'text-content-state-action-dark'
              : 'text-content-state-danger-dark'
          }`}
        >
          <span>{approved ? t('approved') : t('declined')}</span>
          <span>{score}</span>
          {approved ? <GreenIcon size={24} /> : <RedIcon />}
        </div>
      </Card.Header>
      <Card.Content horizontalPadding={false}>
        <table className="w-full text-basic-ink">
          <thead>
            <tr>
              <td className=" pl-5 pb-5 pt-3 font-bold">
                {t('incomeAndWealth')}
              </td>
            </tr>
          </thead>
          <tbody>
            {Object?.keys(equations)?.map((key, index) => (
              <tr
                key={key + index}
                className={cn(
                  'border-t',
                  approved
                    ? 'border-stroke-primary'
                    : 'border-surface-state-danger-light',
                )}
              >
                <td className="py-3 pl-5 text-basic-ink">{t(key)}</td>
                {equations?.[key]?.map((value, index) => {
                  const isMoney = moneyKeys?.includes(key);
                  if (isMoney) {
                    return (
                      <td
                        key={key + index}
                        className="py-3 text-right text-basic-ink last:pr-5"
                      >
                        {formatMoney(value)}
                      </td>
                    );
                  }
                  return (
                    <td key={key + index} className="text-right last:pr-5">
                      {value}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </Card.Content>
    </Card>
  );
}
