import { Stack } from "../ui"
import { Aggregations } from "./Services.Aggregations"
import { ServiceItems } from "./Services.ServiceItem"
import { fetchServices } from "../../api/api"
import { useQuery } from "@tanstack/react-query"

export function Services({ t, startDate, endDate, variableQueryParam }) {
  const { data, isSuccess } = useQuery({
    queryKey: ["services", startDate, endDate, variableQueryParam],
    queryFn: () => fetchServices({ startDate, endDate, variableQueryParam }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    suspense: true,
  })

  const { aggregatedPerDay, services } = data ?? {}

  return (
    <div>
      <Stack className="items-stretch">
        <div className="flex items-end justify-between">
          <div>
            <h2 className="text-lg font-semibold text-primary-800">
              {t("services-header")}
            </h2>
          </div>
        </div>

        {isSuccess && aggregatedPerDay && (
          <div className="flex flex-row items-start gap-4">
            {services?.length ? (
              <Aggregations
                aggregations={aggregatedPerDay}
                service={services}
                t={t}
              />
            ) : null}
            <div className="flex w-full flex-col gap-4">
              {services?.map((service) => (
                <ServiceItems service={service} key={service.id} t={t} />
              ))}
            </div>
          </div>
        )}
      </Stack>
    </div>
  )
}
