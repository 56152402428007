import { formatMoney } from '../../utils/formatMoney';

function ChartBalanceTooltip({ active, payload, label }: any) {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-4 grid gap-4 border border-gray-300 shadow-lg">
        <h4 className="text-base text-black">{label}</h4>
        <ul className="grid gap-2">
          <li className="flex items-center gap-2">
            <span
              className="inline-flex h-4 w-4 rounded-full"
              style={{ backgroundColor: payload[0].fill }}
            />
            Current assets: {formatMoney(payload[0].payload.currentAssets)}
          </li>
          <li className="flex items-center gap-2">
            <span
              className="inline-flex h-4 w-4 rounded-full"
              style={{ backgroundColor: payload[1].fill }}
            />
            Fixed assets: {formatMoney(payload[0].payload.fixedAssets)}
          </li>
          <li className="flex items-center gap-2">
            <span
              className="inline-flex h-4 w-4 rounded-full"
              style={{ backgroundColor: payload[2].fill }}
            />
            Debt: {formatMoney(payload[0].payload.debt)}
          </li>
          <li className="flex items-center gap-2">
            <span
              className="inline-flex h-4 w-4 rounded-full"
              style={{ backgroundColor: payload[3].fill }}
            />
            Equity: {formatMoney(payload[0].payload.equity)}
          </li>
        </ul>
      </div>
    );
  }

  return null;
}

export { ChartBalanceTooltip };
