import { VerifyAssetPayoutTaskForm } from '../../components/Views/VerifyAssetPayoutTaskForm';
import { VerifyInvoiceTaskForm } from '../../components/Views/VerifyInvoiceTaskForm';
import { FlowContext } from '../../hooks/useFlowContext';

export function VerifyAssetPayout(props) {
  return (
    <FlowContext.Provider value={props}>
      <VerifyAssetPayoutTaskForm flowProps={props} />
    </FlowContext.Provider>
  );
}
