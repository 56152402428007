import { formatMoney } from '../../utils/formatMoney';

export default function SBLHeader({
  incomeTotal,
  name,
  identifier,
  t,
}: {
  incomeTotal: number;
  name: string;
  identifier: string;
  t: (key: string) => string;
}) {
  return (
    <div className="flex justify-between text-black rounded p-2">
      <div className="flex flex-col">
        <span className="text-lg">{name}</span>
        <span>{identifier}</span>
      </div>
      <div className="flex flex-col">
        <span className="text-lg">{formatMoney(incomeTotal)}</span>
        <span>{t('sblIncome')}</span>
      </div>
    </div>
  );
}
