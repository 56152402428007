import { cva } from "class-variance-authority"

const stackStyles = cva(["grid"], {
  variants: {
    gap: {
      "xs": ["gap-1"],
      "sm": ["gap-2"],
      "md": ["gap-4"],
      "lg": ["gap-8"],
      "xl": ["gap-12"],
    },
  },

  defaultVariants: {
    gap: "md",
  },
})

export const Stack = ({ children, gap, className }) => {
  return (
    <div
      className={stackStyles({
        gap,
        className,
      })}
    >
      {children}
    </div>
  )
}
