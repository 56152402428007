import { APPS, CaseManager, FEATURES } from '@flow/case-manager';
import customTranslations from './translations/index.json';
import { customComponents } from './configuration/customComponents';
import { Role } from './types/role';
import type { User } from './types/user';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { searchConfiguration } from './configuration/search';
import { CaseOverview } from './components/CaseOverview/CaseOverview';
import { HomePage } from './components/Views/Home';
import KpiDashboard from './components/KpiDashboard/KpiDashboard';
import { Icons } from '@flow/icons';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AccessDenied } from './components/Views/AccessDenied';
import { CreditScore } from './components/creditScore';
import { DecisionTesting } from './components/DecisionTesting';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

const skin = {
  mainLogo: () => <p className="pl-8">Asset Finance</p>,
  branding: styled.div`
    .mainMenu {
      background-color: #003755;
    }
  `,
};

function withUserAndTranslation(user) {
  return (props) => {
    console.log(props);
    return <KpiDashboard {...props} user={user} />;
  };
}

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CaseManager
        configuration={(user: User) => {
          const role = user?.profile?.role || Role.User;
          const roles = ['worker', 'admin', 'manager'];
          const isRoleInRoles = roles.includes(role);
          const bccToken = user?.profile?.bccToken;
          if (bccToken) {
            document.cookie = `.AspNet.ApplicationCookie=${bccToken};`;
          }
          return {
            apps: isRoleInRoles
              ? [APPS.Inbox, APPS.Search, APPS.Process]
              : null,
            inboxConfiguration: {
              startableProcesses: [],
              searchFields: [
                'referenceId',
                'data.currentContractDetails.Contract.Buyer.Organisation.VATnr',
                'data.currentContractDetails.Contract.Buyer.Organisation.Name',
              ],
              queuesPollingEnabled: true,
              features: [FEATURES.CaseNotes, FEATURES.CaseAssignment],
            },
            skin,
            customComponents: customComponents(user),
            customRoutes: [
              {
                path: '/overview',
                component: CaseOverview,
              },
              {
                path: '/kpi',
                component: withUserAndTranslation(role),
                navigation:
                  role === 'admin'
                    ? {
                        title: 'KPI',
                        icon: () => <Icons.Auditlog />,
                      }
                    : null,
              },
              {
                path: '/decisionTesting',
                component: DecisionTesting,
                navigation: {
                  title: 'decisionTesting',
                  icon: Icons.Withdrawal,
                },
              },
              {
                path: '/access-denied',
                component: AccessDenied,
              },
              {
                path: '',
                component: HomePage,
              },
            ],
            language: 'en',
            translates: [
              {
                language: 'no',
                translates: customTranslations.no,
              },
              {
                language: 'en',
                translates: customTranslations.en,
              },
            ],
            searchConfiguration,
          };
        }}
      />
    </QueryClientProvider>
  );
}
