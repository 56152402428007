import { useMutation } from '@tanstack/react-query';
import { Button } from '../Button/Button';
import { TaskItemBadge } from './TaskItem.Badge';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Modal } from '@flow/modal';

export function CancelItem(props) {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  function handleSuccess() {
    setLoading(false);
    setModal(false);
  }
  const { mutate } = useMutation({
    mutationFn: () => {
      return axios.post(`/api/flow/tasks/${props.taskId}/trigger`, {
        taskId: props.id,
      });
    },
    onMutate: () => setLoading(true),
    onSuccess: () => handleSuccess(),
  });

  return (
    <div className="p-3 flex flex-row justify-between items-center">
      <div className="flex flex-row items-center gap-4">
        <TaskItemBadge status="delete" />
        <p className=" font-medium text-gray-700">{props.title}</p>
      </div>
      <Button
        onClick={() => setModal(true)}
        intent="danger"
        className=" transition ease-in-out"
      >
        {loading ? (
          <div className="px-3 py-1">
            <div className="smallSpinner h-4 w-4 rounded-full animate-spin" />
          </div>
        ) : (
          'Cancel'
        )}
      </Button>
      {modal && (
        <Modal
          title={props.title}
          customWidth="20%"
          customHeight="25%"
          onClose={() => setModal(false)}
        >
          <div className="flex flex-col items-center justify-center gap-4 h-full w-full">
            <h3>Are you sure you want to cancel this task?</h3>
            <div className="flex flex-row gap-4">
              <Button
                intent="danger"
                onClick={() => {
                  mutate();
                  setModal(false);
                }}
              >
                Yes
              </Button>
              <Button intent="ghost" onClick={() => setModal(false)}>
                No
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
