interface ApplicantProps {
  applicant: {
    name: string;
    nationalId: string;
    phone: string;
    email: string;
    type: 'mainApplicant' | 'coApplicant';
  };
}

function Applicant({ applicant }: ApplicantProps) {
  const { name, nationalId, phone, email, type } = applicant;

  return (
    <div className="bg-surface border border-stroke-primary rounded-md overflow-hidden flex">
      <div className="flex items-center justify-center bg-surface-secondary p-2 text-content-secondary">
        {type === 'mainApplicant' ? (
          <span>HS</span>
        ) : type === 'coApplicant' ? (
          <span>MS</span>
        ) : null}
      </div>
      <div
        className="grid w-full p-2"
        style={{
          gridTemplateAreas: "'name phone''nationalId email'",
          gridTemplateColumns: '1fr max-content',
          gridTemplateRows: 'auto auto',
          columnGap: '0.5rem',
        }}
      >
        <h2 className="font-medium" style={{ gridArea: 'name' }}>
          {name}
        </h2>
        <span style={{ gridArea: 'nationalId' }}>{nationalId}</span>
        <span style={{ gridArea: 'phone' }}>{phone}</span>
        <span style={{ gridArea: 'email' }}>{email}</span>
      </div>
    </div>
  );
}

export { Applicant };
