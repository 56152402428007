import { formatMoney } from '../../../utils/formatMoney';

type CreditBarProps = {
  creditAssesment: number;
  documentControl: number;
  invoicing: number;
};

const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

function EfficiencyGraph({
  creditAssesment,
  documentControl,
  invoicing,
}: CreditBarProps) {
  const percentageUsed = (creditAssesment / documentControl) * 100;
  const percentageCurrentApplication = (invoicing / documentControl) * 100;
  const percentageIncreaseFromCurrentApplicationToUsed = clamp(
    ((creditAssesment - invoicing) / documentControl) * 100,
    0,
    100,
  );

  const total = creditAssesment + documentControl + invoicing;

  const creditAssesmentPercentage = (creditAssesment / total) * 100;
  const documentControlPercentage = (documentControl / total) * 100;
  const invoicingPercentage = (invoicing / total) * 100;
  return (
    <div className="grid gap-4">
      <h3 className="text-lg text-black ">Process overview</h3>
      <div className="flex flex-col">
        <div className="flex w-full bg-blue-200 h-8 scaleIn items-stretch overflow-hidden">
          <div
            className={`bg-primary-800 inline-flex`}
            style={{ width: `${documentControlPercentage}%` }}
          />
          {percentageCurrentApplication > 0 ? (
            <div
              className={`bg-primary-500 inline-flex`}
              style={{ width: `${invoicingPercentage}%` }}
            />
          ) : null}
          <div
            className={`bg-primary-200 inline-flex`}
            style={{ width: `${creditAssesmentPercentage}%` }}
          />
        </div>
      </div>
      <div className="flex flex-row items-center gap-8">
        <ul className="flex flex-row items-center gap-6 ">
          <li className="flex items-center gap-2">
            <div className="inline-flex rounded-full bg-primary-800 h-4 w-4" />{' '}
            {documentControlPercentage.toFixed(0)} % Contract changes
          </li>
          <li className="flex items-center gap-2">
            <div className="inline-flex rounded-full bg-primary-500 h-4 w-4" />{' '}
            {invoicingPercentage.toFixed(0)} % Invoices
          </li>
          <li className="flex items-center gap-2">
            <div className="inline-flex rounded-full bg-primary-200 h-4 w-4" />{' '}
            {creditAssesmentPercentage.toFixed(0)} % Main contracts
          </li>
        </ul>
      </div>
    </div>
  );
}

export { EfficiencyGraph };
