import { FormProvider, useForm } from 'react-hook-form';
import { Comment } from '../_shared/forms/fields/Comment';
import { Button } from '../Button/Button';
import { ThreeOneLayout } from '../Layout/ThreeOneLayout';
import clsx from 'clsx';
import { formatMoney } from '../../utils/formatMoney';
import { BoxWithHeader } from '../Box/BoxWithHeader';
import { SuitcaseIcon } from '../icons/Suitcase';
import { Box } from '../Box/Box';
import { RadioButtonList } from '../_shared/Inputs/RadioButtonList/RadioButtonList';
import { formatDate } from '../../utils/formatDate';

export function VerifyAssetPayoutTaskForm({ flowProps }) {
  const { task } = flowProps;
  const reporter = {
    id: flowProps?.user?.profile?.username,
    name: flowProps?.user?.profile?.name,
  };
  const method = useForm({
    defaultValues: {
      paidRegistrationFee: 'no',
      assessment: {
        comment: '',
        decision: null,
        reporter: reporter,
        date: new Date().toISOString(),
        task: task?.taskType,
      },
    },
  });
  const { handleSubmit, watch } = method;
  const onSubmit = (data) => {
    flowProps.complete(data);
  };

  const assetId = flowProps?.task?.context?.assetId;

  const invoices = flowProps?.flow?.data?.approved?.invoices;
  const matchingInvoice = findMatchingInvoice(assetId, invoices);
  const contract = flowProps?.flow?.data?.currentContractDetails?.Contract;
  const calculation =
    flowProps?.flow?.data?.currentContractDetails?.CalculationResult;
  const currencyCode =
    contract?.LeasingAssetsContract?.Assets?.find((el) => el.Id === assetId)
      ?.CurrencyCode ?? 'DKK';

  const isAsset = !!contract?.LeasingAssetsContract;
  const useVat = currencyCode === 'DKK' ? 1.25 : 1;

  const price = isAsset
    ? contract?.LeasingAssetsContract?.Assets?.find((el) => el.Id === assetId)
        ?.PriceInOtherCurrency ??
      contract?.LeasingAssetsContract?.Assets?.find((el) => el.Id === assetId)
        ?.Price
    : calculation?.Leasing?.VehiclePriceLicencePlateDeliveryAndSubidies;

  const priceWithVat = isAsset
    ? price * useVat
    : calculation?.Leasing?.VehiclePriceLicencePlateDeliveryAndSubidiesIncVAT;

  const formattedInvoices = matchingInvoice?.map((invoice) => {
    const amountToCheck =
      invoice?.legalMonetaryTotal?.taxInclusiveAmount?.amount;
    const isEligibleForFinalPayment =
      amountToCheck > priceWithVat * 0.89 ||
      amountToCheck < priceWithVat * 0.11;

    return {
      ...invoice,
      isFinalInvoice: isEligibleForFinalPayment,
    };
  });

  const sumInvoices = formattedInvoices?.reduce((acc, curr) => {
    return acc + curr?.legalMonetaryTotal?.taxInclusiveAmount?.amount ?? 0;
  }, 0);

  const sumOfAllInvoices = sumInvoices ?? 0;

  const paidRegistrationFee = watch('paidRegistrationFee');
  const registrationFee = calculation?.Leasing?.L64UpfrontPayment ?? 0;

  const checkIfFinalInvoice =
    formattedInvoices?.filter((invoice) => invoice?.isFinalInvoice)?.length ===
    0;

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <ThreeOneLayout>
          <ThreeOneLayout.Left>
            <Box>
              <h3>{task?.context?.assetType}</h3>
              <div className="grid grid-cols-6 w-full p-4 border-b border-gray-300">
                <div></div>
                <div></div>
                <InvoiceLine>Total cost</InvoiceLine>
                <InvoiceLine>Approved amount</InvoiceLine>
                <InvoiceLine>Left to approve</InvoiceLine>
                <div></div>
              </div>
              <div className="grid grid-cols-6 w-full p-4 border-b border-gray-300">
                <span>Payment(inc. VAT)</span>
                <div></div>
                <InvoiceLine>
                  {' '}
                  {formatMoney(priceWithVat, true, 0, currencyCode)}
                </InvoiceLine>
                <InvoiceLine>
                  {' '}
                  {formatMoney(sumOfAllInvoices, true, 0, currencyCode)}
                </InvoiceLine>
                <InvoiceLine>
                  {' '}
                  {formatMoney(
                    priceWithVat - sumOfAllInvoices,
                    true,
                    0,
                    currencyCode,
                  )}
                </InvoiceLine>
                {priceWithVat - sumOfAllInvoices ===
                0 ? null : sumOfAllInvoices === 0 ? (
                  <div className="flex justify-end">
                    <span className="px-3 py-1 text-xs bg-blue-200 text-blue-800 rounded-full">
                      Waiting
                    </span>
                  </div>
                ) : (
                  <div className="flex justify-end w-full ">
                    <span className="px-3 py-1 text-xs bg-yellow-100 text-yellow-800 rounded-full">
                      In progress
                    </span>
                  </div>
                )}
              </div>
              {flowProps?.task?.context?.assetType === 'Car' && (
                <div className="grid grid-cols-6 w-full p-4 border-b border-gray-300">
                  <span>Registration fee</span>
                  <div></div>
                  <InvoiceLine>
                    {' '}
                    {formatMoney(registrationFee, true, 0, currencyCode)}
                  </InvoiceLine>
                  <InvoiceLine>
                    {' '}
                    {formatMoney(
                      paidRegistrationFee === 'yes' ? registrationFee : 0,
                      true,
                      0,
                      currencyCode,
                    )}
                  </InvoiceLine>
                  <InvoiceLine>
                    {' '}
                    {formatMoney(
                      paidRegistrationFee === 'yes' ? 0 : registrationFee,
                      true,
                      0,
                      currencyCode,
                    )}
                  </InvoiceLine>
                  {paidRegistrationFee === 'no' && (
                    <div className="flex justify-end w-full ">
                      <span className="px-3 py-1 text-xs bg-yellow-100 text-yellow-800 rounded-full">
                        In progress
                      </span>
                    </div>
                  )}
                </div>
              )}
              <div className="grid grid-cols-6 w-full p-4 border-b border-gray-300">
                <span className="text-black font-medium">Total cost</span>
                <div></div>
                <InvoiceLine>
                  {' '}
                  {formatMoney(
                    priceWithVat + registrationFee,
                    true,
                    0,
                    currencyCode,
                  )}
                </InvoiceLine>
                <InvoiceLine>
                  {' '}
                  {formatMoney(
                    sumOfAllInvoices +
                      (paidRegistrationFee === 'yes' ? registrationFee : 0),
                    true,
                    0,
                    currencyCode,
                  )}
                </InvoiceLine>
                <InvoiceLine>
                  {' '}
                  {formatMoney(
                    priceWithVat -
                      sumOfAllInvoices +
                      (paidRegistrationFee === 'yes' ? 0 : registrationFee),
                    true,
                    0,
                    currencyCode,
                  )}
                </InvoiceLine>
                {priceWithVat - sumOfAllInvoices - registrationFee ===
                0 ? null : sumOfAllInvoices + registrationFee === 0 ? (
                  <div className="flex justify-end">
                    <span className="px-3 py-1 text-xs bg-blue-200 text-blue-800 rounded-full">
                      Waiting
                    </span>
                  </div>
                ) : (
                  <div className="flex justify-end w-full ">
                    <span className="px-3 py-1 text-xs bg-yellow-100 text-yellow-800 rounded-full">
                      In progress
                    </span>
                  </div>
                )}
              </div>
            </Box>
            {flowProps?.task?.context?.assetType === 'Car' && (
              <Box className="flex flex-col gap-2">
                <label
                  className="text-black font-medium"
                  htmlFor="paidRegistrationFee"
                >
                  Is registration fee paid to Motor Styrelsen?
                </label>
                <RadioButtonList
                  horizontal={true}
                  options={[
                    {
                      label: 'Yes',
                      value: 'yes',
                    },
                    { label: 'No', value: 'no' },
                  ]}
                  id="paidRegistrationFee"
                />
              </Box>
            )}

            {matchingInvoice?.length > 0 && (
              <Box>
                <div className="grid grid-cols-6 w-full p-4 border-b border-gray-300">
                  <div></div>
                  <InvoiceLine>Amount ex. VAT</InvoiceLine>
                  <InvoiceLine>VAT</InvoiceLine>
                  <InvoiceLine>Amount inc. VAT</InvoiceLine>
                  <InvoiceLine>Due date</InvoiceLine>
                  <div></div>
                </div>
                {formattedInvoices?.map((invoice, index) => {
                  return (
                    <div className="grid grid-cols-6 w-full p-4 border-b border-gray-300">
                      <InvoiceLine>Invoice {index + 1}</InvoiceLine>
                      <InvoiceLine>
                        {formatMoney(
                          invoice?.legalMonetaryTotal?.taxExclusiveAmount
                            ?.amount,
                          true,
                          0,
                          currencyCode,
                        )}
                      </InvoiceLine>
                      <InvoiceLine>
                        {formatMoney(
                          invoice?.taxTotal?.taxAmount?.amount,
                          true,
                          0,
                          currencyCode,
                        )}
                      </InvoiceLine>
                      <InvoiceLine>
                        {' '}
                        {formatMoney(
                          invoice?.legalMonetaryTotal?.payableAmount?.amount,
                          true,
                          0,
                          currencyCode,
                        )}
                      </InvoiceLine>
                      <InvoiceLine>
                        {formatDate(invoice?.paymentMeans?.paymentDueDate)}
                      </InvoiceLine>
                      {invoice?.isFinalInvoice ? (
                        <div className="flex justify-end w-full ">
                          <span className="px-3 py-1 text-xs whitespace-nowrap bg-yellow-100 text-yellow-800 rounded-full">
                            Waiting for activation
                          </span>
                        </div>
                      ) : (
                        <div className="flex justify-end w-full ">
                          <span className="px-3 py-1 text-xs bg-green-100 text-green-800 rounded-full">
                            Processed
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </Box>
            )}
          </ThreeOneLayout.Left>
          <ThreeOneLayout.Right>
            <div></div>
            <div className="flex flex-col gap-4">
              <Comment
                name="assessment.comment"
                label="Comment"
                placeholder="Enter a comment"
              />
              <Button disabled={checkIfFinalInvoice} type="submit">
                Complete
              </Button>
            </div>
          </ThreeOneLayout.Right>
        </ThreeOneLayout>
      </form>
    </FormProvider>
  );
}

export function InvoiceLine({ children, price = 0 }) {
  return (
    <span
      className={clsx(
        'flex items-center justify-start text-xs text-black',
        price > 0 ? 'text-red-500' : '',
        price < 0 ? 'text-green-600' : '',
      )}
    >
      {children}
    </span>
  );
}

export function findMatchingInvoice(assetID, invoices) {
  if (invoices?.hasOwnProperty(assetID)) {
    const hasProp = invoices[assetID];
    const listOfKey = Object.keys(invoices[assetID]);
    const fullList = listOfKey.map((key) => {
      if (hasProp?.hasOwnProperty(key)) {
        return hasProp[key];
      }
      return [];
    });
    return fullList;
  }
  return [];
}
