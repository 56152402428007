import { CompanyInformation } from '../../types/creditscore';
import { BoxWithHeader } from '../Box/BoxWithHeader';
import { SplitList, SplitListItem } from '../SplitList';
import { CarIcon } from '../icons/Car';
import { OfficeBuildingsIcon } from '../icons/OfficeBuildings';

type CompanyAndContactProps = {
  companyInformation: any;
  newData: any;
};

function CompanyAndContact({
  companyInformation,
  newData,
}: CompanyAndContactProps) {
  const { dbInternalCustomerData: customer } = newData;
  const customerFromContract = companyInformation?.Contract?.Buyer;
  return (
    <BoxWithHeader icon={<OfficeBuildingsIcon />} title="Company and contact">
      <div className="flex gap-8">
        <SplitList heading="Customer">
          <SplitListItem label="Company">
            {customer?.caseSubject?.companyName ??
              customerFromContract?.Organisation?.Name}
          </SplitListItem>

          <SplitListItem label="CVR">
            {customer?.caseSubject?.externalKey?.value ??
              customerFromContract?.Organisation?.VATnr}
          </SplitListItem>
          <SplitListItem label="NACE">
            <span className="block text-right">
              {customer?.caseSubject?.customerHoldingBranch} <br />
              {customer?.caseSubject?.customerGroupCode}
            </span>
          </SplitListItem>
          <SplitListItem label="Advisor Team">
            {customer?.caseSubject?.advisorTeam}
          </SplitListItem>
        </SplitList>
        <SplitList heading="Merchant">
          <SplitListItem label="Seller">
            {companyInformation?.Contract?.Seller?.Person?.FirstName}{' '}
            {companyInformation?.Contract?.Seller?.Person?.LastName}
          </SplitListItem>
          <SplitListItem label="Phone">
            <a
              className="text-primary-500"
              href={
                'tel:' +
                `${companyInformation?.Contract?.Seller?.Person?.Phone.length > 0 ? companyInformation?.Contract?.Seller?.Person?.Phone : '928 82 324'}`
              }
            >
              {companyInformation?.Contract?.Seller?.Person?.Phone.length > 0
                ? companyInformation?.Contract?.Seller?.Person?.Phone
                : '928 82 324'}
            </a>
          </SplitListItem>
          <SplitListItem label="Email">
            <a
              className="text-primary-500"
              href={
                'mailto:' + companyInformation?.Contract?.Seller?.Person?.Email
              }
            >
              {companyInformation?.Contract?.Seller?.Person?.Email}
            </a>
          </SplitListItem>
          <SplitListItem label="VAT">
            {companyInformation?.Contract?.Seller?.Organisation?.VATnr}
          </SplitListItem>
        </SplitList>
      </div>
    </BoxWithHeader>
  );
}

export { CompanyAndContact };
