import { ManualOnboardingTaskForm } from '../../components/Views/ManualOnboardingTaskForm';
import { FlowContext } from '../../hooks/useFlowContext';

export function ManualOnboarding(props) {
  return (
    <FlowContext.Provider value={props}>
      <ManualOnboardingTaskForm flowProps={props} />
    </FlowContext.Provider>
  );
}
