import {
  capitalizeFirstLetter,
  convertFailureRatePct,
  friendlyDate,
  getDate,
} from "./CommonFunctions"
import { ChartLineServices, Stack } from "../ui"

export function Aggregations({ aggregations, service, t }) {
  const getFirstAndLastDate = () => {
    const firstPeriodDate = friendlyDate(aggregations[0].date)
    const lastPeriodDate = friendlyDate(
      aggregations[aggregations.length - 1].date
    )

    return (
      capitalizeFirstLetter(firstPeriodDate) +
      " - " +
      capitalizeFirstLetter(lastPeriodDate)
    )
  }

  const retrieveServiceInformation = (strDate) => {
    const result = []

    service.forEach((serv) => {
      const period = serv.periods.find((p) => p.date === strDate)

      if (period && period.failureCount > 0) {
        result.push({
          name: serv.name,
          failureRatePct: period.failureRatePct,
          failureCount: period.failureCount,
          startedCount: period.startedCount,
        })
      }
    })

    return result
  }

  const lineChartData = aggregations.map((aggregation) => ({
    date: getDate(aggregation.date),
    value: convertFailureRatePct(aggregation.failureRatePct),
    additionalInformation: retrieveServiceInformation(aggregation.date),
  }))

  return (
    <div className="w-full rounded bg-white p-5 shadow-md">
      <Stack>
        <div>
          <h3 className="font-semibold">{t("all-services")}</h3>
          <span className="text-xs text-neutral-600">
            {getFirstAndLastDate()}
          </span>
          <div className="pr-10 pt-5">
            <ChartLineServices data={lineChartData} showToolTip isLarge />
          </div>
        </div>
      </Stack>
    </div>
  )
}
