import { cn } from '../../utils/cn';
import { formatNumber } from '../../utils/formatMoney';
import { formatPercentage } from '../../utils/formatPercentage';
import { ToolTip } from '../Tooltip';

interface DecisionHit {
  ruleId: string;
  description: string;
  value: string | number;
  status: 'red' | 'green' | 'yellow' | string;
  format: 'number' | 'percent' | 'string';
  tooltip: string;
}

interface TableDecisionHitsProps {
  decisionHits: DecisionHit[];
}

function descriptionFromId(id: string): string {
  switch (id) {
    case 'foreign-citizen':
      return 'Ikke norsk statsborger';
    case 'requires-attention':
      return 'Trenger spesiell oppfølging';
    case 'dead':
      return 'Personen er død';
    case 'bankrupt':
      return 'Konkurs';
    case 'alert':
      return 'Alert';
    case 'aml-sars':
      return 'Sars';
    case 'aml-hits-odd-mon':
      return 'AML hits';
    case 'requires-further-investigation':
      return 'Trenger ekstra undersøkelse';
    case 'hasPaymentRemarks':
      return 'Betalingsarnmerkninger';
    default:
      return id;
  }
}

function formatValue(
  value: string | number,
  format: 'number' | 'percent' | 'string',
) {
  // todo: more elegant solution
  if (format === 'number') {
    return formatNumber(value);
  }

  if (format === 'percent') {
    return ((value as number) * 100).toFixed(2);
  }

  return value;
}

export const TableDecisionHits: React.FC<TableDecisionHitsProps> = ({
  decisionHits,
}) => {
  return (
    <div className="grid gap-2">
      <h2 className="text-content-secondary uppercase text-sm font-medium">
        Utslag
      </h2>
      {decisionHits.length === 0 && (
        <span className="text-content-secondary text-sm">
          Ingen utslag funnet.
        </span>
      )}
      {decisionHits.map((decisionHit) => (
        <dl className="flex justify-between text-sm" key={decisionHit.ruleId}>
          <dt>
            {decisionHit.description ?? descriptionFromId(decisionHit.ruleId)}
          </dt>
          <dd className="flex items-center gap-2">
            <span
              className={cn(
                'h-3 w-3 rounded-full',
                decisionHit.status === 'red'
                  ? 'bg-surface-state-danger'
                  : decisionHit.status === 'yellow'
                    ? 'bg-surface-state-warning'
                    : 'bg-surface-state-success',
              )}
            ></span>
            <ToolTip tooltip={<p>{decisionHit.tooltip}</p>}>
              <span>{formatValue(decisionHit.value, decisionHit.format)}</span>
            </ToolTip>
          </dd>
        </dl>
      ))}
    </div>
  );
};
