import { useState, forwardRef, useEffect } from 'react';
import { Rifm } from 'rifm';
import { InputProps } from './InputBox';

export const InputBoxMaskedInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange,
      value,
      name,
      type,
      inputMode,
      placeholder,
      invalid,
      errorId,
      mask,
      disabled,
      autoComplete,
      className,
      maxLength,
    },
    ref,
  ) => {
    const [maskedValue, setMaskedValue] = useState(value);

    const handleChange = (val) => {
      setMaskedValue(val);
      // @ts-ignore
      onChange(mask.parser(val));
    };

    useEffect(() => {
      setMaskedValue(value);
    }, [value]);

    return (
      <Rifm
        //@ts-ignore
        format={mask.formatter}
        //@ts-ignore
        value={maskedValue?.toString()}
        onChange={handleChange}
      >
        {({ value, onChange }) => (
          <input
            type={type}
            inputMode={inputMode}
            ref={ref}
            name={name}
            id={name}
            className={className}
            placeholder={placeholder}
            aria-invalid={invalid}
            aria-errormessage={errorId}
            value={value}
            onChange={onChange}
            autoComplete={autoComplete}
            disabled={disabled}
            maxLength={maxLength}
          />
        )}
      </Rifm>
    );
  },
);

InputBoxMaskedInput.displayName = 'InputBoxMaskedInput';
