import { Button } from "../Button"
import { Calendar } from "../Calendar"
import { Popover, PopoverContent, PopoverTrigger } from "../Popover"
import { cn } from "../../../utils"
import { format } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { useState } from "react"

export function DatePicker({ onChange, disabledDays, defaultValue }) {
  const [date, setDate] = useState()

  const handleDateChange = (date) => {
    setDate(date)
    onChange(date)
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"subtle"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? (
            format(date, "PPP")
          ) : defaultValue ? (
            format(defaultValue, "PPP")
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleDateChange}
          initialFocus
          disabled={disabledDays}
          weekStartsOn={1}
          defaultMonth={defaultValue}
        />
      </PopoverContent>
    </Popover>
  )
}
