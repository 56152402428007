import {
  Tooltip as Root,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './Tooltip';

type Props = {
  children: React.ReactNode;
  tooltip: React.ReactNode;
};
export const ToolTip = ({ children, tooltip }: Props) => {
  return (
    <TooltipProvider delayDuration={100}>
      <Root>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent>{tooltip}</TooltipContent>
      </Root>
    </TooltipProvider>
  );
};
