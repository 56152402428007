import { CaseSummary } from '../components/CaseSummary/CaseSummary';
import { ManuallyApprove } from './tasks/ManuallyApprove';
import { menuStructure, queueDefinition } from './queues';
import { tabs } from './tabs';
import { CreditHandling } from './tasks/CreditHandling';
import { VerifyInvoice } from './tasks/VerifyInvoice';
import { ManuallyAssignInvoice } from './tasks/ManuallyAssignInvoice';
import { ManualOnboarding } from './tasks/ManualOnboarding';
import { SelectAssetsForPod } from './tasks/SelectAssetsForPod';
import { ChooseSigners } from './tasks/ChooseSigners';
import { CancelItem } from '../components/TaskItems/TaskItem.Cancel';
import { CompleteCaseAssessment } from './tasks/CompleteCaseAssessment';
//@ts-ignore
import * as userTaskGroupBy from '../utils/userTaskGroupBy';
import { CompleteTask } from './tasks/CompleteTask';
import { NeedMoreDocumentation } from './tasks/NeedMoreDocumentation';
import { UploadDocuments } from './tasks/UploadDocuments';
import { ManuallyHandleSupplier } from './tasks/ManuallyHandleSupplier';
import { VerifyAssetPayout } from './tasks/VerifyAssetPayout';
import { CompleteItem } from '../components/TaskItems/TaskItem.Complete';
import { Button } from '../components/Button/Button';
import { ButtonSkipSigning } from '../components/Button/Button.SkipSigning';
import { getPendingTaskTitle } from '../utils/pendingTask';
import { AdjustInCore } from './tasks/AdjustInCore';
const customWidth = '85%';
const customHeight = '85%';

function withUser(user) {
  return (props) => {
    return <CaseSummary {...props} user={user} />;
  };
}

function withProps() {
  return (props) => {
    return <ChooseSigners {...props} />;
  };
}

export const customComponents = (user) => {
  return {
    case: {
      caseSummary: {
        component: withUser(user),
        caseHistoryTasksLimit: 10000,
      },
      tabs,

      businessStatus: {
        map: (flow) => {
          return flow.data.status;
        },
      },
      tasks: {
        taskLists: [
          {
            query: 'taskType=!%3Dcancel-user-task&status=pending',
            groupBy: (task) => {
              return userTaskGroupBy[task.taskType] || 'Service tasks';
            },
            map: (task) => {
              const canCompleteTask =
                task?.actions?.includes('complete') ||
                task?.actions?.includes('trigger');
              return {
                title: getPendingTaskTitle(task),
                actionTitle: 'Start',
                disabled: !userTaskGroupBy[task.taskType] || !canCompleteTask,
              };
            },
          },
          /*           {
            query: 'taskType=upload-requested-documents&status=complete',

            map: (task) => {
              return {
                title: getPendingTaskTitle(task),
                actionTitle: 'Start',
                disabled: !userTaskGroupBy[task.taskType],
              };
            },
          }, */
          /*  {
            query: 'taskType=skip-signing&status=pending',
            groupBy: (task) => userTaskGroupBy[task.taskType],
            //@ts-ignore
            pendingTask: (task, props) =>
              CompleteItem(
                task,

                //@ts-ignore
                <ButtonSkipSigning task={task} flowProps={props} />,
                <ChooseSigners {...props} />,
              ),
          }, */
          {
            id: 'cancel-user-task',
            query: 'taskType=cancel-user-task',
            pendingTask: CancelItem,
          },
        ],
        taskComponents: {
          'initiate-contract': {
            component: ManuallyApprove,
            titleMap: (task) => 'Manually approve task',
            customWidth,
            customHeight,
          },
          'verify-asset-payout': {
            component: VerifyAssetPayout,
            customWidth,
            customHeight,
          },
          'manually-verify-invoice': {
            component: VerifyInvoice,
            titleMap: (task) => 'Manually verify invoice',
            customWidth,
            customHeight,
          },
          'upload-requested-documents': {
            component: UploadDocuments,
            customWidth,
            customHeight,
          },
          'four-eyes-manual-handling-credit': {
            component: CreditHandling,
            titleMap: (task) => 'Final approval',
            customWidth,
            customHeight,
          },
          'need-more-documentation': {
            component: NeedMoreDocumentation,
            titleMap: (task) => 'Need more documentation',
            customWidth,
            customHeight,
          },
          'adjust-in-core': {
            component: AdjustInCore,
            customWidth,
            customHeight,
          },
          'manually-handle-supplier': {
            component: ManuallyHandleSupplier,
            titleMap: (task) => 'Manually handle supplier',
            customWidth,
            customHeight,
          },
          'manual-handling-credit': {
            component: CreditHandling,
            titleMap: (task) => 'Manually handle credit',
            customWidth,
            customHeight,
          },
          'manually-assign-invoice': {
            component: ManuallyAssignInvoice,
            customWidth,
            customHeight,
          },

          'manually-complete-onboarding': {
            component: ManualOnboarding,
            customWidth: '60%',
            customHeight,
          },
          'select-assets-for-pod': {
            component: SelectAssetsForPod,
            customWidth,
            customHeight,
          },
          'choose-signers': {
            component: ChooseSigners,
            customWidth,
            customHeight,
          },
          'complete-full-case-assessment': {
            component: CompleteCaseAssessment,
            customWidth,
            customHeight,
          },
        },
      },
    },
    menu: {
      menuStructure: menuStructure,
    },
    queueDefinition: queueDefinition,
  };
};
