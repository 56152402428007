import { Decisions } from '../components/decisions';
import { Files } from '../components/Files/Index';
import { PROCESSES } from '../types/processes';
import { Tab } from '../types/tab';

export const tabs: Tab[] = [
  {
    id: 'files',
    title: 'Files',
    component: Files,
    hide: (flow) => {
      const flowDefinitionId = flow?.flowDefinitionId ?? '';
      if (flowDefinitionId === PROCESSES.CREDIT_PROCESS) return false;
      return false;
    },
  },
  {
    id: 'decisions',
    title: 'Decisions',
    component: Decisions,
    hide: (flow) => {
      const flowDefinitionId = flow?.flowDefinitionId ?? '';
      return false; //flowDefinitionId !== PROCESSES.CREDIT_PROCESS;
    },
    badge: (flow) => {
      const values: any[] = Object.values(flow.data?.decisions ?? {});
      const flattenedValues = values.reduce((acc, val) => {
        return acc.concat(val);
      }, []);
      return flattenedValues.length > 0 ? flattenedValues.length : undefined;
    },
  },
];
