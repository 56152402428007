import { useMutation } from '@tanstack/react-query';
import { TaskProps } from '../../types/taskProps';
import { useEffect, useRef, useState } from 'react';
import { Loader } from '../Loader/Loader';
import {
  checkDocuments,
  getPendingMessageTasks,
  overrideComplete,
  uploadNewInvoice,
} from '../../api/api';
import { FormProvider, set, useForm } from 'react-hook-form';
import { ThreeOneLayout } from '../Layout/ThreeOneLayout';
import clsx from 'clsx';
import { Button } from '../Button/Button';
import { v4 as uuidv4 } from 'uuid';
import { useFormConnect } from '../../hooks/useFormConnect';
import { format } from 'prettier';
import { formatMoney } from '../../utils/formatMoney';
import { MagicButton } from '../Button/MagicButton';
import Popover from '../utils/Popover';

type UploadedDocumentHandlerProps = {
  files: any;
  props?: any;
  close: () => void;
};

const acceptedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
const documentTypes = {
  invoice: 'Invoice',
  offer: 'Offer',
  quote: 'Quote',
  contract: 'Contract',
  other: 'Other',
};
const currencyToCountryCode = {
  nok: 'NO',
  sek: 'SE',
  dkk: 'DK',
  eur: 'GER',
  usd: 'US',
};

function mapInvoicePayload(data: any, flowId: any) {
  const countryCode =
    currencyToCountryCode[data.invoice?.currency?.toLowerCase()] ?? 'NOK';
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 27);
  const backUpDueDate = futureDate.toISOString().split('T')[0];
  return {
    ublVersionID: '2.1',
    customizationID:
      'urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0',
    profileID: 'urn:fdc:peppol.eu:2017:poacc:billing:01.01',
    id: data.invoice?.invoiceId ?? '123456',
    issueDate: data.invoice?.dueDate ?? new Date().toISOString(),
    invoiceTypeCode: '380',
    note: 'Payment due within 30 days',
    documentCurrencyCode: countryCode,
    accountingSupplierParty: {},
    accountingCustomerParty: {},
    paymentMeans: {
      paymentMeansCode: '31', //hardcoded
      paymentDueDate: data.invoice.dueDate ?? backUpDueDate,
      paymentChannelCode: 'IBAN',
      paymentId: uuidv4(),
      payeeFinancialAccount: {
        financialAccountID: `${countryCode}-${flowId.slice(0, 6)}`,
        financialInstitutionBranch: {
          financialInstitutionID: 'DABANO22',
        },
      },
    },
    accountNo: data.invoice?.accountNumber ?? '3059 87 654321',
    taxTotal: {
      taxAmount: {
        amount: Number(data?.invoice?.totalVat ?? 0),
      },
    },
    legalMonetaryTotal: {
      lineExtensionAmount: {
        amount: Number(
          data.invoice?.totalAmountExVat || data.invoice?.totalAmount || 0,
        ),
      },
      taxExclusiveAmount: {
        amount: Number(
          data.invoice?.totalAmountExVat ?? data.invoice?.totalAmount ?? 0,
        ),
      },
      taxInclusiveAmount: {
        amount: Number(data.invoice?.totalAmount ?? 0),
      },
      payableAmount: {
        amount: Number(data.invoice?.totalAmount ?? 0),
      },
    },
    invoiceLine: data.invoice.items.map((item, index) => ({
      id: `${index}`,
      invoicedQuantity: item.quantity || 0,
      lineExtensionAmount: {
        amount: Number(item.unitPrice ?? 0),
      },
      item: {
        description: item.description,
      },
      price: {
        priceAmount: {
          amount: Number(item.totalAmount ?? 0),
        },
      },
    })),
  };
}

export const UploadedDocumentHandler = ({
  files,
  close,
  props,
}: UploadedDocumentHandlerProps) => {
  const [response, setResponse] = useState<any>();
  const [confirmUpload, setConfirmUpload] = useState(false);
  //const [documentType, setDocumentType] = useState<string>('unselected');

  const method = useForm({
    defaultValues: {
      documentType: 'unselected',
      invoice: {
        invoiceId: '',
        totalAmount: '',
        totalAmountExVat: '',
        totalVat: '',
        currency: '',
        invoiceDate: '',
        dueDate: '',
        customerRef: '',
        accountNumber: '',
        items: [],
      },
      docMeta: files?.[0] ?? null,
      doc: '',
    },
  });
  const { handleSubmit, watch, setValue } = method;
  const documentType = watch('documentType');

  const onSubmit = (data) => {
    const reader = new FileReader();
    reader.readAsDataURL(files?.[0]);
    reader.onload = async () => {
      setValue('doc', reader.result as string);
      const flowId = props.flow?.flowId;
      const messageTasks = await getPendingMessageTasks(flowId).then((res) => {
        return res.data;
      });
      if (data.documentType === 'invoice') {
        if (
          messageTasks?.find(
            (task) => task.taskType === 'assign-invoice-to-contract',
          )
        ) {
          const payload = mapInvoicePayload(data, flowId);
          await uploadNewInvoice(
            payload,
            (reader.result as string).split('base64,')?.[1],
            props.flow.flowId,
            files?.[0]?.type,
          );
          close();
        } else {
          setConfirmUpload(true);
        }
      } else {
      }
    };
  };

  const {
    mutate,
    data: mutatedData,
    isLoading,
  } = useMutation({
    //@ts-ignore
    mutationFn: (data: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      return new Promise((resolve) => {
        reader.onload = async () => {
          if (acceptedTypes.includes(data.type)) {
            resolve(checkDocuments({ image: reader.result }));
            //resolve(mockInvoiceData());
          } else if (data.type === 'application/pdf') {
            console.log('PDF');
            resolve({});
          } else {
            console.log('Not supported');

            setTimeout(() => resolve({ test: 'test' }), 1000);
          }
        };
      });
    },
    onSuccess: (data: any) => {
      const result = JSON.parse(data.data.result?.content);
      setResponse(result);
      setValue('documentType', result?.type);
      if (result.type === 'invoice') {
        setValue('invoice', {
          invoiceId: result?.invoiceId,
          totalAmount: result?.totalAmount,
          totalAmountExVat: result?.totalAmountExVat,
          totalVat: result?.totalVat,
          currency: result?.currency,
          invoiceDate: result?.invoiceDate,
          dueDate: result?.dueDate,
          customerRef:
            typeof result?.customerRef === 'object'
              ? result?.customerRef?.[0]
              : result?.customerRef,
          accountNumber: result?.accountNumber,
          items: result?.itemList,
        });
      }
    },
  });

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <ThreeOneLayout>
          <ThreeOneLayout.Left>
            <div className="h-full w-full">
              {files?.[0]?.type === 'application/pdf' ? (
                <embed className="h-full w-full" src={files?.[0]?.preview} />
              ) : (
                <div>
                  <MagicButton
                    isLoading={isLoading}
                    onClick={() => mutate(files?.[0])}
                  />
                  <img
                    className="w-full"
                    src={files?.[0]?.preview}
                    alt="preview"
                  />
                </div>
              )}
            </div>
          </ThreeOneLayout.Left>
          <ThreeOneLayout.Right>
            <div className="flex justify-between flex-col h-full">
              {isLoading ? (
                <div className="w-full flex justify-center pt-10">
                  <Loader className="" />
                </div>
              ) : (
                <>
                  <div>
                    {response?.type !== 'invoice' ? (
                      documentTypes[response?.type] ||
                      'No extracted data available'
                    ) : (
                      <div>
                        <div className="grid grid-cols-2 gap-2">
                          <SummaryLineItem
                            label="Invoice Id"
                            id="invoice.invoiceId"
                          />
                          <SummaryLineItem
                            label="Amount inc. Vat"
                            currency={response?.currency}
                            id="invoice.totalAmount"
                          />
                          <SummaryLineItem
                            label="Amount ex. Vat"
                            currency={response?.currency}
                            id="invoice.totalAmountExVat"
                          />
                          <SummaryLineItem
                            label="Vat"
                            currency={response?.currency}
                            id="invoice.totalVat"
                          />
                          <SummaryLineItem
                            label="Invoice Date"
                            id="invoice.invoiceDate"
                          />
                          <SummaryLineItem
                            label="Due Date"
                            id="invoice.dueDate"
                          />
                          <SummaryLineItem
                            label="Customer Ref"
                            id="invoice.customerRef"
                            options={response?.customerRef}
                          />
                          <SummaryLineItem
                            label="Account Number"
                            id="invoice.accountNumber"
                          />
                        </div>
                        <div className="grid grid-cols-3 border-t-2 my-2">
                          <h2 className="font-bold col-span-2 text-xs">Item</h2>
                          <div className="font-bold text-xs grid grid-cols-3">
                            <h2 className="col-span-1 overflow-x-hidden hover:bg-white showOnHover">
                              Quantity
                            </h2>
                            <h2 className="col-span-2">Total Amount</h2>
                          </div>
                          {response?.itemList?.map((item) => (
                            <>
                              <p className="text-xs col-span-2">
                                {item.description}
                              </p>
                              <div className="text-xs col-span-1 grid grid-cols-3 content-end">
                                <p className="col-span-1">{item.quantity}</p>
                                <p className="col-span-2">
                                  {formatMoney(
                                    item.totalAmount,
                                    false,
                                    2,
                                    response?.currency,
                                  )}
                                </p>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="flex flex-col items-end">
                <div className="flex justify-between items-center flex-row w-full">
                  <text>Document type</text>
                  <select
                    {...method.register('documentType')}
                    className="p-1 my-2"
                    defaultValue={documentType}
                    // onChange={(event) =>
                    //   setDocumentType(event.target?.value ?? 'unselected')
                    // }
                  >
                    <option disabled value="unselected">
                      Select document type...
                    </option>
                    <option value="invoice">Invoice</option>
                    <option value="offer">Offer</option>
                    <option value="quote">Quote</option>
                    <option value="contract">Contract</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                {documentType === 'other' && (
                  <div className="flex justify-between items-center flex-row w-full">
                    <text>Other document type</text>
                    <input
                      type="text"
                      className="p-1 my-2"
                      placeholder="Other document type..."
                    />
                  </div>
                )}
                <Popover
                  show={confirmUpload}
                  onClose={() => {
                    setConfirmUpload(false);
                  }}
                  content={
                    <>
                      Case not awaiting invoice, upload as normal attachment?
                      <div className="flex flex-row mt-2">
                        <Button
                          className="w-1/2 mr-2"
                          onClick={() => {
                            getPendingMessageTasks(props.flow.flowId)
                              .then((resp) => {
                                const uploadTask = resp.data.find(
                                  (task) =>
                                    task.taskType === 'upload-file-start',
                                );
                                if (!uploadTask) {
                                  throw new Error('No upload task found');
                                }
                                const reader = new FileReader();
                                reader.readAsDataURL(files?.[0]);
                                return new Promise((resolve) => {
                                  reader.onload = async () => {
                                    resolve(
                                      overrideComplete(uploadTask.taskId, {
                                        base64file: (
                                          reader.result as string
                                        )?.split('base64,')?.[1],
                                        filename: files?.[0]?.name,
                                        type: documentType,
                                        mimeType: files?.[0]?.type,
                                      }),
                                    );
                                  };
                                });
                              })
                              .then(() => {
                                setConfirmUpload(false);
                                close();
                              })
                              .catch((error) => console.log(error));
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          intent="secondary"
                          className="w-1/2"
                          onClick={() => setConfirmUpload(false)}
                        >
                          No
                        </Button>
                      </div>
                    </>
                  }
                >
                  <Button
                    className="w-full"
                    disabled={
                      isLoading ||
                      documentType === 'unselected' ||
                      confirmUpload
                    }
                    type="submit"
                  >
                    {documentType !== 'unselected'
                      ? `Upload ${documentTypes[documentType]}`
                      : 'Upload'}
                  </Button>
                </Popover>
              </div>
            </div>
          </ThreeOneLayout.Right>
        </ThreeOneLayout>
      </form>
    </FormProvider>
  );
};

function SummaryLineItem({
  id,
  label,
  options,
  currency,
}: {
  id: string;
  label: string;
  options?: string[];
  currency?: string;
}) {
  const textRef = useRef<any>(null);
  const [active, setActive] = useState(false);
  const props = useFormConnect(id);
  props.onBlur = () => setActive(false);
  const formattedMoney =
    currency && formatMoney(props.value, true, 2, currency);
  useEffect(() => {
    if (active) {
      textRef.current?.focus();
    }
  }, [active]);
  return (
    <>
      <label htmlFor={id}>{label}</label>
      {options && typeof options === 'object' ? (
        <select className="text-right cursor-pointer" {...props}>
          {options.map((option) => (
            <option value={option} label={option} />
          ))}
        </select>
      ) : active || !formattedMoney ? (
        <input
          className="text-right cursor-pointer"
          type="text"
          {...props}
          ref={textRef}
        />
      ) : (
        <span
          className="cursor-pointer text-right"
          onClick={() => setActive(true)}
        >
          {formattedMoney}
        </span>
      )}
    </>
  );
}
