import { CircleIconWithTooltip } from "../tasks/components/CircleIconWithTooltip";

export function NumberOfApplicationsUsed({
  count
}: {
  count: number | undefined;
}) {
  return (
    <CircleIconWithTooltip
      count={count}
      label="Total number of applications used for this test run."
    />
  );
}
