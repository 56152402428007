import clsx from 'clsx';

export function Loader({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        'h-12 w-12 rounded-full bg-transparent spinner animate-spin',
        className,
      )}
    />
  );
}
