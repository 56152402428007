import { Skeleton, Stack } from "../ui"

function ItemSkeleton() {
  return (
    <div>
      <Stack>
        <div>
          <Stack gap="xs">
            <Skeleton className="h-4 w-[220px]" />
            <Skeleton className="h-2 w-[240px]" />
          </Stack>
        </div>
        <div>
          <Stack gap="md">
            <Skeleton className="h-3 w-[320px]" />
            <Skeleton className="h-3 w-[220px]" />
          </Stack>
        </div>
      </Stack>
    </div>
  )
}

export function ServicesLoading() {
  return (
    <div>
      <Stack>
        <div>
          <h2 className="text-lg font-semibold text-primary-800">
            Tjenester
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-white p-4 pb-[280px] shadow-md">
            <div className="grid gap-2">
              <Skeleton className="h-4 w-[220px]" />
              <Skeleton className="h-4 w-[240px]" />
            </div>
          </div>
          <div className="flex w-full flex-col gap-4">
            <div className="grid gap-2 bg-white p-4 pb-[140px] shadow-md">
              <Skeleton className="h-4 w-[220px]" />
              <Skeleton className="h-2 w-[240px]" />
            </div>
            <div className="grid gap-2 bg-white p-4 pb-[140px] shadow-md">
              <Skeleton className="h-4 w-[220px]" />
              <Skeleton className="h-2 w-[240px]" />
            </div>
          </div>
        </div>
      </Stack>
    </div>
  )
}
