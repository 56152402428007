import { format } from "date-fns"
import nbLocale from "date-fns/locale/nb"

export const convertFailureRatePct = (str) => {
  return Number(str.slice(0, -1))
}

export const getDate = (strDate) => {
  const day = new Date(strDate).getDate()
  return day
}

export const friendlyDate = (strDate) => {
  return format(new Date(strDate), "EEEE d. LLLL yyyy", {
    locale: nbLocale,
  })
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
