import { useQuery } from '@tanstack/react-query';
import { CenteredSpinner } from '@flow/icons';
import { DocumentsCard } from './DocumentCard';
import axios from 'axios';
import {
  fetchAttachmentByKey,
  fetchAttachmentsByFlowId,
} from '../../utils/attachments';
import { TaskProps } from '../../types/taskProps';
import { FlowContext } from '../../hooks/useFlowContext';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { Modal } from '@flow/modal';
import { UploadedDocumentHandler } from './UploadedDocumentHandler';
import clsx from 'clsx';

const MAX_SIZE = 20 * 1024 * 1024;

export function Files(props: TaskProps) {
  const { flow, t } = props;
  const { flowId } = flow;

  const [files, setFiles] = useState([]);
  const [modal, setModal] = useState(false);
  const [isDragOver, setIsDragOver] = useState(0);

  function handleFileUpload(file) {
    const reader = new FileReader();
    setFiles(file);
    setModal(true);

    // append(file);
    reader.readAsBinaryString(file);
  }

  const { getRootProps, getInputProps, fileRejections, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFileUpload(
        //@ts-ignore
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    accept: {
      'application/pdf': [],
      'image/*': [],
    },
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: MAX_SIZE,
  });
  const { data: uploadedfiles, isFetching } = useQuery({
    queryKey: ['files', flowId],
    queryFn: async () => {
      const { attachments } = await fetchAttachmentsByFlowId(flowId);
      const attachmentsWithVersions = await Promise.all(
        attachments.map(async (attachment: any) => {
          let data;
          try {
            data = await fetchAttachmentByKey(flowId, attachment.attachmentKey);
          } catch (err) {
            if (axios.isAxiosError(err)) {
              data = null;
            }
          }

          const version = data?.attachment;
          if (!version) {
            console.error('failed to fetch attachment:', attachment);
            return {
              attachmentKey: attachment.attachmentKey,
              owner: '',
              filename: attachment.attachmentKey,
              hasFailed: true,
            };
          }
          if (version.previousVersions) {
            return [
              { attachmentKey: attachment.attachmentKey, ...version },

              ...Object.values(version.previousVersions).map((element: any) => {
                return { attachmentKey: attachment.attachmentKey, ...element };
              }),
            ];
          } else {
            return { attachmentKey: attachment.attachmentKey, ...version };
          }
        }),
      );
      return {
        flowId,
        attachments: attachmentsWithVersions
          .flat()
          .sort((a, b) => b.version - a.version),
      };
    },
  });
  const customerDocuments = uploadedfiles?.attachments
    ?.filter(
      (file: any) =>
        file.owner === 'mainApplicant' || file.owner === 'coApplicant',
    )
    .concat(props?.task?.context?.cloudmersiveErrors ?? []);

  const caseWorkerDocuments = uploadedfiles?.attachments?.filter(
    (file: any) => file.owner === 'caseworker',
  );

  const remainingDocuments = uploadedfiles?.attachments?.filter(
    (file: any) =>
      file.owner !== 'mainApplicant' &&
      file.owner !== 'coApplicant' &&
      file.owner !== 'caseworker',
  );
  const { ref, ...rootProps } = getRootProps();

  if (isFetching) return <CenteredSpinner />;
  return (
    <FlowContext.Provider value={props}>
      <div
        className="relative flex flex-col gap-5"
        onDragEnter={() => setIsDragOver((current) => current + 1)}
        onDragLeave={() => setIsDragOver((current) => current - 1)}
      >
        {isDragOver > 0 && (
          <div
            {...rootProps}
            className={clsx(
              'flex justify-center items-center absolute w-full h-full rounded-xl border-2 border-dashed border-gray-400 backdrop-filter backdrop-blur-lg shadow-lg z-50',
            )}
          >
            <input id={`${document}`} {...getInputProps()} type="file" />
            <p>Drop to upload file...</p>
          </div>
        )}
        <DocumentsCard
          title={t('customerUploadedDocuments')}
          data={customerDocuments ?? []}
          color="blue"
        />
        <DocumentsCard
          title={t('caseWorkerUploadedDocuments')}
          data={caseWorkerDocuments ?? []}
          color="yellow"
        />
        <DocumentsCard
          title={t('flowUploadedDocuments')}
          data={remainingDocuments ?? []}
          color="green"
        />
        <div
          className="cursor-pointer flex justify-center"
          onClick={() => open()}
        >
          <span className="text-xs ml-4 border-2 border-dashed border-gray-200 rounded-lg p-2 hover:border-gray-600 hover:shadow-lg">
            Click here or drag and drop files to upload...
          </span>
        </div>
        {modal && (
          <Modal
            title="Uploaded file"
            customWidth="80%"
            customHeight="80%"
            onClose={() => {
              setIsDragOver(0);
              setModal(false);
            }}
          >
            <UploadedDocumentHandler
              files={files}
              props={props}
              close={() => {
                setModal(false);
                setIsDragOver(0);
              }}
            />
          </Modal>
        )}
      </div>
    </FlowContext.Provider>
  );
}
