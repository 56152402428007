import { useFormConnect } from '../../../../hooks/useFormConnect';
import { InputBox } from '../../Inputs/InputBox';

export const Email = ({
  name,
  label = '',
  placeholder = '',
  disabled = false,
}) => {
  const props = useFormConnect(name);
  return (
    <InputBox
      type="email"
      disabled={disabled}
      placeholder={placeholder}
      label={label}
      {...props}
    ></InputBox>
  );
};
