import { useState } from 'react';
import { TaskItemBadge } from './TaskItem.Badge';

type TaskItemBadgeLabledHoverProps = {
  status: 'delete' | 'completed' | 'active' | 'unknown';
  label: string;
  className?: string;
  style?: { [key: string]: string };
};

export function TaskItemBadgeLabledHover({
  status,
  label,
  className,
  style,
}: TaskItemBadgeLabledHoverProps) {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      className={className}
      style={style}
    >
      <TaskItemBadge status={status} className={className} style={style} />
      {hover && (
        <span className="text-xs absolute bottom-2 p-2 right-2 bg-yellow-200 shadow-md translate-x-1/2 transform">
          <div className=" -rotate-45 w-2 h-2 bg-yellow-200 absolute left-1/2 -bottom-1 -translate-x-1/2 transform"></div>
          {label}
        </span>
      )}
    </div>
  );
}
