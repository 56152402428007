import { Button } from '../Button/Button';
import { Comment } from '../_shared/forms/fields/Comment';
import { DecisionComment } from '../_shared/forms/fields/DecisionComment';

export function CreditHandlingAssessment({
  filteredAssessments,
  is4Eyes,
  save,
  watch,
}) {
  const fourEyesComment = watch('fourEyesComment');
  const assessmentComment = watch('assessment.comment');
  return (
    <>
      {!!filteredAssessments &&
        filteredAssessments?.map((item) => {
          const assessment = item?.assessment;
          if (item?.userType === 'FIRST' && assessment?.comment !== '') {
            return (
              <div>
                <div className="flex flex-col rounded-md">
                  <DecisionComment
                    disabled={true}
                    name=""
                    label="Final Approval"
                    placeholder={assessment?.comment}
                    reporter={assessment?.reporter}
                    date={assessment?.date}
                    userType={item?.userType}
                  ></DecisionComment>
                </div>
              </div>
            );
          }
          if (item?.userType === 'SECOND' && item?.fourEyesComment !== '') {
            return (
              <div>
                <div className="flex flex-col rounded-md">
                  <DecisionComment
                    disabled={true}
                    name=""
                    label="Caseworker Assessment"
                    placeholder={item?.fourEyesComment}
                    reporter={item?.fourEyesReporter}
                    date={item?.date}
                    userType={item?.userType}
                  ></DecisionComment>
                </div>
              </div>
            );
          }
        })}
      {is4Eyes ? (
        <>
          <Comment name="fourEyesComment" label="Final Approval" />
          <Button onClick={() => save({ fourEyesComment })} intent="secondary">
            Save draft
          </Button>
        </>
      ) : (
        <>
          <Comment name="assessment.comment" label="Caseworker Assessment" />
          <Button
            onClick={() => save({ assessmentComment })}
            intent="secondary"
          >
            Save draft
          </Button>
        </>
      )}
    </>
  );
}
