export function formatMoney(
  value: number | string,
  prefix = false,
  decimals = 0,
  currency = 'DKK',
) {
  const numberValue = Number(value);
  const formatedNumber = new Intl.NumberFormat('en-GB', {
    style: prefix ? 'currency' : 'decimal',
    currency: currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(isNaN(numberValue) ? 0 : numberValue);
  return formatedNumber;
}

export function formatNumber(
  value: number | string,
  prefix = false,
  minDecimals = 0,
  maxDecimals = 2,
) {
  const numberValue = Number(value);
  const formatedNumber = new Intl.NumberFormat('no-NB', {
    style: prefix ? 'currency' : 'decimal',
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  }).format(isNaN(numberValue) ? 0 : numberValue);
  return formatedNumber;
}
