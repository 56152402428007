import { convertFailureRatePct, getDate } from "./CommonFunctions"
import { ChartLineServices, Stack } from "../ui"

export function ServiceItems({ service, t }) {
  const lineChartData = service.periods.map((period) => ({
    date: getDate(period.date),
    value: convertFailureRatePct(period.failureRatePct),
    errorMessages: period?.errorMessages,
  }))

  return (
    <div className="rounded bg-white p-5 shadow-md">
      <Stack>
        <div className="flex flex-row ">
          <div className="relative flex flex-col	whitespace-nowrap">
            <h3 className="font-semibold">
              {t("service")} {service.name}
            </h3>
            <span className="pt-2 text-xs text-neutral-800">
              {t("total-amount-service-failures")}:{" "}
              {service.statistics.failureCount} (
              {service.statistics.failureRatePct})
            </span>
          </div>
          <div className="m-1 w-full">
            <ChartLineServices data={lineChartData} showToolTip={true} />
          </div>
        </div>
      </Stack>
    </div>
  )
}
