import { Skeleton, Stack } from "../ui"

export function SkeletonMetric() {
  return (
    <Stack gap="lg" className="h-full items-stretch">
      <Stack gap="sm">
        <div className="flex justify-between">
          <Skeleton className="h-4 w-[90px] rounded" />
        </div>
        <span className="text-4xl font-semibold">
          <Skeleton className="h-8 w-[150px] rounded" />
        </span>
      </Stack>
      <div className="flex flex-col justify-end">
        <div className="ml-6 animate-pulse border-l border-neutral-400 pl-2">
          <div className="flex w-full items-end justify-between gap-4 pr-4">
            <div>
              <div className="flex flex-col items-center gap-1">
                <Skeleton className="h-[76px] w-[8px] rounded-full" />
                <Skeleton className="h-4 w-4 rounded-full" />
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center gap-1">
                <Skeleton className="h-[56px] w-[8px] rounded-full" />
                <Skeleton className="h-4 w-4 rounded-full" />
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center gap-1">
                <Skeleton className="h-[96px] w-[8px] rounded-full" />
                <Skeleton className="h-4 w-4 rounded-full" />
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center gap-1">
                <Skeleton className="h-[116px] w-[8px] rounded-full" />
                <Skeleton className="h-4 w-4 rounded-full" />
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center gap-1">
                <Skeleton className="h-[136px] w-[8px] rounded-full" />
                <Skeleton className="h-4 w-4 rounded-full" />
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center gap-1">
                <Skeleton className="h-[160px] w-[8px] rounded-full" />
                <Skeleton className="h-4 w-4 rounded-full" />
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center gap-1">
                <Skeleton className="h-[144px] w-[8px] rounded-full" />
                <Skeleton className="h-4 w-4 rounded-full" />
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center gap-1">
                <Skeleton className="h-[132px] w-[8px] rounded-full" />
                <Skeleton className="h-4 w-4 rounded-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Stack>
  )
}

export function SkeletonAggregate() {
  return (
    <Stack gap="lg" className="h-full items-stretch">
      <div className="flex flex-col items-center justify-end">
        <div className="relative">
          <Skeleton className="h-[120px] w-[120px] rounded-full border-8 border-neutral-400 bg-white" />
          <Skeleton className="absolute left-1/2 top-1/2 h-8 w-8 -translate-x-1/2 -translate-y-1/2" />
        </div>
      </div>
      <div className="grid gap-2">
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-4 rounded-full" />
          <Skeleton className="h-3 w-1/2 rounded-full" />
        </div>
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-4 rounded-full" />
          <Skeleton className="h-3 w-4/12 rounded-full" />
        </div>
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-4 rounded-full" />
          <Skeleton className="h-3 w-6/12 rounded-full" />
        </div>
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-4 rounded-full" />
          <Skeleton className="h-3 w-8/12 rounded-full" />
        </div>
      </div>
    </Stack>
  )
}
