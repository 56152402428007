import * as Tabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { useState } from 'react';
import { Button } from '../Button/Button';
import { CreditHandlingOverview } from '../CreditHandling/CreditHandling.Overview';
import { CreditHandlingResult } from '../CreditHandling/CreditHandling.Result';
import { RadioButtonList } from '../_shared/Inputs/RadioButtonList/RadioButtonList';
import { Text } from '../_shared/forms/fields/Text';
import { CreditHandlingBalance } from '../CreditHandling/CreditHandling.Balance';
import { CreditHandlingCompanyInformation } from '../CreditHandling/CreditHandling.CompanyInformation';
import { CreditHandlingAssessment } from '../CreditHandling/CreditHandling.Assessment';

export function CreditHandlingTaskForm({ flowProps }) {
  const is4Eyes = flowProps?.task?.taskType.includes('four-eyes');
  const firstUser = flowProps?.task?.context?.firstUser?.assessment;
  const reporter = {
    name: flowProps?.user?.profile?.name,
    id: flowProps?.user?.profile?.username,
    role: flowProps?.user?.profile?.role,
  };

  const method = useForm({
    resolver: zodResolver(
      z
        .object({
          assessment: z.object({
            comment: z.string().optional(),
            decision: z.string().optional(),
            task: z.string(),
            date: z.string().datetime(),
            reporter: z.object({
              id: z.string(),
              name: z.string(),
              role: z.string(),
            }),
            attachments: z
              .array(
                z.object({
                  id: z.string(),
                  name: z.string(),
                }),
              )
              .optional(),
          }),
          newCreditLimit: z.string().transform(Number).or(z.number()),
          date: z.string().datetime(),
          fourEyesDecision: z.string().optional(),
          fourEyesComment: z.string().optional(),
          fourEyesReporter: z.object({
            id: z.string(),
            name: z.string(),
            role: z.string(),
          }),
        })
        .superRefine((val, ctx) => {
          if (val.assessment.comment === '' && !is4Eyes) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please add a comment!',
              path: ['assessment.comment'],
            });
          }
          if (val.assessment.decision === '' && !is4Eyes) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please make a decision!',
              path: ['assessment.decision'],
            });
          }
          if (val.fourEyesComment === '' && is4Eyes) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please add a comment!',
              path: ['fourEyesComment'],
            });
          }
          if (val.fourEyesDecision === '' && is4Eyes) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please make a decision!',
              path: ['fourEyesDecision'],
            });
          }
        }),
    ),
    defaultValues: {
      newCreditLimit: flowProps?.task?.context?.creditLimit || null,
      manualCreditProcessStatus:
        flowProps?.task?.data?.manualCreditProcessStatus || null,
      assessment: {
        comment: flowProps?.task?.data?.assessmentComment || '',
        decision: '',
        reporter: reporter,
        task: flowProps?.task?.taskType,
        date: new Date().toISOString(),
        attachments: [],
      },
      date: new Date().toISOString(),
      fourEyesDecision: '',
      fourEyesComment: flowProps?.task?.data?.fourEyesComment || '',
      fourEyesReporter: reporter,
    },
  });

  const [active, setActive] = useState('1');
  const tabs = [
    {
      value: '1',
      label: 'Overview',
      showNotification: false,
    },
    {
      value: '2',
      label: 'Result',
      showNotification: false,
    },
    {
      value: '3',
      label: 'Balance',
      showNotification: false,
    },
    {
      value: '5',
      label: 'Company information',
      showNotification: false,
    },
    {
      value: '6',
      label: 'Credit Assessment',
      showNotification: true,
    },
  ];
  const creditScoreData = flowProps?.flow?.data?.creditProcess?.creditScore;

  const newCreditData = {
    dbInternalCustomerCredit:
      flowProps?.flow?.data?.dbInternalCustomerCreditData,
    dbInternalCustomerData: flowProps?.flow?.data?.dbInternalCustomerData,
  };

  const { handleSubmit, formState, watch } = method;

  const manualCreditProcessStatus = watch('assessment.decision');
  const comment = watch('assessment.comment');
  const { complete, save } = flowProps;
  const onSubmit = (data) => {
    complete(data);
  };

  const creditLimitWatcher = watch('newCreditLimit');
  const filteredAssessments = flowProps?.flow?.data?.assessments?.filter(
    (item) =>
      item?.assessment?.task === 'manual-handling-credit' ||
      (item?.assessment?.task === 'four-eyes-manual-handling-credit' &&
        item?.userType === 'FIRST' &&
        item?.assessment?.comment !== '') ||
      (item?.userType === 'SECOND' && item?.fourEyesComment !== ''),
  );

  return (
    <FormProvider {...method}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-4 h-full"
      >
        <Tabs.Root
          defaultValue="1"
          className="col-span-3 overflow-scroll h-full border-r border-gray-200 bg-gray-100"
        >
          <Tabs.List className="flex flex-row gap-4 items-center border-b px-4 bg-white">
            {tabs.map((tab) => {
              return (
                <Tabs.Trigger
                  onClick={() => setActive(tab.value)}
                  value={tab.value}
                  className={clsx(
                    active === tab.value ? 'tabs' : 'inactiveTabs',
                    'text-blue-500 text-xs p-4 flex flex-row items-center gap-2',
                  )}
                >
                  {tab.label}
                  {tab.showNotification &&
                    (formState?.errors?.assessment?.comment ||
                      formState?.errors?.fourEyesComment) && (
                      <div className="w-4 h-4 bg-red-500 rounded-full text-white font-bold flex items-center justify-center">
                        !
                      </div>
                    )}
                </Tabs.Trigger>
              );
            })}
          </Tabs.List>
          <Tabs.Content value="1" className="p-4 grid gap-4">
            <CreditHandlingOverview
              newCreditData={newCreditData}
              newCreditLimit={creditLimitWatcher}
              creditScoreData={creditScoreData}
              variables={flowProps?.task?.variables}
              flowProps={flowProps?.flow?.data}
              context={flowProps?.task?.context}
            />
          </Tabs.Content>
          <Tabs.Content value="2" className="p-4">
            <CreditHandlingResult
              creditScoreData={creditScoreData}
              context={flowProps?.task?.context}
            />
          </Tabs.Content>
          <Tabs.Content value="3" className="p-4">
            <CreditHandlingBalance creditScoreData={creditScoreData} />
          </Tabs.Content>
          <Tabs.Content value="5" className="p-4">
            <CreditHandlingCompanyInformation
              creditScoreData={creditScoreData}
            />
          </Tabs.Content>
          <Tabs.Content value="6" className="px-4 grid gap-4">
            <CreditHandlingAssessment
              filteredAssessments={filteredAssessments}
              is4Eyes={is4Eyes}
              save={save}
              watch={watch}
            />
          </Tabs.Content>
        </Tabs.Root>
        <SideBar
          firstUser={firstUser}
          savePayload={{
            comment,
            manualCreditProcessStatus,
          }}
          is4Eyes={is4Eyes}
          flowProps={flowProps}
          newCreditData={newCreditData}
          formErrors={formState?.errors}
        />
      </form>
    </FormProvider>
  );
}

export function SideBar({
  flowProps,
  newCreditData,
  is4Eyes,
  savePayload,
  firstUser,
  formErrors,
}) {
  const flowData = flowProps?.flow?.data?.currentContractDetails;
  const buyer = flowData?.Contract?.Buyer;
  const seller = flowData?.Contract?.Seller;
  const asset = flowData?.Contract?.Vehicle;
  const askedCreditAmount = flowProps?.task?.variables?.askedCreditAmount
    ? flowProps?.task?.variables?.askedCreditAmount
    : 0;

  const totalLimit = newCreditData?.dbInternalCustomerCredit?.creditLimit;
  const creditLimitUtilized =
    newCreditData?.dbInternalCustomerCredit?.creditLimitUtilized;

  const { save } = flowProps;

  return (
    <div className="p-4 flex flex-col justify-between">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className=" font-medium text-black">Applicant</h2>
          <DescList title="Company" value={buyer?.Organisation?.Name} />
        </div>
        <div className="flex flex-col gap-1">
          <h2 className=" font-medium text-black">Contact</h2>
          <DescList
            title="Name"
            value={seller?.Person?.FirstName + ' ' + seller?.Person?.LastName}
          />
          <DescList title="Email" value={seller?.Person?.Email} />
        </div>
        {asset?.BrandName && (
          <div className="flex flex-col gap-1">
            <h2 className=" font-medium text-black">Assets</h2>
            <ul>
              <li>
                {asset?.BrandName} - {asset?.EngineSize} {asset?.EngineType}
              </li>
            </ul>
          </div>
        )}
        {creditLimitUtilized + askedCreditAmount > totalLimit && (
          <Text
            disabled={is4Eyes}
            name="newCreditLimit"
            label="New credit limit"
          />
        )}
      </div>
      <div className="flex flex-col gap-4">
        {is4Eyes && (
          <div className="flex flex-col gap-4">
            <p className="font-medium text-black">Previous decision:</p>
            <div className="flex flex-col gap-2">
              <dl className="flex flex-row justify-between">
                <dt>Caseworker:</dt>
                <dd>{firstUser?.reporter?.name}</dd>
              </dl>
              <dl className="flex flex-row justify-between">
                <dt>Decision:</dt>
                <dd>{firstUser?.decision}</dd>
              </dl>
            </div>
          </div>
        )}
        <label
          className=" font-medium text-black"
          aria-label="manualCreditProcessStatus"
          id="assessment.decision"
        >
          Decision
        </label>
        {is4Eyes ? (
          <RadioButtonList
            id="fourEyesDecision"
            options={[
              { label: 'Approve', value: 'Approved' },
              { label: 'Send back to caseworker', value: 'Declined' },
            ]}
          />
        ) : (
          <RadioButtonList
            id="assessment.decision"
            options={[
              { label: 'Approve', value: 'Approved' },
              { label: 'Decline', value: 'Declined' },
            ]}
          />
        )}
        {/*TODO: Swap input to textarea*/}
        {formErrors?.assessment?.comment || formErrors?.fourEyesComment ? (
          <div>
            <span className="text-red-500">
              You need to add a comment in the credit assesment tab before
              proceeding
            </span>
          </div>
        ) : null}

        <div className="flex justify-start items-start gap-2">
          <Button type="submit">Complete</Button>
        </div>
      </div>
    </div>
  );
}

export function DescList({ title, value }) {
  return (
    <dl className="flex items-center gap-2">
      <dt> {title}:</dt>
      <dd> {value}</dd>
    </dl>
  );
}
