import React, { ButtonHTMLAttributes } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { clsx } from 'clsx';
import { Spinner } from '@flow/icons';

const buttonStyles = cva(
  [
    'inline-flex transition-all items-center px-7 py-1 text-sm rounded-md text-xs text-center items-center justify-center',
  ],
  {
    variants: {
      intent: {
        primary: [
          'bg-primary-500',
          'hover:bg-primary-border',
          'border border-primary-border',
          'text-white',
          'focus: outline-2 outline-primary-focus',
        ],
        secondary: [
          'bg-transparent',
          'border-2 border-primary-600',
          'text-primary-600',
          'focus: outline-2 outline-primary-focus',
          'hover:shadow-md',
        ],
        danger: [
          'bg-danger-500',
          'hover:bg-danger-600',
          'border border-danger-600',
          'text-white',
          'focus:outline-2 outline-red-500 outline-none',
        ],
        task: [' bg-primary-500 text-white px-6'],
        ghost: ['bg-transparent', 'border border-ghost-500', 'hover:shadow-md'],
      },
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonStyles> {
  name?: string;
  intent?: 'primary' | 'secondary' | 'danger' | 'ghost' | 'task';
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  children: React.ReactNode;
  className?: string;
  isFull?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      intent = 'primary',
      className = '',
      isFull,
      type = 'button',
      disabled,
      isLoading,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          buttonStyles({ intent }),
          isFull ? 'w-full' : 'w-fit',
          disabled ? 'cursor-not-allowed pointer-events-none opacity-50' : '',
          isLoading ? 'animate-pulse' : '',
          className,
        )}
        disabled={disabled}
        {...props}
      >
        {isLoading && (
          <span className="absolute top-1/2 left-2 transform -translate-y-1/2">
            <Spinner
              size={16}
              color={intent === 'primary' ? 'white' : 'blue'}
            />
          </span>
        )}
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
