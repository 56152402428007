import { useFlowContext } from '../../hooks/useFlowContext';
import CommercialInterests from './CommercialInterests';
import EquationCard from './EquationCard';
import PaymentNotes from './PaymentNotes';

export default function CreditCheck() {
  const { task } = useFlowContext();
  const stakeholders: any[] = task.context.applicants;
  console.log(stakeholders);
  return (
    <div className="flex flex-col gap-10">
      {stakeholders?.map((stakeholder) => (
        <div key={stakeholder?.firstName} className="flex flex-col gap-5">
          <EquationCard stakeholder={stakeholder} />
          <CommercialInterests stakeholder={stakeholder} />
          <PaymentNotes stakeholder={stakeholder} />
        </div>
      ))}
    </div>
  );
}
