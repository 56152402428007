import { useLocation } from 'react-router-dom';
import { CaseSummary } from '../CaseSummary/CaseSummary';
import { useQuery } from '@tanstack/react-query';
import { getFlow, getFlowByReference } from '../../api/api';
import clsx from 'clsx';
import { Chevron } from '../icons/Chevron';
import { useState } from 'react';
import { formatDate } from '../../utils/formatDate';
import * as Tabs from '@radix-ui/react-tabs';
import { Button } from '../Button/Button';
import { CaseOverviewChangeContract } from './CaseOverview.ChangeContract';
import { CaseOverViewFullTaskList } from './CaseOverview.FullTaskList';
import { CaseOverviewComments } from './CaseOverview.Comments';

export function CaseOverview(props) {
  const data = useLocation();
  const contractId = data?.pathname?.split('/')[2];
  const {
    data: flows,
    refetch,
    isLoading,
  } = useQuery({
    queryFn: () => getFlowByReference(contractId),
    queryKey: ['flow', contractId],
  });

  const flow = flows?.find(
    (flow) => flow.flowDefinitionId === 'initiate-contract',
  );
  const activeChanges = flows
    ?.filter(
      (flow) =>
        flow?.flowDefinitionId === 'contract-change' &&
        flow?.status === 'active',
    )
    ?.map((flow) => ({
      taskType: flow?.referenceName,
      flowId: flow?.flowId,
      status: 'active',
      date: flow?.updatedAt,
    }));

  const contractActive = flow?.status === 'completed';
  const coreId = flow?.data?.coreAccount?.resultNewAccountNo;
  const [active, setActive] = useState('1');

  if (isLoading)
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <div className="h-12 w-12 rounded-full bg-transparent spinner animate-spin" />
      </div>
    );

  const tabs = [
    {
      value: '1',
      label: 'Overview',
    },
    {
      value: '2',
      label: 'Comments',
    },
    {
      value: '3',
      label: 'All tasks',
    },
  ];

  if (flow) {
    return (
      <div className="overflow-scroll">
        <div className="flex p-4 flex-row gap-4 items-center border-b">
          ID - {contractId}
        </div>
        <Tabs.Root defaultValue="1" className="grid gap-4">
          <Tabs.List className="flex flex-row gap-4 items-center border-b px-4">
            {tabs.map((tab) => {
              return (
                <Tabs.Trigger
                  onClick={() => setActive(tab.value)}
                  value={tab.value}
                  className={clsx(
                    active === tab.value ? 'tabs' : 'inactiveTabs',
                    'text-blue-500 text-xs p-4',
                  )}
                >
                  {tab.label}
                </Tabs.Trigger>
              );
            })}
          </Tabs.List>
          <Tabs.Content value="1">
            <div className="w-full grid gap-6 p-4 max-w-6xl">
              {flow && <CaseSummary flow={flow} isOverview={true} />}
              <CaseOverviewChangeContract
                referenceId={contractId}
                coreId={coreId}
                activeChanges={activeChanges}
                refetch={refetch}
                contractActive={contractActive}
              />
              <div className="grid gap-4">
                <ContractList title="Contract process" flow={flow} />
                <InvoiceList
                  title="Invoices"
                  tasks={flow?.data?.invoiceAssessment}
                />
                <FlowList
                  title="Ongoing contract changes"
                  tasks={activeChanges}
                />
              </div>
            </div>
          </Tabs.Content>
          <Tabs.Content value="2">
            <CaseOverviewComments assessments={flow?.data?.assessments} />
          </Tabs.Content>
          <Tabs.Content value="3">
            <CaseOverViewFullTaskList flowId={flow?.flowId} />
          </Tabs.Content>
        </Tabs.Root>
      </div>
    );
  }
  return <p>No data found...</p>;
}

export function TaskList({ tasks, title = 'KYC' }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="p-4 w-1/2">
      <ChevronOpen title={title} isOpen={isOpen} setIsOpen={setIsOpen} />
      {isOpen &&
        tasks?.map((item, index) => {
          return <TaskItem key={index} task={item} />;
        })}
    </div>
  );
}

export function TaskItem({ task }) {
  const taskStatusMapper = () => {
    switch (task?.status) {
      case 'completed':
        return {
          queue: 'completed-cases-queue',
          statusColor: 'border-green-500 ',
          statusText: 'Done',
        };
      case 'active':
        return {
          queue: 'active-cases-queue',
          statusColor: 'border-yellow-500',
          statusText: 'In progress',
        };
      case 'failed':
        return {
          queue: 'failed-cases-queue',
          statusColor: 'border-red-500',
          statusText: 'Failed',
        };
      default:
        return {
          queue: 'active-cases-queue',
          statusColor: 'border-yellow-500',
          statusText: 'In progress',
        };
    }
  };
  const taskInfo = taskStatusMapper();
  return (
    <div className="p-4 border-b border-gray-200 flex items-center justify-between">
      <div className="flex items-center gap-2">
        <div
          className={`h-4 w-4 border-4 rounded-full ${taskInfo.statusColor} bg-white`}
        />
        <p className="capitalize text-xs">
          {task?.taskType.replaceAll('-', ' ')}
        </p>
        <Button intent="primary">View</Button>
      </div>
    </div>
  );
}
export function FlowList({ tasks, title = 'Credit' }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="grid gap-2">
      <ChevronOpen title={title} isOpen={isOpen} setIsOpen={setIsOpen} />
      {tasks?.length === 0 && isOpen && (
        <div className="w-full bg-gray-200 border-b border-gray-400 px-4 py-4 flex flex-row justify-between items-center">
          <p className="text-xs text-gray-700">No tasks yet</p>
        </div>
      )}
      {isOpen &&
        tasks?.map((item) => {
          return <FlowItem task={item} />;
        })}
    </div>
  );
}

export function ContractList({ title = 'Contract', flow }) {
  const [isOpen, setIsOpen] = useState(false);

  const mappedFlow = {
    ...flow,
    id: flow?.flowId,
    flowDate: flow?.updatedAt,
    taskType: flow?.referenceName,
  };

  return (
    <div className="grid gap-2">
      <ChevronOpen title={title} isOpen={isOpen} setIsOpen={setIsOpen} />
      {!flow && isOpen && (
        <div className="w-full bg-gray-200 border-b border-gray-400 px-4 py-4 flex flex-row justify-between items-center">
          <p className="text-xs text-gray-700">No data found</p>
        </div>
      )}
      {flow && isOpen && <FlowItem task={mappedFlow} />}
    </div>
  );
}

export function InvoiceList({ tasks = {}, title = 'Credit' }) {
  const [isOpen, setIsOpen] = useState(false);

  const keyList = Object?.keys(tasks);
  const mappedInvoices = keyList?.map((key) => {
    return {
      ...tasks[key],
      id: key,
    };
  });
  return (
    <div className="grid gap-2">
      <ChevronOpen title={title} isOpen={isOpen} setIsOpen={setIsOpen} />
      {mappedInvoices?.length === 0 && isOpen && (
        <div className="w-full bg-gray-200 border-b border-gray-400 px-4 py-4 flex flex-row justify-between items-center">
          <p className="text-xs text-gray-700">No invoices found</p>
        </div>
      )}
      {isOpen &&
        mappedInvoices?.map((invoice) => {
          const taskUrl = `/inbox/non-assigned-invoice-queue/register-invoice/${invoice?.id}`;

          return (
            <a href={taskUrl} className="">
              <div className="w-full  border-b border-gray-300 px-4 py-4 flex flex-row justify-between items-center">
                <div className="flex flex-row gap-4 items-center">
                  <div>
                    <p className="text-xs uppercase">Id-{invoice?.id}</p>
                    <p className="capitalize">
                      {invoice?.paymentType.replaceAll('-', ' ')}
                    </p>
                    <p className="xs"></p>
                  </div>
                </div>
                <div className="flex text-xs items-end flex-col gap-1">
                  <p>{formatDate(invoice?.paymentDate)}</p>
                  <p
                    className={clsx(
                      'px-4 font-medium rounded-full',
                      !!invoice?.assessment
                        ? 'bg-green-200 text-green-800'
                        : 'bg-yellow-200 text-yellow-800',
                    )}
                  >
                    {!!invoice?.assessment
                      ? 'Processed'
                      : 'Waiting for processing'}
                  </p>
                </div>
              </div>
            </a>
          );
        })}
    </div>
  );
}
export function FlowItem({ task }) {
  const queue = () => {
    switch (task?.status) {
      case 'completed':
        return {
          queue: 'approved-credit-queue',
          statusColor: 'bg-green-200 text-green-800',
          statusText: 'Done',
        };
      case 'active':
        return {
          queue: 'inprogress-credit-queue',
          statusColor: 'bg-yellow-500 text-yellow-800',
          statusText: 'In progress',
        };
      case 'failed':
        return {
          queue: 'failed-cases-queue',
          statusColor: 'bg-red-500 text-red-800',
          statusText: 'Failed',
        };
      default:
        return {
          queue: 'my-cases-queue',
          statusColor: 'bg-yellow-200 text-yellow-800',
          statusText: 'In progress',
        };
    }
  };

  const taskMapper = queue();
  const taskUrl = `/inbox/${taskMapper?.queue}/${task?.flowDefinitionId}/${task?.flowId}`;
  return (
    <a href={taskUrl} className="">
      <div className="w-full  border-b border-gray-300 px-4 py-4 flex flex-row justify-between items-center">
        <div className="flex flex-row gap-4 items-center">
          <div>
            {task?.referenceId ? (
              <p className="text-xs uppercase">{task?.referenceId}</p>
            ) : (
              <p className="text-xs uppercase"> {task?.flowId}</p>
            )}
            <p className="capitalize">{task?.taskType?.replaceAll('-', ' ')}</p>
            <p className="xs"></p>
          </div>
        </div>
        <div className="flex text-xs items-end flex-col gap-1">
          {!!task?.date ? (
            <p>{formatDate(task?.date)}</p>
          ) : (
            <p>{formatDate(task?.flowDate)}</p>
          )}
          <p
            className={clsx(
              'px-4 font-medium rounded-full',
              taskMapper?.statusColor,
            )}
          >
            {taskMapper?.statusText}
          </p>
        </div>
      </div>
    </a>
  );
}

export function ChevronOpen({ title, isOpen, setIsOpen }) {
  return (
    <button
      type="button"
      onClick={() => setIsOpen(!isOpen)}
      className="uppercase text-xs text-gray-500 font-medium flex flex-row items-center"
    >
      {' '}
      <span
        className={
          isOpen
            ? 'transform transition-all -rotate-180'
            : 'transition-all transform '
        }
      >
        <Chevron className="text-gray-500" />
      </span>{' '}
      {title}
    </button>
  );
}
