import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormConnect } from '../../../../hooks/useFormConnect';
import { format } from 'prettier';
import { formatMoney } from '../../../../utils/formatMoney';
import { formatDate } from '../../../../utils/formatDate';

export function ComboBox({ options, name, setValue }) {
  const data = useFormConnect(name);
  function setValues(refId, flowId) {
    setValue('referenceId', refId);
    setValue('flowId', flowId);
  }

  return (
    <Autocomplete
      options={options}
      className="w-full"
      getOptionLabel={(option: any) => option?.referenceId}
      id={name}
      onChange={(e, value) => setValues(value?.referenceId, value?.flowId)}
      renderOption={(props, option: any) => {
        const isAssetFlow =
          !!option?.data?.currentContractDetails?.Contract
            ?.LeasingAssetsContract?.Assets;
        const everyAssetHasSameCurrencyCode =
          option?.data?.currentContractDetails?.Contract?.LeasingAssetsContract?.Assets?.every(
            (asset, _, arr) => {
              return asset.CurrencyCode === arr?.[0]?.CurrencyCode;
            },
          ) || false;
        const currencyCode =
          everyAssetHasSameCurrencyCode && isAssetFlow
            ? option?.data?.currentContractDetails?.Contract
                ?.LeasingAssetsContract?.Assets?.[0]?.CurrencyCode
            : 'DKK';
        const fullNetInvoiceAmount = isAssetFlow
          ? option?.data?.currentContractDetails?.Contract?.LeasingAssetsContract?.Assets?.reduce(
              (acc, asset) => {
                let { Price, PriceInOtherCurrency } = asset;
                if (!!PriceInOtherCurrency) {
                  if (everyAssetHasSameCurrencyCode)
                    Price = PriceInOtherCurrency;
                }
                return acc + Price;
              },
              0,
            ) || 0
          : option?.data?.currentContractDetails?.CalculationResult?.Leasing
              ?.VehiclePriceLicencePlateDeliveryAndSubidies;

        return (
          /* @ts-ignore */
          <div
            {...props}
            {...data}
            key={option?.value?.id}
            className="py-2 px-4 cursor-pointer hover:bg-gray-200 flex justify-between"
          >
            <div>
              <div>
                <p className="text-xs">
                  {
                    option?.data?.currentContractDetails?.Contract?.Supplier
                      ?.Organisation?.Name
                  }
                </p>
                <p className="text-xs">
                  {
                    option?.data?.currentContractDetails?.Contract?.Buyer
                      ?.Organisation?.Name
                  }
                </p>
              </div>
              <h4>Contract - {option?.referenceId}</h4>
            </div>
            <div>
              <p className="text-xs">{formatDate(option?.createdAt)}</p>
              <p className="text-xs text-right">
                {formatMoney(fullNetInvoiceAmount, true, 2, currencyCode)}
              </p>
            </div>
          </div>
        );
      }}
      renderInput={(params) => (
        <TextField name={name} {...params} label="Select flow..." />
      )}
    />
  );
}
