import { COUNTRY } from '../../types/creditProcess/enums';
import { standarizeString } from './standarizeString';

export function standarizeDecisionHeaders(inputString: string): string {
  if (!inputString || inputString.length === 0) {
    return 'Error- No header found.';
  }

  const stringArray = inputString.split('-').map((word) => word.toLowerCase());
  const stringArrayResult: string[] = [];

  for (let index = 0; index < stringArray.length; index++) {
    const word = stringArray[index];
    switch (word) {
      case COUNTRY.NORWAY:
      case COUNTRY.SWEDEN:
      case COUNTRY.FINLAND:
      case COUNTRY.DENMARK:
      case 'b2b':
      case 'b2c':
        continue;
      default:
        stringArrayResult.push(word);
        continue;
    }
  }
  stringArrayResult[0] = standarizeString(stringArrayResult[0]);
  return stringArrayResult.join(' ');
}
