import { Button } from "../ui"
import { useQueryErrorResetBoundary } from "@tanstack/react-query"
import { ErrorBoundary } from "react-error-boundary"

export function TaskAnalyticsError({ children, t }) {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div className="rounded-lg bg-white p-4 text-black shadow-md">
          <div className="flex justify-center">
            <div className="grid gap-4">
              <div className="flex justify-center">
                <div>
                  <strong className="block text-xl font-semibold text-neutral-600">
                    {t("fetch-error")}
                  </strong>
                </div>
              </div>
              <div>
                <Button
                  variant={"secondary"}
                  onClick={() => resetErrorBoundary()}
                >
                  {t("retry")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    >
      {children}
    </ErrorBoundary>
  )
}
