import { Children } from 'react';

export function ThreeOneLayout({ children }) {
  const slots = {
    left: null,
    right: null,
  };

  Children.forEach(children, (child) => {
    switch (child.type) {
      case Left: {
        slots.left = child;
        break;
      }
      case Right: {
        slots.right = child;
        break;
      }
    }
  });

  return (
    <div className="grid grid-cols-4 h-full">
      {slots.left}
      {slots.right}
    </div>
  );
}

type SideProps = {
  children: React.ReactNode;
  className?: string;
};

function Left({ children, className }: SideProps) {
  return (
    <div
      className={
        className
          ? className
          : 'col-span-3 h-full border-r border-gray-200 bg-gray-100 p-8 flex flex-col relative gap-4 overflow-scroll'
      }
    >
      {children}
    </div>
  );
}

function Right({ children, className }: SideProps) {
  return (
    <div
      className={
        className
          ? className
          : 'col-span-1 h-full flex flex-col justify-between p-4'
      }
    >
      {children}
    </div>
  );
}

ThreeOneLayout.Left = Left;
ThreeOneLayout.Right = Right;
