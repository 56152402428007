import { TaskManualCreditDecisionAssessmentItem } from './TaskManualCreditEvaluation.Assesment';

interface TaskManualCreditEvaluationCaseWorkerAssesmentsProps {
  assessments: any;
}

function TaskManualCreditEvaluationCaseWorkerAssesments({
  assessments,
}: TaskManualCreditEvaluationCaseWorkerAssesmentsProps) {
  const assessmentsInReverseOrder = assessments.slice().reverse();

  return (
    <div className="grid gap-4">
      {assessmentsInReverseOrder.map((assessment: any, index) => (
        <TaskManualCreditDecisionAssessmentItem
          assessment={assessment}
          heading={index === 0 ? 'Siste vurdering' : 'Vurdering'}
        />
      ))}
    </div>
  );
}

export { TaskManualCreditEvaluationCaseWorkerAssesments };
