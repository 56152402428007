import { DecisionComment } from '../_shared/forms/fields/DecisionComment';

export function CaseOverviewComments({ assessments }) {
  return (
    <div className="flex flex-col gap-2  text-sm w-full px-6">
      {assessments
        ?.sort((comment) => comment?.date)
        ?.map((item) => {
          const assessment = item?.assessment;
          if (item?.userType === 'FIRST' && assessment?.comment !== '') {
            return (
              <div className="w-1/2">
                <div className="flex flex-col rounded-md">
                  <DecisionComment
                    disabled={true}
                    name=""
                    label="Final Approval"
                    placeholder={assessment?.comment}
                    reporter={assessment?.reporter}
                    date={assessment?.date}
                    userType={item?.userType}
                  ></DecisionComment>
                </div>
              </div>
            );
          }
          if (item?.userType === 'SECOND' && item?.fourEyesComment !== '') {
            return (
              <div className="w-1/2">
                <div className="flex flex-col rounded-md">
                  <DecisionComment
                    disabled={true}
                    name=""
                    label="Caseworker Assessment"
                    placeholder={item?.fourEyesComment}
                    reporter={item?.fourEyesReporter}
                    date={item?.date}
                    userType={item?.userType}
                  ></DecisionComment>
                </div>
              </div>
            );
          }
          return null;
        })}
    </div>
  );
}
