import { Ownership } from '../../types/creditscore';

type ChartCorporationProps = {
  ownsershipData: Ownership;
};

function ChartCorporation({ ownsershipData }: ChartCorporationProps) {
  const isCorporation = ownsershipData?.parentCompanies.parents.some(
    (company) => parseInt(company.ownershipInPercent, 10) >= 50,
  );

  if (!isCorporation) return null;

  return (
    <div>
      <span>Chart with corporate structure</span>
    </div>
  );
}

export { ChartCorporation };
