import { cn } from '../../utils/cn';
import { formatMoney, formatNumber } from '../../utils/formatMoney';
import { formatPercentage } from '../../utils/formatPercentage';
import { TableAffordabilityGauge } from './TableAffordability.Gauge';

type Props = {
  affordability: number;
  debtRatio: number;
  degreeOfFinancing: number;
  income: number;
};

const rows = [
  {
    key: 'affordability',
    label: 'Betjeningsevne',
    format: (value: number) => `${formatMoney(value)} kr`,
  },
  {
    key: 'debtRatio',
    label: 'Gjeldsgrad',
    format: (value: number) => formatNumber(value),
  },
  {
    key: 'degreeOfFinancing',
    label: 'Belåningsgrad',
    format: (value: number) => formatPercentage(value),
  },
  {
    key: 'income',
    label: 'Inntekt',
    format: (value: number) => formatMoney(value),
  },
];

export const TableAffordability: React.FC<Props> = (props) => {
  return (
    <div className="grid gap-2">
      <h2 className="text-content-secondary uppercase text-sm font-medium">
        Nøkkeltall
      </h2>
      <div className="flex flex-wrap items-center gap-2">
        <TableAffordabilityGauge debtRatio={props.debtRatio} />
        <div className="flex-1 gap-1 flex flex-col">
          {rows.map((row) => (
            <dl className="flex justify-between text-sm" key={row.key}>
              <dt>{row.label}</dt>
              <dd className="flex items-center gap-2">
                <span className={cn('h-3 w-3 rounded-full')}></span>
                <span>{row.format(props[row.key])}</span>
              </dd>
            </dl>
          ))}
        </div>
      </div>
    </div>
  );
};
