import { cn } from '../../utils/cn';

interface BoxData {
  label: string;
  value: string;
}

interface BoxDataListProps {
  heading: string;
  data: BoxData[];
  className?: string;
}

function BoxDataList({ heading, data, className }: BoxDataListProps) {
  return (
    <div
      className={cn(
        'bg-surface shadow-md rounded-md overflow-hidden',
        !!className ? className : '',
      )}
    >
      <header className="bg-surface-secondary border border-stroke-secondary rounded-md rounded-b-none py-3 px-6">
        <h1 className="font-medium text-content-secondary">{heading}</h1>
      </header>
      <div className="p-6 divide-y divide-stroke-secondary border border-stroke-secondary border-t-0">
        {data.map((boxData) => (
          <dl
            className="flex justify-between text-sm py-2 first:pt-0 last:pb-0"
            key={boxData.label}
          >
            <dt>{boxData.label}</dt>
            <dd>{boxData.value}</dd>
          </dl>
        ))}
      </div>
    </div>
  );
}

export { BoxDataList };
