import { useFormConnect } from '../../../../hooks/useFormConnect';
import { DecisionTextArea } from '../../Inputs/DecisionTextArea/DecisionTextArea';

export const DecisionComment = ({
  name,
  label = '',
  placeholder = '',
  disabled = false,
  reporter,
  date,
  userType,
}) => {
  return (
    //@ts-ignore
    <DecisionTextArea
      reporter={reporter}
      date={date}
      disabled={disabled}
      placeholder={placeholder}
      userType={userType}
      label={label}
    ></DecisionTextArea>
  );
};
