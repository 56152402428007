import { CreditScore } from '../../types/creditscore';
import { Box } from '../Box/Box';
import { BoxWithHeader } from '../Box/BoxWithHeader';
import { ChartAreaResult } from '../ChartAreaResult/ChartAreaResult';
import { ChartBarOperatingIncome } from '../ChartBarOperatingIncome/ChartBarOperatingIncome';
import { CreditScoreGraph } from '../CreditScoreGraph/CreditScoreGraph';
import { TableResult } from '../TableResult/TableResult';
import { TableResultKeyFigures } from '../TableResult/TableResultKeyFigures';
import { CarIcon } from '../icons/Car';
import { InternalScoreMapping } from './CreditHandling.Overview';

type CreditHandlingResultProps = {
  creditScoreData: CreditScore;
  context: any;
};

export function CreditHandlingResult({
  creditScoreData,
  context,
}: CreditHandlingResultProps) {
  const externalScore =
    context.externalScore['credit-score-b2b-denmark']?.[
      'credit-rating-b2b-denmark'
    ]?.input?.creditScore;
  const internalScore =
    context.preScore['internal-score-b2b-denmark'].input.internalCreditScore;
  const internalNumberScore = InternalScoreMapping[internalScore];

  return (
    <div className="grid gap-4">
      <div className="grid grid-cols-1fr_1fr_1fr gap-4">
        <CreditScoreGraph
          title="Credit Score (PAYDEX)"
          score={
            internalNumberScore
              ? (externalScore + internalNumberScore) / 2
              : externalScore
          }
        />
        <CreditScoreGraph title="External Credit Score" score={externalScore} />
        <CreditScoreGraph
          title="Internal Credit Score"
          score={internalNumberScore || null}
        />
      </div>
      <div>
        <BoxWithHeader icon={<CarIcon />} title="Result">
          <div className="grid grid-cols-2 gap-4">
            <ChartBarOperatingIncome
              financialStatements={
                creditScoreData.dnb.companyRiskAndCreditData.finance
                  .financialStatements.financialStatements
              }
            />
            <ChartAreaResult
              financialStatements={
                creditScoreData.dnb.companyRiskAndCreditData.finance
                  .financialStatements.financialStatements
              }
            />
          </div>
        </BoxWithHeader>
      </div>
      <div className="flex flex-row gap-2">
        <BoxWithHeader icon={<CarIcon />} title="Result">
          <div className="-mx-4 -mt-4">
            <TableResult
              data={
                creditScoreData.dnb.companyRiskAndCreditData.finance
                  .financialStatements.financialStatements
              }
            />
            <TableResultKeyFigures />
          </div>
        </BoxWithHeader>
      </div>
    </div>
  );
}
