import { cn } from '../../utils/cn';

export function Header({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLHeadElement>) {
  return (
    <h1 {...props} className={cn('font-medium text-sea-sea', className)}>
      {children}
    </h1>
  );
}

type StickyHeaderProps = React.HTMLAttributes<HTMLHeadElement> & {
  isSmall?: boolean;
};

export function StickyHeader({
  children,
  className,
  isSmall = false,
  ...props
}: StickyHeaderProps) {
  return (
    <h1
      {...props}
      className={cn(
        'mt-5 z-20 sticky left-0',
        isSmall ? 'text-sm' : 'text-base',
        className,
      )}
    >
      {children}
    </h1>
  );
}
