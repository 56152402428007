import { ChartBarFeaturedMetric, ChartLineFeaturedMetric, Stack } from '../ui';
import { formatNumber } from '../../utils/formatNumber';
import { differenceInDays } from 'date-fns';

export function MetricItem({ metric }) {
  const firstPeriodDate = metric.periods[0].date;
  const lastPeriodDate = metric.periods[metric.periods.length - 1].date;
  const differenceInDaysBetweenPeriods = differenceInDays(
    new Date(lastPeriodDate),
    new Date(firstPeriodDate),
  );

  const getLineChartDate = (period, differenceInDaysBetweenPeriods) => {
    if (differenceInDaysBetweenPeriods > 60) {
      const date = new Date(period.date);
      date.setMonth(period.month - 1);
      return date.toLocaleString('no-NB', { month: 'short' });
    }

    return `Week ${period.week.toString()}`;
  };

  const chartType = differenceInDaysBetweenPeriods > 7 ? 'line' : 'bar';

  const barChartData = metric.periods.map((period) => ({
    date: period.fullDay.charAt(0).toUpperCase(),
    value: period.count ?? 0,
  }));

  const lineChartData = metric.periods.map((period) => ({
    date: getLineChartDate(period, differenceInDaysBetweenPeriods),
    value: period.count ?? 0,
  }));

  return (
    <Stack gap="lg" className="h-full">
      <Stack gap="sm" className="h-full">
        <div className="flex h-full flex-col justify-end items-center">
          <h4 className="font-semibold text-neutral-black">{metric.name}</h4>
          <span className="text-4xl font-semibold">
            {formatNumber(metric.statistics.count)}
          </span>
        </div>
      </Stack>
      <div className="flex flex-col justify-end">
        {chartType === 'bar' ? (
          <ChartBarFeaturedMetric data={barChartData} />
        ) : (
          <ChartLineFeaturedMetric data={lineChartData} />
        )}
      </div>
    </Stack>
  );
}
