export const CustomTooltipServices = ({ active, payload, label }) => {
  const hasPayload = () => {
    return (
      payload?.[0].payload?.errorMessages?.[0] ||
      payload?.[0].payload?.additionalInformation?.[0]
    )
  }

  if (active && !!hasPayload()) {
    return (
      <div className="custom-tooltip rounded bg-white p-3 shadow-md">
        <div className="flex flex-col gap-2">
          {payload?.[0].payload?.additionalInformation ? (
            <>
              {payload?.[0].payload?.additionalInformation?.map((service) => {
                return (
                  <div
                    className="desc text-xs text-neutral-800"
                    key={service.name}
                  >
                    <strong> {service.name} </strong>
                    <br />
                    Antall feil: {service.failureCount} / {service.startedCount}{" "}
                    <br />
                    Feilprosent: {service.failureRatePct} <br />
                  </div>
                )
              })}
            </>
          ) : (
            <div className="desc text-xs text-neutral-800">
              Feilprosent: {payload?.[0].payload?.value}%
              {payload?.[0].payload?.errorMessages?.map((errorMsg) => {
                return (
                  <div
                    className="desc text-xs text-neutral-700"
                    key={errorMsg}
                  >
                    Feilmelding: {errorMsg}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    )
  }
  return null
}
