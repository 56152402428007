import { featureConfig } from '../../utils/featuresConfig';
import { formatMoney } from '../../utils/formatMoney';

function getFrequencyFromInt(frequency) {
  switch (frequency) {
    case 1:
      return 'Monthly';
    case 3:
      return 'Quarterly';
    case 6:
      return 'Bi-annually';
    case 12:
      return 'Annually';
    default:
      return '-';
  }
}

export function CaseSummaryAsset({
  assets,
  isOverview,
  referenceId,
  contract,
  calculation,
  bccToken,
  status,
  coreId,
}) {
  return (
    <div className="grid gap-4">
      <div className="text-black caseSummary h-full">
        <div className="rounded-xl shadow-lg grid border border-gray-300 h-fit-content">
          <div className="p-4 flex flex-col gap-4">
            <div className="grid gap-2">
              {assets?.Assets?.map((asset) => {
                return (
                  <div>
                    <p>{asset.Type}</p>
                    <p className="text-xs">
                      {asset.Brand} {asset.Model}
                    </p>
                    <p className="text-xs">
                      {formatMoney(asset.Price)} DKK
                      {asset.PriceInOtherCurrency
                        ? `/ €${asset.PriceInOtherCurrency}`
                        : null}
                    </p>
                  </div>
                );
              })}
            </div>
            <div>
              <data className="flex items-center justify-between text-xs font-medium">
                <dt>Overall payment ex. VAT</dt>
                <dd>
                  kr {formatMoney(calculation?.LeasingAssets?.Payment?.ExVat)}
                </dd>
              </data>
              <data className="flex items-center justify-between text-xs">
                <dt>Frequency</dt>
                <dd>
                  {typeof contract?.LeasingAssetsContract?.PaymentFrequency ===
                  'number'
                    ? getFrequencyFromInt(
                        contract?.LeasingAssetsContract?.PaymentFrequency,
                      )
                    : contract?.LeasingAssetsContract?.PaymentFrequency}
                </dd>
              </data>
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              href={`${featureConfig.cc.url}/Views/Offer/OfferDetails.aspx?id=${contract?.LeasingAssetsContract?.Id}&type=1&${!!bccToken ? `&bccToken=${bccToken}` : ''}`}
              className="inline-flex bg-transparent border border-ghost-500 hover:shadow-md transition-all px-6 py-1 rounded-lg text-xs text-center w-full items-center justify-center"
            >
              Edit application
            </a>
          </div>
        </div>
        <div className="grid gap-4">
          <div className=" p-4 rounded-xl shadow-lg flex flex-col gap-4 w-full border border-gray-300">
            <CaseSummaryAssetContact
              customer={contract?.Buyer?.Organisation}
              salesPerson={contract?.Seller?.Organisation}
              supplier={contract?.Seller?.Organisation}
              assets={assets?.Assets}
            />
            <CaseSummaryassetDetails
              contract={contract}
              leasingData={calculation}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export function CaseSummaryassetDetails({ contract, leasingData }) {
  const totalAssetPrice = leasingData?.LeasingAssets?.TotalAssetPrice?.ExVat;
  const downPayment = leasingData?.LeasingAssets?.DownPayment?.ExVat;
  const fundingNeeds = totalAssetPrice - downPayment;
  return (
    <div className="flex flex-row justify-between gap-8">
      <div className="w-full border-r border-gray-200 flex flex-col gap-4 pr-4">
        <h3 className=" text-sm font-medium">Price details</h3>
        <div className="text-xs">
          <data className="flex justify-between">
            <dt>Total asset price</dt>
            <dd>{formatMoney(totalAssetPrice)} kr</dd>
          </data>
          <data className="flex justify-between">
            <dt>Downpayment</dt>
            <dd>{formatMoney(downPayment)} kr</dd>
          </data>
          <data className="flex justify-between">
            <dt>Funding needs</dt>
            <dd>{formatMoney(fundingNeeds)} kr</dd>
          </data>
          <data className="flex justify-between">
            <dt>Co-financed cost</dt>
            <dd>
              {formatMoney(leasingData?.LeasingAssets?.FinancedFees?.ExVat)} kr
            </dd>
          </data>
          <data className="flex justify-between">
            <dt>Funding amount</dt>
            <dd>
              {formatMoney(leasingData?.LeasingAssets?.Principal?.ExVat)} kr
            </dd>
          </data>
          <data className="flex justify-between">
            <dt>Residual value</dt>
            <dd>
              {formatMoney(leasingData?.LeasingAssets?.ResidualValue?.ExVat)} kr
            </dd>
          </data>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4">
        <h3 className=" text-sm font-medium">Frame agreement details</h3>
        <div className="text-xs">
          <dl className="flex justify-between">
            <dt>Leasing period</dt>
            <dd>
              {contract?.LeasingAssetsContract?.LeasingPeriodMonths} months
            </dd>
          </dl>
          <dl className="flex justify-between">
            <dt>Interest</dt>
            <dd>{leasingData?.LeasingAssets?.NominalInterestRate}%</dd>
          </dl>
          <dl className="flex justify-between">
            <dt>Establishment costs</dt>
            <dd>
              {formatMoney(contract?.LeasingAssetsContract?.EstablishmentFee)}{' '}
              kr
            </dd>
          </dl>
          <dl className="flex justify-between">
            <dt>Insurance administration</dt>
            <dd>
              {formatMoney(
                leasingData?.LeasingAssets?.InsuranceAdministrationFee?.ExVat,
              )}{' '}
              kr
            </dd>
          </dl>
          <dl className="flex justify-between">
            <dt>Administration fee</dt>
            <dd>
              {formatMoney(leasingData?.LeasingAssets?.AdministrationFee)}{' '}
              kr/month
            </dd>
          </dl>
          <dl className="flex justify-between">
            <dt>Mortgage interest insurance</dt>
            <dd>
              {formatMoney(
                leasingData?.LeasingAssets?.Panthaverinteresseforsikring?.ExVat,
              )}{' '}
              kr
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}

export function CaseSummaryAssetContact({
  customer,
  salesPerson,
  supplier,
  assets,
}) {
  return (
    <div className="text-xs font-medium flex flex-row gap-8">
      {customer?.Name && customer?.VATnr && (
        <div>
          <h3 className="text-gray-600 uppercase">Customer</h3>
          <p>{customer?.Name}</p>
          <p>{customer?.Email}</p>
        </div>
      )}
      {salesPerson?.ContactPersonName && (
        <div>
          <h3 className="text-gray-600 uppercase">Sales Person</h3>
          <p>{salesPerson?.ContactPersonName}</p>
          <p>{salesPerson?.Email}</p>
        </div>
      )}
      {assets?.map((asset) => {
        return (
          <div>
            <h3 className="text-gray-600 uppercase">Vendor</h3>
            <p>{asset?.Supplier?.CompanyName}</p>
            <p>{asset?.Supplier?.Email}</p>
          </div>
        );
      })}
    </div>
  );
}
