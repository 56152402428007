import axios from 'axios';

function jsonToQueryString(json) {
  return Object.keys(json)
    .map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    })
    .join('&');
}

export async function getFlow(id) {
  try {
    const { data } = await axios.get(`/api/flow/flows/${id}`);
    return data;
  } catch (e) {
    throw new Error('Error');
  }
}

export async function getAllFlows(
  extraQueryParams: { [key: string]: string } = {},
) {
  let url = `/api/flow/flows?limit=5000`;
  if (Object.keys(extraQueryParams).length > 0) {
    url = `${url}&${jsonToQueryString(extraQueryParams)}`;
  }

  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    throw new Error('Error');
  }
}

export async function getFlowByReference(input) {
  try {
    const { data } = await axios.get(`/api/flow/flows?referenceId=${input}`);
    return data;
  } catch (e) {
    throw new Error('Error');
  }
}

export async function getAllHistoricalFlowsByReferenceId(input) {
  try {
    const { data } = await axios.get(`/api/flow/flows?referenceId=${input}`);
    return data;
  } catch (e) {
    throw new Error('Error');
  }
}

export async function uploadDocuments(file) {
  return await axios.post(`/api/flow/files`, file);
}

export async function overrideComplete(taskId, data) {
  return await axios.post(`/api/flow/tasks/${taskId}/trigger`, data);
}

export async function getAllTasks(flowId, taskType) {
  return await axios.get(
    `/api/flow/tasks?flowId=${flowId}${!!taskType ? `&taskType=${taskType}` : ''}`,
  );
}

export async function getAllUserTasks(flowId, taskType) {
  return await axios.get(
    `/api/flow/tasks?flowId=${flowId}&taskCategory=user-task${!!taskType ? `&taskType=${taskType}` : ''}`,
  );
}

export async function getPendingMessageTasks(flowId) {
  return await axios.get(
    `/api/flow/tasks?flowId=${flowId}&taskCategory=message-task&status=pending`,
  );
}

export async function uploadNewInvoice(
  invoice,
  base64Invoice,
  flowId,
  mimeType,
) {
  await axios.post('/api/flow/flow-definitions/register-invoice', {
    invoice: invoice,
    base64Invoice: base64Invoice,
    autoAssignedFlowId: flowId,
    mimeType,
  });
}

export async function startChangeContract(
  referenceId: string,
  type: string,
  coreId: string,
) {
  return await axios.post(`/api/flow/flow-definitions/contract-change`, {
    contractNumber: referenceId,
    subType: type,
    coreId: coreId,
  });
}

export async function checkDocuments(file) {
  return await axios.post(`/gateway/api/upload/check-type`, file);
}
