import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FinancialStatement } from '../../types/creditscore';

type ChartBarOperatingIncomeProps = {
  financialStatements: FinancialStatement[];
};

function ChartBarOperatingIncome({
  financialStatements,
}: ChartBarOperatingIncomeProps) {
  const chartData = financialStatements
    .map((financialStatement) => {
      return {
        name: financialStatement.closingPeriod.year,
        operatingIncome:
          financialStatement.incomeStatement.otherOperatingIncome +
          financialStatement.incomeStatement.grossTurnover +
          5000, //TODO: Find correct fields
      };
    })
    .reverse();

  return (
    <div className="grid gap-2">
      <h4 className="text-center text-gray-500 text-base">
        Total operating income
      </h4>
      <div
        className="w-full"
        style={{
          height: '200px',
        }}
      >
        <ResponsiveContainer>
          <BarChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip cursor={{ fill: '#EDEDED' }} />

            <Bar
              dataKey="operatingIncome"
              fill="#FFBB16"
              activeBar={<Rectangle fill="#E09F00" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export { ChartBarOperatingIncome };
