import * as React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

interface TableAffordabilityGaugeProps {
  debtRatio: number;
}

const RADIAN = Math.PI / 180;

const sectors = [
  { name: 'low', value: 25, color: '#2FA700' },
  { name: 'medium', value: 25, color: '#FFBB16' },
  { name: 'high', value: 25, color: '#E4251B' },
  { name: 'veryHigh', value: 25, color: '#C01E15' },
];

const cx = 120;
const cy = 100;
const iR = 50;
const oR = 100;

const calculateValue = (debtRatio: number): number => {
  if (debtRatio < 0) {
    throw new Error('debtRatio must be between 0 and 8');
  }

  if (debtRatio > 8) {
    debtRatio = 8;
  }

  if (debtRatio <= 4) {
    // Interpolate between 0/0 and 4/25
    return (25 / 4) * debtRatio;
  } else if (debtRatio <= 5) {
    // Interpolate between 4/25 and 5/50
    return 25 + ((50 - 25) / (5 - 4)) * (debtRatio - 4);
  } else if (debtRatio <= 6) {
    // Interpolate between 5/50 and 6/75
    return 50 + ((75 - 50) / (6 - 5)) * (debtRatio - 5);
  } else {
    // Interpolate between 6/75 and 8/100
    return 75 + ((100 - 75) / (8 - 6)) * (debtRatio - 6);
  }
};

const needle = (value, data, cx, cy, iR, oR) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 2.4;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle key={ang} cx={x0} cy={y0} r={r} fill={'#B8C4E0'} />,
    <path
      key={ang + xba}
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      fill={'#B8C4E0'}
    />,
  ];
};

function TableAffordabilityGauge({ debtRatio }: TableAffordabilityGaugeProps) {
  const value = React.useMemo(() => calculateValue(debtRatio), [debtRatio]);

  return (
    <PieChart width={240} height={120}>
      <Pie
        dataKey="value"
        startAngle={180}
        endAngle={0}
        data={sectors}
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        fill="#8884d8"
        stroke="none"
        isAnimationActive={false}
      >
        {sectors.map((entry, index) => (
          <Cell
            key={`cell-${entry.name}`}
            fill={entry.color}
            onFocus={() => null}
          />
        ))}
      </Pie>
      {needle(value, sectors, cx, cy, iR, oR)}
    </PieChart>
  );
}

export { TableAffordabilityGauge };
