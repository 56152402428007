import clsx from 'clsx';
import { formatMoney } from '../../utils/formatMoney';

type CreditBarProps = {
  availableCredit: number;
  usedCredit: number;
  totalCredit: number;
  currentApplicationCredit: number;
  currentCreditLimit: number;
};

function CreditBar({
  availableCredit,
  usedCredit,
  totalCredit,
  currentApplicationCredit,
  currentCreditLimit,
}: CreditBarProps) {
  const creditOverLimit =
    usedCredit + currentApplicationCredit - currentCreditLimit;

  const totalLength =
    Number(totalCredit) >= usedCredit + currentApplicationCredit
      ? Number(totalCredit)
      : Number(creditOverLimit > 0 ? creditOverLimit : 0) +
        Number(currentApplicationCredit);

  const percentageUsed = (usedCredit / totalLength) * 100;
  const percentageCurrentApplicationAvailable =
    ((currentApplicationCredit > availableCredit
      ? availableCredit
      : currentApplicationCredit) /
      totalLength) *
    100;

  const percentageCurrentApplicationNotAvailable =
    (Math.max(0, currentApplicationCredit - availableCredit) / totalLength) *
    100;
  const percentageCreditIncreaseOverAsk =
    100 -
    (percentageUsed +
      percentageCurrentApplicationAvailable +
      percentageCurrentApplicationNotAvailable);

  const missingCredit = availableCredit - currentApplicationCredit;

  return (
    <div className="grid gap-4 transition-all ease-in-out">
      <h3 className={clsx('text-lg text-black  font-medium')}>
        Available credit:{' '}
        <span
          className={clsx(
            'transition-all ease-in-out',
            missingCredit >= 0 ? 'text-black' : 'text-red-500',
          )}
        >
          {formatMoney(missingCredit, true)}
        </span>
      </h3>
      <div className="flex w-full relative bg-blue-200 h-8 rounded-md items-stretch">
        <div
          className={`bg-primary-800 inline-flex text-white`}
          style={{ width: `${percentageUsed}%` }}
        />
        {percentageCurrentApplicationAvailable > 0 ? (
          <div
            className={`bg-primary-500 inline-flex relative`}
            style={{
              width: `${percentageCurrentApplicationAvailable}%`,
            }}
          ></div>
        ) : null}
        {percentageCurrentApplicationNotAvailable > 0 ? (
          <div
            className={`overCreditBlue inline-flex relative`}
            style={{
              width: `${percentageCurrentApplicationNotAvailable}%`,
            }}
          ></div>
        ) : null}
        {percentageCreditIncreaseOverAsk > 0 ? (
          <div
            style={{
              width: `${percentageCreditIncreaseOverAsk}%`,
            }}
            className="overCreditRed inline-flex"
          ></div>
        ) : null}
        {/* {percentageCreditIncreaseOverAsk +
          percentageCurrentApplicationNotAvailable >
          0 && (
          <div
            style={{
              width: `${10}%`,
            }}
            className=" absolute -top-10 z-50 flex justify-end"
          >
            <div className="flex flex-col items-center">
              <span className="text-xs bg-primary-200 text-black p-1">
                Credit Limit
              </span>
              <div className="w-1 h-12 bg-primary-200" />
            </div>
          </div>
        )} */}
      </div>
      <ul className="grid gap-2">
        <li className="flex items-center gap-2">
          <div className="inline-flex rounded-full bg-primary-200 h-4 w-4" />{' '}
          Credit limit: {formatMoney(currentCreditLimit, true)}
        </li>
        <li className="flex items-center gap-2">
          <div className="inline-flex rounded-full bg-primary-800 h-4 w-4" />{' '}
          Limit usage: {formatMoney(usedCredit, true)}
        </li>
        <li className="flex items-center gap-2">
          <div className="inline-flex rounded-full bg-primary-500 h-4 w-4" />{' '}
          This application: {formatMoney(currentApplicationCredit, true)}
        </li>
      </ul>
    </div>
  );
}

export { CreditBar };
