function BarStriped({
  x: oX,
  y: oY,
  width: oWidth,
  height: oHeight,
  fill,
  value,
  equity,
  ...props
}: any) {
  const x = oX;
  const y = oHeight < 0 ? oY + oHeight : oY;
  const width = oWidth;
  const height = Math.abs(oHeight);

  if (equity > 0) {
    return (
      <rect fill={fill} x={x} y={y} width={width} height={height} {...props} />
    );
  }

  return (
    <rect
      fill={fill}
      mask="url(#mask-stripe)"
      x={x}
      y={y}
      width={width}
      height={height}
    />
  );
}

export { BarStriped };
