import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts"

export function ChartBarFeaturedMetric({ data }) {
  const widths = {
    1: 10,
    2: 20,
    3: 30,
    4: 40,
    5: 50,
    6: 60,
    7: 70,
    8: 80,
  }

  const highestNumberOfDigitsInDataValue = data.reduce((acc, item) => {
    const numberOfDigits = item.value.toString().length
    if (numberOfDigits > acc) {
      return numberOfDigits
    }
    return acc
  }, 0)

  const max = widths[highestNumberOfDigitsInDataValue] ?? 100
  const width = max > 20 ? max + 10 : 30

  return (
    <div className="relative w-full pb-[180px]">
      <div className="absolute bottom-0 left-0 right-0 top-0">
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <XAxis dataKey="date" axisLine={false} tickLine={false} />
            <YAxis
              axisLine={true}
              tickLine={false}
              tick={{ fill: "#7A7C7A" }}
              stroke="#DAD9D8"
              domain={["dataMin", "dataMax"]}
              width={width}
              dataKey={"value"}
            />
            <CartesianGrid stroke="#DAD9D8" vertical={false} />
            <Bar dataKey="value" fill="#452268" barSize={10} radius={9999} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
