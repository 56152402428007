import * as React from 'react';
import { cn } from '../../../utils/cn';
import {
  TaskLayout,
  TaskLayoutDecision,
  TaskLayoutMain,
} from '../TaskLayout/TaskLayout';
import * as Tabs from '@radix-ui/react-tabs';
import { TaskManualCreditEvaluationOverview } from './TaskManualCreditEvaluation.Overview';
import {
  TaskManualCreditDecisionAssessmentList,
  TaskManualCreditEvaluationAssesment,
} from './TaskManualCreditEvaluation.Assesment';
import { Applicant } from '../../Applicant/Applicant';
import { Applicant as ApplicationApplicant } from '../../../types/ManualDecisionContext';
import { formatMoney } from '../../../utils/formatMoney';
import { formatPercentage } from '../../../utils/formatPercentage';
import SBL from '../../SBL/';
import CreditCheck from '../../CreditCheck';
import { TaskManualCreditEvaluationCaseWorkerAssesments } from './TaskManualCreditEvaluation.CaseWorkerAssesments';
import { useFlowContext } from '../../../hooks/useFlowContext';

const tabs = [
  { label: 'Oversikt', value: '1' },
  { label: 'SBL', value: '2' },
  { label: 'Kredittsjekk', value: '3' },
  { label: 'Scoring', value: '4' },
  { label: 'Vurderinger', value: '5' },
  { label: 'Filer', value: '6' },
];

function TaskManualCreditEvaluation() {
  const props = useFlowContext();
  const { task, user, flow, schema, t } = props;
  const [active, setActive] = React.useState('1');
  const context = task.context;
  const username = user.profile.username;
  const reporter = {
    id: user?.profile?.username,
    name: user?.profile?.name,
  };
  const isLastAssesser =
    flow.data.assessments?.[flow.data.assessments?.length - 1].user
      ?.username === username;

  const isFourEyesTask = task.taskType === 'four-eyes-manual-handling-credit';
  const isLockedByFourEyesConflict = isFourEyesTask && isLastAssesser;
  const applicant = context?.applicants?.find(
    (applicant) => applicant.type === 'mainApplicant',
  ) as ApplicationApplicant;

  const coApplicant = context?.applicants?.find(
    (applicant) => applicant.type === 'coApplicant',
  );

  const { financingObject, calculation } = context;
  const lastAssessment = context.assessments[context.assessments.length - 1];
  const lastAssesserIsFirst =
    context.assessments[context.assessments.length - 1]?.userType === 'FIRST';

  const checkIfCanApprove = lastAssesserIsFirst
    ? lastAssessment?.assessment?.reporter.id === user.profile.username
    : lastAssessment?.fourEyesReporter?.id === user.profile.username;

  if (!schema) {
    return null;
  }
  return (
    <TaskLayout>
      <TaskLayoutMain>
        <Tabs.Root defaultValue="1" className="">
          <div className="bg-surface">
            <Tabs.List className="flex flex-row gap-4 items-center border-b border-stroke-secondary px-4">
              {tabs.map((tab) => {
                return (
                  <Tabs.Trigger
                    onClick={() => setActive(tab.value)}
                    value={tab.value}
                    className={cn(
                      active === tab.value
                        ? 'tabs text-content-state-action'
                        : 'inactiveTabs',
                      ' text-sm p-4',
                    )}
                    key={tab.value}
                  >
                    {tab.label}
                    {tab.label === 'Vurderinger' &&
                    context?.assessments?.length > 0 ? (
                      <span className="text-xs bg-surface-tertiary text-content-state-action-dark rounded-full w-6 h-6 inline-flex items-center justify-center ml-2">
                        {context?.assessments.length}
                      </span>
                    ) : null}
                  </Tabs.Trigger>
                );
              })}
            </Tabs.List>
          </div>
          <div className="bg-gray-100">
            <Tabs.Content value="1">
              <div className="p-6 ">
                <TaskManualCreditEvaluationOverview context={context} />
              </div>
            </Tabs.Content>
            <Tabs.Content value="2">
              <div className="p-6">
                <SBL applicants={context.applicants} t={t} />
              </div>
            </Tabs.Content>
            <Tabs.Content value="3">
              <div className="p-6">
                <CreditCheck />
              </div>
            </Tabs.Content>
            <Tabs.Content value="5">
              <div className="p-6 ">
                <TaskManualCreditEvaluationCaseWorkerAssesments
                  assessments={context.assessments}
                />
              </div>
            </Tabs.Content>
          </div>
        </Tabs.Root>
      </TaskLayoutMain>

      <TaskLayoutDecision>
        <div className="grid gap-8">
          <div className="grid gap-2">
            <h2 className="text-content-secondary font-medium text-sm uppercase">
              {t('applicants')}
            </h2>
            {applicant ? <Applicant applicant={applicant} /> : null}
            {coApplicant ? <Applicant applicant={coApplicant} /> : null}
          </div>
          <div className="grid gap-2">
            <h2 className="text-content-secondary font-medium uppercase">
              {t('loan-details')}
            </h2>
            <div className="divide-y divide-stroke-secondary">
              <dl className="flex justify-between py-2">
                <dt>{`${financingObject.brand} ${financingObject.model} ${financingObject.modelYear}`}</dt>
                <dd>{`${formatMoney(financingObject.purchasePrice, true)}`}</dd>
              </dl>
              <dl className="flex justify-between py-2">
                <dt>{t('equity')}</dt>
                <dd>{`${formatMoney(financingObject.advance, true)}`}</dd>
              </dl>
              <dl className="flex justify-between py-2">
                <dt>{t('nominal-interest-rate')}</dt>
                <dd>{`${formatPercentage(calculation.instalmentSeries[0].nominalInterest)}`}</dd>
              </dl>
              <dl className="flex justify-between py-2">
                <dt>{t('repayment-period')}</dt>
                <dd>{`${formatMoney(calculation.instalmentSeries[0].length)} ${t('month')}`}</dd>
              </dl>
              <dl className="flex justify-between py-2">
                <dt>{t('financing-amount')}</dt>
                <dd>{`${formatMoney(calculation.financingAmount, true)}`}</dd>
              </dl>
            </div>
          </div>
          {context.assessments.length > 0 && (
            <TaskManualCreditDecisionAssessmentList
              assessments={context.assessments}
            />
          )}
          <TaskManualCreditEvaluationAssesment
            assessments={schema.properties.assessment?.enum ?? []}
            assessment={task.data.assessment}
            comment={task.data.comment}
            canComplete={props.canComplete}
            canSave={props.canSave}
            onComplete={props.onComplete}
            permissions={task.permissions}
            taskId={task.taskId}
            isFourEyesTask={isFourEyesTask}
            reporter={reporter}
            onSave={props.onSave}
            close={props.close}
            flowId={task.flowId}
            isLockedByFourEyesConflict={checkIfCanApprove}
          />
        </div>
      </TaskLayoutDecision>
    </TaskLayout>
  );
}

export { TaskManualCreditEvaluation };
