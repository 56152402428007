import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { RadioButtonList } from '../_shared/Inputs/RadioButtonList/RadioButtonList';
import { ThreeOneLayout } from '../Layout/ThreeOneLayout';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { Comment } from '../_shared/forms/fields/Comment';
import { featureConfig } from '../../utils/featuresConfig';

export function AdjustInCoreTaskForm({ flowProps }) {
  const method = useForm({
    resolver: zodResolver(
      z.object({
        assessment: z.object({
          decision: z.string({
            invalid_type_error: 'Please select an option',
          }),
          comment: z.string(),
        }),
      }),
    ),
    defaultValues: {
      assessment: {
        comment: '',
        decision: null,
      },
    },
  });

  const coreId = flowProps?.task?.context?.coreId;

  const { handleSubmit } = method;

  function onSubmit(data) {
    flowProps.complete(data);
  }

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <ThreeOneLayout>
          <ThreeOneLayout.Left>
            <div className="flex items-center flex-col h-full justify-center">
              <Box className="flex items-center flex-col justify-center gap-8">
                <p>
                  To proceed with the contract adjustment, you will be sent to
                  Core.
                </p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${featureConfig.core.url}/core-bc-poc/web/?page=70725576&company=${featureConfig.core.instance}&dc=0&filter='No.' IS '${coreId}'`}
                  className="inline-flex bg-primary-500 text-primary-50 border border-ghost-500 hover:shadow-md hover:bg-primary-600 transition-all px-6 py-1 rounded-lg text-xs text-center w-1/3 items-center justify-center"
                >
                  Go to Core
                </a>
              </Box>
            </div>
          </ThreeOneLayout.Left>
          <ThreeOneLayout.Right>
            <div className="grid gap-4">
              <h3>Contract ID: {flowProps?.task?.context?.contractId}</h3>
            </div>
            <div className="flex w-full flex-col gap-4">
              <label
                className=" font-medium text-black"
                id="assessment.decision"
                aria-label="decision"
              >
                Decision
              </label>
              <RadioButtonList
                id="assessment.decision"
                options={[
                  { label: 'Approve', value: 'approved' },
                  { label: 'Reject', value: 'rejected' },
                ]}
              />
              <Comment
                name="assessment.comment"
                label="Internal Comment"
                placeholder="(Optional)"
              />
              <div className="w-full flex gap-2">
                <Button type="submit">Complete</Button>
              </div>
            </div>
          </ThreeOneLayout.Right>
        </ThreeOneLayout>
      </form>
    </FormProvider>
  );
}
