import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../Button/Button';
import { TaskProps } from '../../../types/taskProps';
import * as React from 'react';
import { formatDate } from '../../../utils/formatDate';
import { cn } from '../../../utils/cn';
import { useMutation } from '@tanstack/react-query';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { RadioButtonList } from '../../_shared/Inputs/RadioButtonList/RadioButtonList';

interface TaskManualCreditEvaluationAssesmentProps {
  assessments: string[];
  assessment: string;
  comment: string;
  reporter: { name: string; id: string };
  onComplete: (taskId, formData, callback, errorCallback) => void;
  permissions: Record<string, boolean>;
  canComplete: boolean;
  canSave: boolean;
  onSave: TaskProps['onSave'];
  taskId: string;
  close: TaskProps['close'];
  flowId: string;
  isFourEyesTask: boolean;
  isLockedByFourEyesConflict: boolean;
}

function assesmentEnumToLabel(enumValue: string) {
  switch (enumValue) {
    case 'request-additional-information':
      return 'Etterspør dokumentasjon';
    case 'accepted':
      return 'Godkjenn';
    case 'escalate-to-committee':
      return 'Eskaler til komité';
    case 'request-reevaluation':
      return 'Be om ny vurdering';
    case 'rejected':
      return 'Avvis';
    case 'edit-application':
      return 'Endre søknad';
    default:
      return enumValue;
  }
}

function assessmentTypeToLabel(enumValue: string) {
  switch (enumValue) {
    case 'creditCommittee':
      return 'Kredittkomité';
    case 'manualVerification':
      return 'Saksbehandler';
    case 'fourEyes':
      return 'Fire øyne';
    default:
      return enumValue;
  }
}

const assessmentOrder = [
  'accepted',
  'rejected',
  'request-additional-information',
  'edit-application',
];

async function wait(milliseconds: number = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

function TaskManualCreditEvaluationAssesment({
  assessments,
  assessment,
  comment,
  onComplete,
  isFourEyesTask,
  permissions,
  canComplete,
  canSave,
  onSave,
  reporter,
  taskId,
  close,
  flowId,
  isLockedByFourEyesConflict,
}: TaskManualCreditEvaluationAssesmentProps) {
  const methods = useForm();
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isSubmitting },
  } = methods;

  const complete = useMutation({
    mutationKey: ['manual-credit'],
    mutationFn: async (): Promise<void> =>
      new Promise((resolve) => {
        onComplete(
          taskId,
          methods.getValues(),
          () => {
            resolve();
            close();
          },
          console.error,
        );
      }),
  });
  const save = useMutation({
    mutationFn: async (): Promise<void> =>
      new Promise((resolve) => {
        onSave(taskId, methods.getValues(), resolve, console.error);
      }),
  });

  function handleSave() {
    if (!canSave) return;
    save.mutate();
  }

  function handleComplete() {
    if (!canComplete) return;
    complete.mutate();
  }

  function onSubmit(data) {
    let assessment;
    const firstAssessment = {
      decision: data.assessment,
      reporter,
      task: taskId,
      date: new Date().toISOString(),
    };

    const fourEyesAssessment = {
      fourEyesDecision: data.assessment,
      fourEyesReporter: reporter,
      date: new Date().toISOString(),
      task: taskId,
    };

    if (isFourEyesTask) {
      assessment = { ...fourEyesAssessment };
      onComplete(
        taskId,
        { comment: data.comment, ...fourEyesAssessment },
        () => {
          close();
        },
        console.error,
      );
      return;
    }
    assessment = firstAssessment;
    onComplete(
      taskId,
      { comment: data.comment, assessment },
      () => {
        close();
      },
      console.error,
    );
  }

  const isLoading = complete.isLoading || save.isLoading;
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <div className="flex flex-col gap-1">
            <h2 className="text-content-secondary font-medium">Vurdering</h2>
          </div>
          <RadioButtonList
            id="assessment"
            options={[
              { label: 'Approve', value: 'Approved' },
              { label: 'Decline', value: 'Declined' },
            ]}
          />
          <div className="grid gap-2">
            <label htmlFor="comment" className="text-content-secondary">
              Kommentar
            </label>
            <textarea
              className="border border-stroke p-2"
              id="comment"
              {...register('comment')}
              defaultValue={comment}
              placeholder={'(Optional)'}
              rows={4}
            />
          </div>
          {isLockedByFourEyesConflict && (
            <div className="bg-surface-state-danger-light border border-surface-state-danger p-2">
              Du kan ikke fullføre denne oppgaven fordi du er den opprinnelige
              saksbehandleren. En annen saksbehandler må fullføre oppgaven.
            </div>
          )}
          <div className="flex gap-4">
            <Button
              type="submit"
              disabled={isLoading || !canComplete || isLockedByFourEyesConflict}
              isLoading={isLoading}
            >
              Fullfør
            </Button>

            <Button
              isLoading={isLoading}
              intent="secondary"
              type="button"
              onClick={handleSave}
              disabled={
                isLoading || !canSave || !isDirty || isLockedByFourEyesConflict
              }
            >
              Save for later
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

function TaskManualCreditDecisionAssessmentList({ assessments }) {
  const lastAssessment = assessments[assessments.length - 1];
  const isFirst = lastAssessment.userType === 'FIRST';
  const { t } = useFlowContext();
  if (isFirst) {
    return (
      <div className="flex flex-col gap-2">
        {assessments.length ? (
          <>
            <h2 className="text-content-secondary font-medium uppercase">
              {t('assessments')}
            </h2>
            <TaskManualCreditDecisionAssessmentItem
              key={
                lastAssessment.assessment + lastAssessment?.timestamp ?? 'rs'
              }
              assessment={lastAssessment}
            />
          </>
        ) : null}
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-2">
      {assessments.length ? (
        <>
          <h2 className="text-content-secondary font-medium uppercase">
            {t('assessments')}
          </h2>
          <TaskManualCreditDecisionAssessmentItemSecond
            key={lastAssessment?.date}
            assessment={lastAssessment}
          />
        </>
      ) : null}
    </div>
  );
}

function TaskManualCreditDecisionAssessmentItem({
  assessment,
  heading = 'Siste vurdering',
}) {
  const { t } = useFlowContext();
  return (
    <li
      className={cn(
        'w-full border-2 border-stroke-primary p-2 flex flex-col gap-2',
      )}
      key={assessment.assessment.date}
    >
      <div className="flex justify-between">
        <h2 className="text-content-secondary uppercase">{heading}</h2>
        {/*         <p>{formatDate(assessment.timestamp, 'dd.MM.yyyy HH:mm')}</p>
         */}{' '}
      </div>
      <div className="flex gap-1">
        <h3 className="font-bold">{t('caseWorker')}:</h3>
        <p> {assessment?.assessment?.reporter?.name}</p>
      </div>
      <div className="flex gap-1">
        <h3 className="font-bold">{t('assessment')}:</h3>
        <p> {assesmentEnumToLabel(assessment.assessment?.decision)}</p>
      </div>
      {assessment.comment && (
        <div className="">
          <h3 className="font-bold">{t('comment')}</h3>
          <p>{assessment.comment}</p>
        </div>
      )}
      <p className="italic text-right">
        {assessmentTypeToLabel(assessment?.userType)}
      </p>
    </li>
  );
}

function TaskManualCreditDecisionAssessmentItemSecond({
  assessment,
  heading = 'Siste vurdering',
}) {
  const { t } = useFlowContext();
  return (
    <li
      className={cn(
        'w-full border-2 border-stroke-primary p-2 flex flex-col gap-2',
      )}
      key={assessment.date}
    >
      <div className="flex justify-between">
        <h2 className="text-content-secondary uppercase">{heading}</h2>
        {/*         <p>{formatDate(assessment.timestamp, 'dd.MM.yyyy HH:mm')}</p>
         */}{' '}
      </div>
      <div className="flex gap-1">
        <h3 className="font-bold">{t('caseWorker')}:</h3>
        <p> {assessment?.fourEyesReporter?.name}</p>
      </div>
      <div className="flex gap-1">
        <h3 className="font-bold">{t('assessment')}:</h3>
        <p> {assesmentEnumToLabel(assessment.fourEyesDecision)}</p>
      </div>
      {assessment.comment && (
        <div className="">
          <h3 className="font-bold">{t('comment')}</h3>
          <p>{assessment.comment}</p>
        </div>
      )}
      <p className="italic text-right">
        {assessmentTypeToLabel(assessment?.type)}
      </p>
    </li>
  );
}

export {
  TaskManualCreditEvaluationAssesment,
  TaskManualCreditDecisionAssessmentItem,
  TaskManualCreditDecisionAssessmentList,
  TaskManualCreditDecisionAssessmentItemSecond,
};
