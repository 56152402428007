import { Fragment } from 'react';

type CreditDecisionProps = {
  context: any;
};

function CreditDecision({ context }: CreditDecisionProps) {
  if (!context) return null;
  const internalScore = context.preScore?.['internal-score-b2b-denmark'];
  const denyList = context.preScore?.['deny-list-b2b-denmark'];
  const vip = context.preScore?.['vip-b2b-denmark'];
  const externalScore =
    context.externalScore?.['credit-score-b2b-denmark']?.[
      'credit-rating-b2b-denmark'
    ];
  const creditAmountScore =
    context.creditAmountScore?.['credit-amount-score-b2b-denmark-decision']?.[
      'credit-amount-score-b2b-denmark-decision'
    ];

  const scores = internalScore?.output
    .concat(externalScore.output)
    .concat(creditAmountScore.output);

  const colors = {
    green: '#34D399',
    red: '#EF4444',
    yellow: '#FBBF24',
  };

  return (
    <div>
      <div>
        <div>
          <ul className="divide-y w-full">
            <li className="py-2 grid grid-cols-2">
              <h3 className="text-black font-medium">Credit decision</h3>
              <h3 className="text-black font-medium">Reason</h3>
            </li>
            {scores
              ?.filter((score) => score.decision !== 'green')
              .map((score) => (
                <Fragment key={score.reason}>
                  <li className="py-2 grid grid-cols-2">
                    <span className="flex items-center gap-1">
                      {colors[score.decision] ? (
                        <span
                          className="rounded-full h-4 w-4 inline-flex"
                          style={{ backgroundColor: colors[score.decision] }}
                        />
                      ) : null}

                      {score.decision}
                    </span>
                    <span>{score.reason}</span>
                  </li>
                </Fragment>
              ))}

            <li className="py-2 flex gap-2 items-center">
              <span className="text-gray-400">Based on:</span>
              {internalScore?.input?.internalCreditScore && (
                <span className="inline-flex rounded-full bg-gray-300 py-1 px-2 text-xs">
                  Internal score {internalScore?.input.internalCreditScore}
                </span>
              )}
              <span className="inline-flex rounded-full bg-gray-300 py-1 px-2 text-xs">
                External score {externalScore?.input?.creditScore}
              </span>
              <span className="inline-flex rounded-full bg-gray-300 py-1 px-2 text-xs">
                {internalScore?.input.isPep ? 'Is pep' : 'Not pep'}
              </span>
              <span className="inline-flex rounded-full bg-gray-300 py-1 px-2 text-xs">
                {internalScore?.input.isSanctioned
                  ? 'Sanctioned'
                  : 'Not sanctioned'}
              </span>
              {vip?.output?.map((vip) => (
                <Fragment key={vip.reason}>
                  <span className="inline-flex rounded-full bg-gray-300 py-1 px-2 text-xs">
                    {vip.isVip ? 'Is Denied' : 'Not Denied'}
                  </span>
                </Fragment>
              ))}
              {denyList?.output?.map((vip) => (
                <Fragment key={vip.reason}>
                  <span className="inline-flex rounded-full bg-gray-300 py-1 px-2 text-xs">
                    {vip.isVip ? 'Is VIP' : 'Not VIP'}
                  </span>
                </Fragment>
              ))}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export { CreditDecision };
