import { featureConfig } from '../../utils/featuresConfig';
import { formatMoney } from '../../utils/formatMoney';
import { CarIcon } from '../icons/Car';

export function CaseSummaryCar({ car, leasingData, contractId, bccToken }) {
  return (
    <div className="rounded-xl shadow-lg grid border border-gray-300">
      <img
        alt="car"
        width={'100%'}
        height={'100%'}
        className="borderTopRadius"
        src={
          car?.ImageUrl
            ? `${featureConfig.cc.url}${car?.ImageUrl}`
            : `${process.env.PUBLIC_URL}/assets.png`
        }
      ></img>
      <div className="p-4 flex flex-col gap-2 font-medium text-gray-700">
        <div>
          <p className=" text-xs text-gray-500 uppercase">{car?.ModelYear}</p>
          <p>
            {car?.BrandName} {car?.ModelName}
          </p>
        </div>
        <div className="flex gap-4">
          <div className="flex gap-2 items-center text-xs font-normal">
            <CarIcon />
            <p>{car?.VariantName}</p>
          </div>
        </div>
        <div>
          <data className="text-xs flex flex-row justify-between items-center">
            <dt>Total leasing payment</dt>
            <dd>
              kr{' '}
              {formatMoney(Number(leasingData?.TotalMonthlyPayment).toFixed(0))}
            </dd>
          </data>
          <data className="text-xs flex flex-row justify-between items-center">
            <dt>TCO Monthly</dt>
            <dd>
              kr {formatMoney(Number(leasingData?.TCOMonthly).toFixed(0))}
            </dd>
          </data>
        </div>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${featureConfig.cc.url}/Views/Orders/CreateNewOrder.aspx?id=${contractId}&contractId=${contractId}${!!bccToken ? `&bccToken=${bccToken}` : ''}`}
            className="inline-flex bg-transparent border border-ghost-500 hover:shadow-md transition-all px-6 py-1 rounded-lg text-xs text-center w-full items-center justify-center"
          >
            See/Edit application
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${featureConfig.cc.url}/Views/Contracts/Contract.aspx?id=${contractId}${!!bccToken ? `&bccToken=${bccToken}` : ''}`}
            className="inline-flex bg-transparent border border-ghost-500 hover:shadow-md transition-all px-6 py-1 rounded-lg text-xs text-center w-full items-center justify-center"
          >
            See/Edit contract details
          </a>
        </div>
      </div>
    </div>
  );
}
