import { CreditScore } from '../../types/creditscore';
import { BoxWithHeader } from '../Box/BoxWithHeader';
import { ChartBarBalance } from '../ChartBarBalance/ChartBarBalance';
import { TableBalance } from '../TableBalance/TableBalance';
import { CarIcon } from '../icons/Car';

type CreditHandlingBalanceProps = {
  creditScoreData: CreditScore;
};

export function CreditHandlingBalance({
  creditScoreData,
}: CreditHandlingBalanceProps) {
  return (
    <div>
      <BoxWithHeader icon={<CarIcon />} title="Balance">
        <div className="-mx-4 -mt-4">
          <ChartBarBalance
            financialStatements={
              creditScoreData.dnb.companyRiskAndCreditData.finance
                .financialStatements.financialStatements
            }
          />
          <TableBalance
            data={
              creditScoreData.dnb.companyRiskAndCreditData.finance
                .financialStatements.financialStatements
            }
          />
        </div>
      </BoxWithHeader>
    </div>
  );
}
