import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Comment } from '../_shared/forms/fields/Comment';
import { Button } from '../Button/Button';
import { ThreeOneLayout } from '../Layout/ThreeOneLayout';
import { RadioButtonList } from '../_shared/Inputs/RadioButtonList/RadioButtonList';
import { BoxWithHeader } from '../Box/BoxWithHeader';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { useMutation } from '@tanstack/react-query';
import { SuitcaseIcon } from '../icons/Suitcase';
import { uploadDocuments } from '../../api/api';

const MAX_SIZE = 20 * 1024 * 1024;
export function ManuallyHandleSupplierTaskForm({ flowProps }) {
  const { task } = flowProps;
  const reporter = {
    id: flowProps?.user?.profile?.username,
    name: flowProps?.user?.profile?.name,
  };
  const method = useForm({
    resolver: zodResolver(
      z.object({
        docs: z.array(z.any()),
        assessment: z.object({
          decision: z.string({}),
          comment: z.string().optional(),
          reporter: z.object({
            id: z.string(),
            name: z.string(),
          }),
          date: z.string(),
          task: z.string(),
        }),
      }),
    ),
    defaultValues: {
      assessment: {
        decision: null,
        comment: '',
        reporter: reporter,
        date: new Date().toISOString(),
        task: task?.taskType,
      },
      docs: [],
    },
  });
  const { handleSubmit, control, watch } = method;
  const {
    mutate,
    data: mutatedData,
    isLoading,
  } = useMutation({
    //@ts-ignore
    mutationFn: (data: any) => uploadDocuments(data),
    onMutate: () => setLoading(true),
    onSuccess: (data) => {
      flowProps.complete({
        //@ts-ignore
        docs: data?.data?.uploadedFiles,
        assessment: {
          decision: watch('assessment.decision'),
          comment: watch('assessment.comment'),
          reporter: reporter,
          date: new Date().toISOString(),
          task: task?.taskType,
        },
      });
    },
  });
  const onSubmit = (data) => {
    if (data?.docs?.length > 0) {
      const form = new FormData();
      data.docs.forEach((doc) => {
        form.append('files', doc);
      });
      mutate(form);
      return;
    }
    flowProps.complete({
      assessment: {
        decision: watch('assessment.decision'),
        comment: watch('assessment.comment'),
        reporter: reporter,
        date: new Date().toISOString(),
        task: task?.taskType,
      },
    });
  };

  const { fields, append } = useFieldArray({
    control,
    //@ts-ignore
    name: 'docs',
  } as any);

  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);
  function handleFileUpload(file) {
    const reader = new FileReader();
    setFiles(file);
    append(file);
    reader.readAsBinaryString(file);
  }

  const { getRootProps, getInputProps, fileRejections, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFileUpload(
        //@ts-ignore
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    accept: {
      'application/pdf': [],
      'image/*': [],
    },
    noClick: false,
    noKeyboard: false,
    multiple: false,
    maxSize: MAX_SIZE,
  });

  const { ref, ...rootProps } = getRootProps();
  const decision = watch('assessment.decision');

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <ThreeOneLayout>
          <ThreeOneLayout.Left>
            <BoxWithHeader
              icon={<SuitcaseIcon />}
              title="Info from supplier"
              className="grid gap-6"
            >
              <p>No new info from supplier...</p>
            </BoxWithHeader>
            <div className="flex items-center justify-center h-full w-full">
              {fields?.length > 0 ? (
                <div className="h-full w-full">
                  {/* @ts-ignore */}
                  <embed className="h-full w-full" src={fields?.[0]?.preview} />
                </div>
              ) : (
                <div
                  {...rootProps}
                  className="p-12 hover:shadow-md rounded-xl border-gray-400 border-dashed hover:border-gray-600 cursor-pointer border-2"
                  onKeyDown={(event) => {
                    if (event.code === 'Enter' || event.code === 'Space') {
                      open();
                    }
                  }}
                >
                  <input id={`${document}`} {...getInputProps()} type="file" />
                  <p>Drag or click to upload file</p>
                </div>
              )}
            </div>
          </ThreeOneLayout.Left>
          <ThreeOneLayout.Right>
            <div className="flex flex-col gap-4"></div>
            <div className="flex flex-col gap-4">
              <label
                className=" font-medium text-black"
                aria-label="manualCreditProcessStatus"
                id="assessment.decision"
              >
                Decision
              </label>
              <RadioButtonList
                id="assessment.decision"
                options={[
                  { label: 'Approved, no changes needed', value: 'Approved' },
                  {
                    label: 'Approved, changes completed',
                    value: 'ApprovedWithChanges',
                  },
                  { label: 'Declined, will make changes', value: 'Declined' },
                ]}
              />
              <Comment
                name="comment"
                label="Internal comment"
                placeholder="Enter a comment"
                disabled={task.status === 'completed'}
              />
              {decision === 'Approved' || decision === null ? (
                <Button type="submit">Approve</Button>
              ) : (
                <Button onClick={() => flowProps.close()} type="button">
                  Make changes
                </Button>
              )}{' '}
            </div>
          </ThreeOneLayout.Right>
        </ThreeOneLayout>
      </form>
    </FormProvider>
  );
}
