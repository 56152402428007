import { useEffect, useState } from 'react';
import { Button } from '../Button/Button';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { useMutation } from '@tanstack/react-query';
import { startChangeContract } from '../../api/api';
import { featureConfig } from '../../utils/featuresConfig';

type CaseOverviewChangeContractProps = {
  referenceId: string;
  coreId: string;
  contractActive: boolean;
  activeChanges: any[];
  refetch: () => void;
};

type ChangeType = 'extension' | 'early-termination' | 'due-date' | 'mileage';

type ChangeContractMutation = {
  referenceId: string;
  changeType: ChangeType;
  coreId: string;
};

function CaseOverviewChangeContract({
  referenceId,
  coreId,
  activeChanges,
  contractActive,
  refetch,
}: CaseOverviewChangeContractProps) {
  const [open, setOpen] = useState(false);
  const [changeType, setChangeType] = useState<string | undefined>();

  const { mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (data: ChangeContractMutation) =>
      startChangeContract(data.referenceId, data.changeType, data.coreId),
    onSuccess: () => {
      setChangeType(undefined);
      refetch();
    },
  });

  const changeTypes = [
    {
      id: 'extension',
      label: 'Extention',
    },
    {
      id: 'early-termination',
      label: 'Early termination',
    },
    {
      id: 'due-date',
      label: 'Due date',
    },
    {
      id: 'mileage',
      label: 'Mileage',
    },
  ];

  const handleStartChange = () => {
    mutate({ referenceId, changeType: changeType as ChangeType, coreId });
  };

  if (!referenceId || !coreId || !featureConfig.core) return null;

  return (
    <div className="rounded-xl shadow-lg flex flex-row justify-between w-full border border-gray-300 p-4 items-center">
      <Button
        intent={'ghost'}
        isFull={false}
        onClick={() => {
          window.open(
            `${featureConfig.core.url}/core-bc-poc/web/?page=70725576&company=${featureConfig.core.instance}&dc=0&filter='No.' IS '${coreId}'`,
          );
        }}
      >
        Open in Core
      </Button>
      {(contractActive && (
        <div className="flex flex-row justify-between items-center ml-4 w-3/4">
          <div>
            {open ? (
              <Button intent="ghost" onClick={() => setOpen(!open)}>
                Abort Change
              </Button>
            ) : (
              <Button intent="ghost" onClick={() => setOpen(!open)}>
                Change Contract
              </Button>
            )}
          </div>
          {open ? (
            <div>
              <div className="pt-4 grid gap-4">
                <div className="grid gap-2">
                  <span className="font-medium text-sm">Type of change</span>

                  <RadioGroup.Root
                    className="flex flex-col gap-2"
                    onValueChange={(value) => setChangeType(value)}
                    defaultValue={changeType}
                  >
                    {changeTypes.map((changeType) => {
                      const disabled = activeChanges
                        ?.map((change) => change.type)
                        .includes(changeType.id);
                      const changeFlow = activeChanges?.find(
                        (change) => change.type === changeType.id,
                      );
                      return (
                        <div
                          className="flex items-center gap-2"
                          key={changeType.id}
                        >
                          <RadioGroup.Item
                            value={changeType.id}
                            id={changeType.id}
                            disabled={disabled}
                            className={
                              'w-4 h-4 rounded-full shadow-md hover:bg-blue-100 focus:shadow-lg outline-none cursor-pointer border border-gray-300 ' +
                              (disabled
                                ? 'cursor-not-allowed bg-gray-200'
                                : 'bg-white')
                            }
                          >
                            <RadioGroup.Indicator className="flex items-center justify-center">
                              <span className="h-2 w-2 rounded-full bg-blue-500" />
                            </RadioGroup.Indicator>
                          </RadioGroup.Item>
                          <label htmlFor={changeType.id}>
                            {disabled ? (
                              <a
                                href={`/inbox/${changeFlow?.type === 'early-termination' ? 'early-termination-queue' : 'incoming-changes-queue'}/contract-change/${changeFlow?.flowId}`}
                              >
                                {changeType.label}
                              </a>
                            ) : (
                              changeType.label
                            )}
                          </label>
                        </div>
                      );
                    })}
                  </RadioGroup.Root>
                </div>
                <div>
                  <Button
                    intent="primary"
                    onClick={handleStartChange}
                    disabled={isLoading}
                  >
                    Start change
                  </Button>
                </div>
                <div>
                  {isSuccess && (
                    <div className="bg-green-100 border-green-500 text-green-500 border-l-4 p-4">
                      <p>Change started</p>
                    </div>
                  )}
                  {isError && (
                    <div className="bg-red-100 border-red-500 text-red-500 border-l-4 p-4">
                      <p>Failed to start change</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>Contract Status: Active</div>
          )}
        </div>
      )) || <div>Contract Status: In Progress</div>}
    </div>
  );
}

export { CaseOverviewChangeContract };
