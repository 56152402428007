import { TaskAnalyticsItem } from "./TaskAnalytics.Item"
import { fetchPhases } from "../../api/api"
import { Stack } from "../ui"
import { cn } from "../../utils"
import { useQuery } from "@tanstack/react-query"
import { useMemo, useState } from "react"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from "../ui/Select"

const getGridCols = (length) => {
  if (length === 1) {
    return "grid-cols-1"
  } else if (length === 2) {
    return "grid-cols-2"
  } else if (length === 3) {
    return "grid-cols-3"
  } else if (length === 4) {
    return "grid-cols-4"
  } else if (length === 5) {
    return "grid-cols-5"
  } else if (length === 6) {
    return "grid-cols-6"
  } else {
    return "grid-cols-6"
  }
}

export function TaskAnalytics({
  t,
  startDate,
  endDate,
  variableQueryParam,
  taskAnalysisKeys,
  selectedTaskAnalysis,
  onChangeTaskAnalysis,
}) {
  const [granularity, setGranularity] = useState("seconds")
  const [selectedStatisticalMethod, setSelectedStatisticalMethod] =
    useState("medianDuration")

  const { data: phaseData, isSuccess } = useQuery({
    queryKey: [
      "phases",
      startDate,
      endDate,
      granularity,
      variableQueryParam,
      selectedTaskAnalysis,
    ],
    queryFn: () =>
      fetchPhases({
        taskAnalysisKey: selectedTaskAnalysis,
        startDate,
        endDate,
        granularity,
        variableQueryParam,
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    suspense: true,
    enabled: !!selectedTaskAnalysis,
  })

  // const statisticalMethods = useMemo(() => {
  //   if (!phaseData) {
  //     return []
  //   }
  //   const methods = phaseData?.phases?.[0]?.statistics
  //   //console.info("isSuccess ", isSuccess)
  //   delete methods["medianDuration"]
  //   return ["medianDuration", ...Object.keys(methods)]
  // }, [phaseData])

  const { phases } = phaseData ?? {}

  return (
    <div>
      <Stack>
        <div className="flex items-end justify-between">
          <h2 className="text-lg font-semibold text-primary-800">
            {t("task-analysis-header")}
          </h2>
          {/* <div className="flex justify-end gap-4">
             <div>
              <Select
                key={"stat type"}
                value={selectedTaskAnalysis}
                onValueChange={(val) => onChangeTaskAnalysis(val)}
              >
                <SelectTrigger>
                  <span className="">
                    <span className="block text-neutral-700 text-ellipsis">
                      {selectedTaskAnalysis}
                    </span>
                  </span>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>{t("task-analysis-header")}</SelectLabel>
                    {taskAnalysisKeys?.map((value) => (
                      <SelectItem key={value} value={value}>
                        {value}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div>
              <Select
                key={"stat type"}
                value={selectedStatisticalMethod}
                onValueChange={(val) => setSelectedStatisticalMethod(val)}
              >
                <SelectTrigger>
                  <span className="">
                    <span className="block text-neutral-700 text-ellipsis">
                      {selectedStatisticalMethod === "medianDuration"
                        ? t("statistical-method")
                        : selectedStatisticalMethod}
                    </span>
                  </span>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>{t("statistical-method")}</SelectLabel>
                    {statisticalMethods?.map((value) => (
                      <SelectItem key={value} value={value}>
                        {value}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>*/}
        </div>
        <div>
          {isSuccess && phases ? (
            <div
              className={cn(
                "grid w-full gap-4",
                `${getGridCols(phases.length)}`
              )}
            >
              {phases.map((phase) => (
                <TaskAnalyticsItem
                  key={phase.name}
                  phase={phase}
                  t={t}
                  statisticalMethod={selectedStatisticalMethod}
                />
              ))}
            </div>
          ) : null}
        </div>
      </Stack>
    </div>
  )
}
