import { FormProvider, useForm } from 'react-hook-form';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { RadioButtonList } from '../_shared/Inputs/RadioButtonList/RadioButtonList';
import { Text } from '../_shared/forms/fields/Text';
import { Button } from '../Button/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { ThreeOneLayout } from '../Layout/ThreeOneLayout';
export function ManuallyApproveTaskForm({ flowProps }) {
  const task = flowProps?.task?.data;
  const method = useForm({
    resolver: zodResolver(
      z
        .object({
          approved: z.string({ invalid_type_error: 'Please select an option' }),
          comment: z.string().optional(),
        })
        .superRefine((val, ctx) => {
          if (val.approved === 'reject' && !val.comment) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Comment is required',
              path: ['comment'],
            });
          }
        }),
    ),
    defaultValues: {
      approved: task?.approved || null,
      comment: task?.comment || null,
    },
  });

  const { handleSubmit, formState, watch } = method;

  const onSubmit = (data) => {
    flowProps.complete(data);
  };

  const status = watch('approved');
  const comment = watch('comment');
  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <ThreeOneLayout>
          <div className="col-span-3 border-r height-full border-gray-200 flex-col flex justify-between p-4">
            <h2>Data goes here</h2>
          </div>
          <div className=" col-span-1 p-4 flex flex-col gap-4">
            <RadioButtonList
              id="approved"
              options={[
                { label: 'Approve', value: 'approve' },
                { label: 'Reject', value: 'reject' },
              ]}
            />
            {status === 'reject' && (
              <Text name="comment" label="Rejection reason" />
            )}
            <div className="flex gap-2">
              <Button intent="primary" type="submit">
                Complete
              </Button>
              <Button
                onClick={() => flowProps?.save({ approved: status, comment })}
                intent="secondary"
                type="button"
              >
                Save and close
              </Button>
            </div>
          </div>
        </ThreeOneLayout>
      </form>
    </FormProvider>
  );
}
