import { useQuery } from '@tanstack/react-query';
import { getAllFlows } from '../../api/api';
import { findDateString, findTimeOfDay } from '../../utils/findDateString';
import { Box } from '../Box/Box';
import { EfficiencyGraph } from '../Home/EfficiencyGraph/EfficiencyGraph';
import { HalfPieScore } from '../Home/HalfPieScore/HalfPieScore';
import { WidgetCard } from '../WidgetCard/WidgetCard';
import { CarHalfPieScore } from '../Home/CarHalfPieScore/CarHalfPieScore';
import { useEffect } from 'react';
import { featureConfig } from '../../utils/featuresConfig';

export function HomePage(props) {
  const date = new Date();
  const dateString = findDateString(date);
  const timeOfDay = findTimeOfDay(date);
  const { data, isFetching } = useQuery({
    queryFn: async () => getAllFlows(),
    queryKey: ['flows-home'],
  });

  const roles = ['worker', 'admin', 'manager'];
  const isRoleInRoles = roles.includes(props?.user?.role);
  useEffect(() => {
    if (!isRoleInRoles) {
      window.location.href = '/access-denied';
    }
  }, []);

  const amountOfContracts = data?.filter(
    (item) => item.flowDefinitionId === 'initiate-contract',
  );

  const amountOfContractsCompleted = amountOfContracts?.filter(
    (item) => item.status === 'completed',
  );

  const amountOfContractsPending = amountOfContracts?.filter(
    (item) => item.status === 'active',
  );

  const amountOfInvoices = data?.filter(
    (item) => item.flowDefinitionId === 'register-invoice',
  );

  const amountOfInvoicesCompleted = amountOfInvoices?.filter(
    (item) => item.status === 'completed',
  );
  const amountOfInvoicesPending = amountOfInvoices?.filter(
    (item) => item.status === 'active',
  );

  const totalOverviewFinished = data?.filter(
    (item) => item?.status === 'completed',
  );

  const totalOverviewPending = data?.filter(
    (item) => item?.status === 'active',
  );
  const amountOfAssets = data?.filter(
    (item) => item?.referenceName === 'Asset',
  );
  const amountOfCars = data?.filter((item) => item?.referenceName === 'Car');

  const mainContracts = data?.filter(
    (item) => item?.flowDefinitionId === 'initiate-contract',
  );

  const contractChanges = data?.filter(
    (item) => item?.flowDefinitionId === 'contract-change',
  );

  return (
    <div className="p-12 flex flex-col gap-12 bg-gray-100 h-full">
      <div className="h-fit">
        <p className="text-sm">{dateString}</p>
        <h1 className="text-2xl">
          Good {timeOfDay}, {props?.user?.name.split(' ')?.[0]}
        </h1>
      </div>
      {!isFetching && (
        <>
          <div className="flex gap-4">
            {featureConfig.cc && (
              <WidgetCard
                imgSrc="https://img.freepik.com/free-photo/sports-car-races-through-dark-blurred-motion-generative-ai_188544-12490.jpg"
                isLast={false}
                text={'Car commerce'}
                url={`${featureConfig.cc.url}/Views/Orders/CreateNewOrder.aspx${!!props?.user?.bccToken ? `?bccToken=${props?.user?.bccToken}` : ''}`}
              ></WidgetCard>
            )}
            {featureConfig.fleet && (
              <WidgetCard
                imgSrc="https://b1085595.smushcdn.com/1085595/wp-content/uploads/2023/08/Electric-vehicle-tracking.png?lossy=0&strip=1&webp=1"
                isLast={false}
                text={'Fleet Portal'}
                url={`${featureConfig.fleet.url}`}
              ></WidgetCard>
            )}
            {featureConfig.panel && (
              <WidgetCard
                imgSrc="https://www.shutterstock.com/shutterstock/videos/1096596785/thumb/11.jpg?ip=x480"
                isLast={false}
                text={'Admin'}
                url={`${featureConfig.panel.url}`}
              ></WidgetCard>
            )}
            {featureConfig.core && (
              <WidgetCard
                imgSrc="https://insuremo.com/wp-content/uploads/2022/07/Digital-Architecture.jpg"
                isLast={false}
                text={'Core'}
                url={`${featureConfig.core.url}/core-bc-poc/web`}
              ></WidgetCard>
            )}
          </div>
          <div className="flex flex-row gap-8">
            <HalfPieScore
              ready={
                totalOverviewPending?.length === 0
                  ? 0
                  : totalOverviewPending?.length
              }
              completed={totalOverviewFinished?.length}
              title="Total overview"
              isLoading={isFetching}
            />
            <HalfPieScore
              ready={
                amountOfContractsPending?.length === 0
                  ? 0
                  : amountOfContractsPending?.length
              }
              completed={amountOfContractsCompleted?.length}
              title="Contracts"
              isLoading={isFetching}
            />
            <HalfPieScore
              ready={
                amountOfInvoicesPending?.length === 0
                  ? 0
                  : amountOfInvoicesPending?.length
              }
              completed={
                amountOfInvoicesCompleted?.length > 0
                  ? amountOfInvoicesCompleted?.length
                  : 0
              }
              title="Invoices"
              isLoading={isFetching}
            />
            <CarHalfPieScore
              cars={amountOfCars?.length}
              assets={amountOfAssets?.length}
              title="Asset Overview"
              isLoading={isFetching}
            />
          </div>
          <Box className="">
            <EfficiencyGraph
              creditAssesment={mainContracts.length}
              documentControl={contractChanges?.length}
              invoicing={amountOfInvoices?.length}
            />
          </Box>
        </>
      )}
    </div>
  );
}
