import { useEffect, useState } from 'react';

export default function Popover({
  show,
  children,
  content,
  onClose,
}: {
  children: any;
  show: boolean;
  content?: any;
  onClose?: () => void;
}) {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    if (!isMounted && show && onClose) {
      document.addEventListener('click', () => {
        onClose();
      });
      setIsMounted(true);
      return () => {
        document.removeEventListener('click', onClose);
      };
    } else if (isMounted && !show && onClose) {
      document.removeEventListener('click', onClose);
      setIsMounted(false);
    }
  }, [onClose, show, isMounted, setIsMounted]);
  return (
    <div
      className="relative"
      onClick={(event) => {
        if (show) {
          event.preventDefault();
          event.stopPropagation();
        }
      }}
    >
      <div hidden={!show}>
        <div className="absolute w-4 h-4 transform rotate-45 bg-gray-50 border-2 left-1/2 bottom-full translate-y-2 z-10" />
        <div className="absolute p-2 bg-gray-50 border-2 rounded-lg left-1/2 bottom-1/2 transform -translate-x-1/2 -translate-y-4 z-50">
          {content}
        </div>
      </div>
      <div className="absolute bg-gray-50" />
      {children}
    </div>
  );
}
