import { FeaturedMetricsLoading } from './components/FeaturedMetrics/FeaturedMetrics.Loading';
import { Services } from './components/Services';
import { ServicesError } from './components/Services/Services.Error';
import { ServicesLoading } from './components/Services/Services.Loading';
import { TaskAnalytics } from './components/TaskAnalytics/TaskAnalytics';
import { TaskAnalyticsError } from './components/TaskAnalytics/TaskAnalytics.Error';
import { TaskAnalyticsLoading } from './components/TaskAnalytics/TaskAnalytics.Loading';
import {
  FeaturedMetrics,
  FeaturedMetricsError,
} from './components/FeaturedMetrics';
import { Greeting } from './components/Greeting';
import { Layout } from './components/Layout';
import { Stack } from './components/ui';
import { Filter } from './components/Filter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { useFilter } from './hooks/useFilter';

const queryClient = new QueryClient();

function KpiDashboard({ t, user }) {
  const { dates, config } = useFilter();

  if (user === 'worker' || user === 'user') {
    return (
      <div className="flex flex-col w-full h-full items-center justify-center">
        <span>Woops...</span>
        <span>You do not have access to this page</span>
      </div>
    );
  }
  return (
    <Layout>
      <Layout.Header>
        <Stack>
          <Greeting t={t} />
          <FeaturedMetricsError t={t}>
            <Suspense fallback={<FeaturedMetricsLoading />}>
              <Filter t={t} {...dates} {...config} />
              <FeaturedMetrics t={t} {...dates} {...config} />
            </Suspense>
          </FeaturedMetricsError>
        </Stack>
      </Layout.Header>
      <Layout.Main>
        <div className="pt-12">
          <TaskAnalyticsError t={t}>
            <Suspense fallback={<TaskAnalyticsLoading />}>
              <TaskAnalytics t={t} {...dates} {...config}/>
            </Suspense>
          </TaskAnalyticsError>
        </div>
        <div className="pt-12">
          <ServicesError t={t}>
            <Suspense fallback={<ServicesLoading />}>
              <Services t={t} {...dates} {...config} />
            </Suspense>
          </ServicesError>
        </div>
      </Layout.Main>
    </Layout>
  );
}

const QueryClientProviderWrapper = ({ t, user }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <KpiDashboard t={t} user={user} />
    </QueryClientProvider>
  );
};

export default withTranslation('KPI')(QueryClientProviderWrapper);
