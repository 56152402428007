import { useQuery } from '@tanstack/react-query';
import { CaseOverviewChangeContract } from '../CaseOverview/CaseOverview.ChangeContract';
import { CaseSummaryAsset } from './CaseSummary.Asset';
import { CaseSummaryCar } from './CaseSummary.Car';
import { CaseSummaryContact } from './CaseSummary.Contact';
import { CaseSummaryDetails } from './CaseSummary.Details';
import { CaseSummaryInvoice } from './CaseSummary.Invoice';
import { getFlowByReference } from '../../api/api';
import { CaseSummaryProgressBar } from './CaseSummary.ProgressBar';

export function CaseSummary(props) {
  const data = props?.isOverview ? props.flow : props?.caseData;
  const user = props?.user;

  const { data: flows, refetch } = useQuery({
    queryFn: () => getFlowByReference(referenceId),
    queryKey: ['flow'],
  });

  const changeFlows = flows
    ?.filter(
      (flow) =>
        flow.flowDefinitionId === 'contract-change' && flow.status === 'active',
    )
    ?.map((flow) => ({
      type: flow.referenceName,
      flowId: flow.flowId,
    }));
  const referenceId = data?.referenceId;
  const coreId =
    data?.data?.coreAccount?.resultNewAccountNo ||
    data?.data?.activeContractChanges?.coreId;

  const contractActive = data?.status === 'completed';

  if (data?.flowDefinitionId === 'contract-change') {
    return (
      <>
        {!!props?.isOverview === false && (
          <a
            href={`/overview/${data?.referenceId}`}
            className="text-xs text-blue-500"
          >
            Go to Case Overview
          </a>
        )}
        <CaseOverviewChangeContract
          referenceId={referenceId}
          coreId={coreId}
          activeChanges={changeFlows}
          refetch={refetch}
          contractActive
        />
      </>
    );
  }

  if (data?.flowDefinitionId === 'register-invoice') {
    return <CaseSummaryInvoice flowProps={data} />;
  }
  if (!data?.data?.currentContractDetails) {
    return null;
  }

  const contract = data?.data?.currentContractDetails?.Contract;
  const calculationResult =
    data?.data?.currentContractDetails?.CalculationResult;
  const car = contract?.Vehicle;
  const leasingData = calculationResult?.Leasing;

  const customer = {
    cvr: contract?.Buyer?.CVR,
    orgName: contract?.Buyer?.Organisation?.Name,
    email: contract?.Buyer?.Organisation?.Email,
  };

  const carUser = {
    name:
      contract?.Buyer?.Person?.FirstName +
      ' ' +
      contract?.Buyer?.Person?.LastName,
    email: contract?.Buyer?.Person?.Email,
  };

  const salesPerson = {
    name:
      contract?.Seller?.Person?.FirstName +
      ' ' +
      contract?.Seller?.Person?.LastName,
    email: contract?.Seller?.Person?.Email,
  };

  const supplier = {
    vatNo: contract?.DeliveryCompany?.Organisation?.VATnr,
    name: contract?.DeliveryCompany?.Organisation?.Name,
    email: contract?.DeliveryCompany?.Organisation?.Email,
  };

  return (
    <div className="grid gap-4">
      {!!props?.isOverview === false && (
        <a
          href={`/overview/${data?.referenceId}`}
          className="text-xs text-blue-500"
        >
          Go to Case Overview
        </a>
      )}
      <CaseSummaryProgressBar flowProps={props} />
      {data?.referenceName?.toLowerCase() === 'car' ? (
        <div className="text-black caseSummary h-full">
          <CaseSummaryCar
            car={car}
            leasingData={leasingData}
            contractId={contract.Id}
            bccToken={user?.profile?.bccToken || ''}
          />
          <div className="grid gap-4">
            <div className=" p-4 rounded-xl shadow-lg flex flex-col w-full border border-gray-300">
              <CaseSummaryContact
                customer={customer}
                carUser={carUser}
                salesPerson={salesPerson}
                supplier={supplier}
              />
            </div>
            <div className="rounded-xl shadow-lg flex flex-col w-full border border-gray-300">
              <CaseSummaryDetails
                contract={contract}
                leasingData={leasingData}
              />
            </div>
          </div>
        </div>
      ) : (
        <CaseSummaryAsset
          assets={contract?.LeasingAssetsContract}
          isOverview={!!props?.isOverview}
          referenceId={data?.referenceId}
          bccToken={user?.profile?.bccToken || ''}
          contract={data?.data?.currentContractDetails?.Contract}
          calculation={data?.data?.currentContractDetails?.Calculation}
          status={props?.caseData?.status}
          coreId={coreId}
        />
      )}
      {!props.isOverview && (
        <CaseOverviewChangeContract
          referenceId={referenceId}
          coreId={coreId}
          activeChanges={changeFlows}
          refetch={refetch}
          contractActive={contractActive}
        />
      )}
    </div>
  );
}
