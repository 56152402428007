import { SvgIconProps } from './SvgIcon.types';

export function BusinessIcon({
  size = 18,
  color = 'currentColor',
}: SvgIconProps) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
    >
      <g clipPath="url(#a)" fill={color}>
        <path d="M7 10H5V1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6h-2V2H7v8Z" />
        <path d="M23 8h-9a1 1 0 0 0-1 1v13h-2v-9a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1ZM7 21H4v-2h3v2Zm0-4H4v-2h3v2Zm13 4h-3v-2h3v2Zm0-4h-3v-2h3v2Zm0-4h-3v-2h3v2Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
