import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormConnect } from '../../../../hooks/useFormConnect';
import { Text } from '../../forms/fields/Text';
import clsx from 'clsx';
import { set } from 'react-hook-form';

export function EquipmentCombobox({ options, name, setValue }) {
  const data = useFormConnect(name);
  function setValues(id) {
    setValue('assetId', id);
  }

  const defaultAssetOption = options.length === 1 ? options?.[0] : undefined;
  React.useEffect(() => {
    setValues(defaultAssetOption?.id);
  }, []);

  return (
    <Autocomplete
      options={options}
      defaultValue={defaultAssetOption}
      className="w-full"
      getOptionLabel={(option: any) => option?.label}
      id={name}
      onChange={(e, value) => setValues(value?.id)}
      renderOption={(props, option: any) => {
        return (
          /* @ts-ignore */
          <div
            {...props}
            {...data}
            key={option?.value?.id}
            className="py-2 px-4 cursor-pointer hover:bg-gray-200 flex justify-between"
          >
            <div>
              <p className="text-xs">Asset </p>
              <h4>{option?.label}</h4>
            </div>
            <p className="text-xs">{option?.assetType}</p>
          </div>
        );
      }}
      renderInput={(params) => {
        const newParams = {
          ...params,
          InputProps: {
            ...params.InputProps,
            className: 'h-input',
          },
          inputProps: {
            ...params.inputProps,
            className: 'h-input',
          },
        };
        return <TextField name={'test'} {...newParams} />;
      }}
    />
  );
}
