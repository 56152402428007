import { ManuallyHandleSupplierTaskForm } from '../../components/Views/ManuallyHandleSupplierTaskForm';
import { FlowContext } from '../../hooks/useFlowContext';

export function ManuallyHandleSupplier(props) {
  return (
    <FlowContext.Provider value={props}>
      <ManuallyHandleSupplierTaskForm flowProps={props} />
    </FlowContext.Provider>
  );
}
