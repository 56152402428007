import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { RadioButtonListSignees } from '../_shared/Inputs/RadioButtonList/RadioButtonListSignees';

export function ChooseSignersTaskForm({ flowProps }) {
  const signRules = flowProps?.flow?.data?.signingCombinations;
  const method = useForm({
    resolver: zodResolver(
      z.object({
        signers: z
          .array(
            z.object({
              name: z.string(),
              nationalIdentificationNumber: z.string().optional(),
              id: z.string(),
              email: z.string().email({ message: 'Enter a valid email' }),
              positions: z.array(
                z.object({
                  roleName: z.string(),
                }),
              ),
            }),
          )
          .min(1, { message: 'Select at least one signing combination' }),
      }),
    ),
    defaultValues: {
      signers: [],
    },
  });

  const { handleSubmit, control, watch, setValue, resetField } = method;

  function onSubmit(data) {
    flowProps?.complete(data);
  }

  const mappedRules = signRules?.map((rule, index) => {
    return {
      label: `Combination ${index + 1}`,
      value: rule,
      index,
    };
  });

  return (
    <FormProvider {...method}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-gray-100 h-full w-full flex flex-col gap-4 justify-start items-center p-4"
      >
        <h3 className="flex flex-col gap-4 w-2/3 text-lg">
          Select combination
        </h3>
        <Box className="w-2/3 flex flex-col gap-8">
          <RadioButtonListSignees
            setValue={setValue}
            id="signers"
            options={mappedRules}
          />
        </Box>
        <div className="w-2/3 flex gap-4">
          <Button type="submit" intent="primary">
            Complete
          </Button>
          <Button type="button" intent="secondary">
            Save and close
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
