import { AdjustInCoreTaskForm } from '../../components/Views/AdjustInCoreTaskForm';
import { CompleteTaskView } from '../../components/Views/CompleteTaskView';
import { FlowContext } from '../../hooks/useFlowContext';

export function AdjustInCore(props) {
  return (
    <FlowContext.Provider value={props}>
      <AdjustInCoreTaskForm flowProps={props} />
    </FlowContext.Provider>
  );
}
