import { DateRangePopover } from "../DateRangePopover"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from "../ui/Select"

export function Filter({
  t,
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
  configurableVariables,
  selectedVariable,
  taskAnalysisKeys,
  selectedTaskAnalysis,
  onChangeTaskAnalysis,
  handleSelectConfig,
}) {
  return (
    <div className="flex flex-row justify-end gap-4">
      <div className="flex">
        <DateRangePopover
          startDate={startDate}
          endDate={endDate}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          t={t}
        />
      </div>
      <div>
        <Select
          key={"stat type"}
          value={selectedTaskAnalysis}
          onValueChange={(val) => onChangeTaskAnalysis(val)}
        >
          <SelectTrigger>
            <span className="">
              <span className="block text-neutral-700 text-ellipsis">
                {selectedTaskAnalysis}
              </span>
            </span>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>{t("task-analysis-header")}</SelectLabel>
              {taskAnalysisKeys?.map((value) => (
                <SelectItem key={value} value={value}>
                  {value}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      {configurableVariables ? (
        <div className="flex flex-row justify-end gap-4">
          {configurableVariables.map((item) => (
            <div key={item.name}>
              <Select
                key={item.name}
                value={
                  selectedVariable?.name === item.name
                    ? selectedVariable.value
                    : item.values?.[0]
                }
                onValueChange={(val) => handleSelectConfig(item, val)}
              >
                <SelectTrigger>
                  <span className="">
                    <span className="block text-neutral-700 text-ellipsis">
                      {selectedVariable?.name === item.name
                        ? selectedVariable.value
                        : t(item.name)}
                    </span>
                  </span>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>{item.name}</SelectLabel>
                    {item.values?.map((value) => (
                      <SelectItem key={value} value={value}>
                        {value}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
