const labelColors = {
  assetsCurrent: {
    bg: `#2969FF`,
    text: `#fff`,
  },
  assetsFixed: {
    bg: `#A2C9FF`,
    text: `#232323`,
  },
  debt: {
    bg: `#E96C00`,
    text: `#fff`,
  },
  equity: {
    bg: `#FDDFA5`,
    text: `#232323`,
  },
};

function ChartBalanceBarLabelAssets({
  x,
  y,
  stroke,
  value,
  fill,
  ...props
}: any) {
  const padding = 2; // adjust as needed
  const fontSize = props.width > 50 ? 12 : 10;
  const boxHeight = fontSize + 2 * padding; // adjust as needed
  const boxWidth = fontSize * 3;

  const middle = x + props.width / 2;
  const widthLeftToMiddle = middle - x;
  const centerOfLeftToMiddle = x + widthLeftToMiddle / 2;
  const leftBoxPlacement = centerOfLeftToMiddle - boxWidth / 2;
  const rightBoxPlacement = middle + widthLeftToMiddle / 2 - boxWidth / 2;

  return (
    <g>
      <rect
        rx={3}
        ry={3}
        x={leftBoxPlacement}
        y={y - boxHeight - padding}
        width={boxWidth}
        height={boxHeight}
        fill={labelColors.assetsCurrent.bg}
      />
      <text
        x={leftBoxPlacement + padding}
        y={y}
        dy={-5}
        fill={labelColors.assetsCurrent.text}
        fontSize={fontSize}
        textAnchor="left"
      >
        {value.assetsPercentageCurrent.toFixed(0)} %
      </text>
      <rect
        rx={3}
        ry={3}
        x={rightBoxPlacement}
        y={y - boxHeight - padding}
        width={boxWidth}
        height={boxHeight}
        fill={labelColors.assetsFixed.bg}
      />
      <text
        x={rightBoxPlacement + padding}
        y={y}
        dy={-5}
        fill={labelColors.assetsFixed.text}
        fontSize={fontSize}
        textAnchor="left"
      >
        {value.assetsPercentageFixed.toFixed(0)} %
      </text>
    </g>
  );
}

function ChartBalanceBarLabelLiabilities({
  x,
  y,
  stroke,
  value,
  fill,
  ...props
}: any) {
  const padding = 2; // adjust as needed
  const fontSize = props.width > 50 ? 12 : 10;
  const boxHeight = fontSize + 2 * padding; // adjust as needed
  const boxWidth = fontSize * 3;

  const middle = x + props.width / 2;
  const widthLeftToMiddle = middle - x;
  const centerOfLeftToMiddle = x + widthLeftToMiddle / 2;
  const leftBoxPlacement = centerOfLeftToMiddle - boxWidth / 2;
  const rightBoxPlacement = middle + widthLeftToMiddle / 2 - boxWidth / 2;

  if (value.equity < 0) {
    return null;
  }

  return (
    <g>
      <rect
        rx={3}
        ry={3}
        x={leftBoxPlacement}
        y={y - boxHeight - padding}
        width={boxWidth}
        height={boxHeight}
        fill={labelColors.debt.bg}
      />
      <text
        x={leftBoxPlacement + padding}
        y={y}
        dy={-5}
        fill={labelColors.debt.text}
        fontSize={fontSize}
        textAnchor="left"
      >
        {value.debtPercentage?.toFixed(0)} %
      </text>
      <rect
        rx={3}
        ry={3}
        x={rightBoxPlacement}
        y={y - boxHeight - padding}
        width={boxWidth}
        height={boxHeight}
        fill={labelColors.equity.bg}
      />
      <text
        x={rightBoxPlacement + padding}
        y={y}
        dy={-5}
        fill={labelColors.equity.text}
        fontSize={fontSize}
        textAnchor="left"
      >
        {value.equityPercentage?.toFixed(0)} %
      </text>
    </g>
  );
}

function ChartBalanceBarLabelLiabilitiesNegativeEquity({
  x,
  y,
  stroke,
  value,
  fill,
  ...props
}: any) {
  const padding = 2; // adjust as needed
  const fontSize = props.width > 50 ? 12 : 10;
  const boxHeight = fontSize + 2 * padding; // adjust as needed
  const boxWidth = fontSize * 3; // adjust as needed

  const middle = x + props.width / 2;
  const widthLeftToMiddle = middle - x;
  const centerOfLeftToMiddle = x + widthLeftToMiddle / 2;
  const leftBoxPlacement = centerOfLeftToMiddle - boxWidth / 2;
  const rightBoxPlacement = middle + widthLeftToMiddle / 2 - boxWidth / 2;

  if (value.equity > 0) {
    return null;
  }

  return (
    <g>
      <rect
        rx={3}
        ry={3}
        x={leftBoxPlacement}
        y={y - boxHeight - padding}
        width={boxWidth}
        height={boxHeight}
        fill={labelColors.debt.bg}
      />
      <text
        x={leftBoxPlacement + padding}
        y={y}
        dy={-5}
        fill={labelColors.debt.text}
        fontSize={fontSize}
        textAnchor="left"
      >
        {value.debtPercentage?.toFixed(0)} %
      </text>
      <rect
        rx={3}
        ry={3}
        x={rightBoxPlacement}
        y={y - boxHeight - padding}
        width={boxWidth}
        height={boxHeight}
        fill={labelColors.equity.bg}
      />
      <text
        x={rightBoxPlacement + padding}
        y={y}
        dy={-5}
        fill={labelColors.equity.text}
        fontSize={fontSize}
        textAnchor="left"
      >
        {value.equityPercentage?.toFixed(0)} %
      </text>
    </g>
  );
}

export {
  ChartBalanceBarLabelAssets,
  ChartBalanceBarLabelLiabilities,
  ChartBalanceBarLabelLiabilitiesNegativeEquity,
};
