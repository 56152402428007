module.exports = {
  'sign-document': 'customer-task',
  'see-invoice-on-asset-overview': 'caseworker-task',
  'four-eyes-manual-handling-credit': 'caseworker-task',
  'manually-complete-onboarding': 'caseworker-task',
  'manual-handling-credit': 'caseworker-task',
  'manually-verify-invoice': 'caseworker-task',
  'asset-delivery': 'caseworker-task',
  'choose-signers': 'caseworker-task',
  'verify-asset-payout': 'caseworker-task',
  'complete-full-case-assessment': 'caseworker-task',
  'change-signees-message-task': 'optional-caseworker-task',
  'resend-signing-information': 'optional-caseworker-task',
  'select-assets-for-pod': 'caseworker-task',
  'need-more-documentation': 'optional-caseworker-task',
  'manually-assign-invoice': 'caseworker-task',
  'upload-requested-documents': 'customer-task',
  'manually-handle-supplier': 'caseworker-task',
  'verify-pod': 'customer-task',
  'create-account': 'Creating account...',
  'get-kyc-b2b-status': 'optional-caseworker-task',
  'skip-signing': 'optional-caseworker-task',
  'adjust-in-core': 'caseworker-task',
};
