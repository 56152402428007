import { Stack } from '../../ui';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

const COLORS = ['#00257A', '#5C8DFF', '#C2D4FF', '#E5EDFF'];

export function ChartAggregateCounts({ data, totalCount }) {
  return (
    <div className="relative">
      <div className="relative w-full pb-[180px]">
        <div className="absolute bottom-0 left-0 right-0 top-0">
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={data}
                innerRadius={'80%'}
                outerRadius={'100%'}
                paddingAngle={2}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      <Stack gap="sm" className="pt-4">
        {data.map((entry, index) => (
          <div
            key={`legend-${index}`}
            className="flex items-center justify-start gap-2 text-xs"
          >
            <div
              className="h-4 w-4 rounded-full"
              style={{ backgroundColor: COLORS[index % COLORS.length] }}
            ></div>
            <div className="flex items-start">
              <div>{entry.name}</div>
            </div>
          </div>
        ))}
        <div className="text-xs">Total: {totalCount}</div>
      </Stack>
    </div>
  );
}
