import { FormProvider, set, useFieldArray, useForm } from 'react-hook-form';
import { Comment } from '../_shared/forms/fields/Comment';
import { Button } from '../Button/Button';
import { ThreeOneLayout } from '../Layout/ThreeOneLayout';
import { Box } from '../Box/Box';
import { RadioButtonList } from '../_shared/Inputs/RadioButtonList/RadioButtonList';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { uploadDocuments } from '../../api/api';
import { useDropzone } from 'react-dropzone';
import FormData from 'form-data';
import * as Tabs from '@radix-ui/react-tabs';
import { Loader } from '../Loader/Loader';

const MAX_SIZE = 20 * 1024 * 1024;

export function UploadDocumentsTaskForm({ flowProps }) {
  const { task } = flowProps;
  const reporter = {
    id: flowProps?.user?.profile?.username,
    name: flowProps?.user?.profile?.name,
  };
  const method = useForm({
    resolver: zodResolver(
      z.object({
        docs: z.array(z.any()),
        reporter: z.object({
          id: z.string(),
          name: z.string(),
        }),
        date: z.string(),
      }),
    ),
    defaultValues: {
      reporter: reporter,
      date: new Date().toISOString(),
      docs: [],
    },
  });
  const { handleSubmit, control } = method;
  const {
    mutate,
    data: mutatedData,
    isLoading,
  } = useMutation({
    //@ts-ignore
    mutationFn: (data: any) => uploadDocuments(data),
    onMutate: () => setLoading(true),
    onSuccess: (data) => {
      flowProps.complete({
        docs: data?.data?.uploadedFiles,
        reporter: reporter,
        date: new Date().toISOString(),
      });
    },
  });
  const onSubmit = (data) => {
    const form = new FormData();
    data.docs.forEach((doc) => {
      form.append('files', doc);
    });
    mutate(form);
  };

  const { fields, append } = useFieldArray({
    control,
    //@ts-ignore
    name: 'docs',
  } as any);

  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);
  function handleFileUpload(file) {
    const reader = new FileReader();
    setFiles(file);
    append(file);
    reader.readAsBinaryString(file);
  }

  const { getRootProps, getInputProps, fileRejections, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFileUpload(
        //@ts-ignore
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    accept: {
      'application/pdf': [],
      'image/*': [],
    },
    noClick: false,
    noKeyboard: false,
    multiple: false,
    maxSize: MAX_SIZE,
  });

  const { ref, ...rootProps } = getRootProps();
  const [active, setActive] = useState('1');

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <ThreeOneLayout>
          <ThreeOneLayout.Left>
            <Tabs.Root defaultValue="1" className="h-full">
              <Tabs.List className="flex gap-4 pb-4">
                {fields?.map((field: any, index: number) => {
                  return (
                    <Tabs.Trigger
                      onClick={() => setActive(`${index + 1}`)}
                      value={`${index + 1}`}
                      className={
                        active === `${index + 1}`
                          ? 'p-2 border-b-2 border-primary-600'
                          : 'p-2 border-b-2 border-transparent'
                      }
                    >
                      {field?.path}
                    </Tabs.Trigger>
                  );
                })}
              </Tabs.List>
              {fields?.map((field: any, index: number) => {
                return (
                  <Tabs.Content value={`${index + 1}`} className="h-full">
                    {' '}
                    <embed
                      className="h-full w-full col-span-1"
                      src={field?.preview}
                    />
                  </Tabs.Content>
                );
              })}
            </Tabs.Root>
            {fields?.length === 0 && (
              <div className="flex items-center justify-center h-full w-full">
                <div
                  {...rootProps}
                  className="p-12 rounded-xl border-gray-400 border-dashed border-2"
                  onKeyDown={(event) => {
                    if (event.code === 'Enter' || event.code === 'Space') {
                      open();
                    }
                  }}
                >
                  <input id={`${document}`} {...getInputProps()} type="file" />
                  <p>Drag or click to upload file</p>
                </div>
              </div>
            )}
            {fields?.length > 0 && (
              <div
                {...rootProps}
                className="absolute bottom-8 bg-blue-600 text-white right-2 h-12 w-12 flex items-center cursor-pointer justify-center rounded-full"
                onKeyDown={(event) => {
                  if (event.code === 'Enter' || event.code === 'Space') {
                    open();
                  }
                }}
              >
                <input id={`${document}`} {...getInputProps()} type="file" />
                <p className="text-3xl">+</p>
              </div>
            )}
          </ThreeOneLayout.Left>
          <ThreeOneLayout.Right>
            <div>
              <h3 className="p-1 border-b border-gray-200">
                Requested documents:
              </h3>
              <ul className="py-2">
                {flowProps?.task?.context?.documentsRequested?.map(
                  (doc, index) => {
                    return (
                      <li className="pl-4">
                        {index + 1}. {mapTheDocumentNames(doc)}
                      </li>
                    );
                  },
                )}
              </ul>
              <p className=" italic text-xs">
                Requested by{' '}
                {flowProps?.task?.context?.userWhoRequestedMoreDocuments?.name}
              </p>
              <p className=" italic text-xs">
                {formatISODateString(
                  flowProps?.task?.context?.dateRequestedDocuments,
                )}
              </p>
            </div>
            <div className="flex flex-col gap-4">
              {isLoading ? (
                <Button className="w-12">
                  <div className="h-4 w-4 spinner rounded-full animate-spin px-3 py-3" />
                </Button>
              ) : (
                <Button type="submit">
                  Upload document{fields?.length > 1 ? 's' : ''}
                </Button>
              )}
            </div>
          </ThreeOneLayout.Right>
        </ThreeOneLayout>
      </form>
    </FormProvider>
  );
}

export function formatISODateString(isoString) {
  const date = new Date(isoString);

  // Format: YYYY-MM-DD HH:mm:ss
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day}, ${hours}:${minutes}:${seconds}`;
}

export function mapTheDocumentNames(name) {
  switch (name) {
    case 'budget':
      return 'Budget';
    case 'updatedFinancialStatement':
      return 'Updated Financial Statement';
    case 'businessPlan':
      return 'Business Plan';
    default:
      return name;
  }
}
