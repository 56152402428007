import { NeedMoreDocumentationTaskForm } from '../../components/Views/NeedMoreDocumentationTaskForm';
import { FlowContext } from '../../hooks/useFlowContext';

export function NeedMoreDocumentation(props) {
  return (
    <FlowContext.Provider value={props}>
      <NeedMoreDocumentationTaskForm flowProps={props} />
    </FlowContext.Provider>
  );
}
