import { formatMoney } from '../../utils/formatMoney';
import { FormProvider, useForm } from 'react-hook-form';
import { Amount } from '../_shared/forms/fields/Amount';
import { useState } from 'react';
export function CaseSummaryDetails({ contract, leasingData }) {
  return (
    <div className="p-4 flex flex-row h-full gap-4 ">
      <CaseSummaryServicePriceDetails
        contract={contract}
        leasingData={leasingData}
      />
      <div className="h-full bg-gray-300 w-1" />
      <CaseSummaryAgreementDetails
        contract={contract}
        leasingData={leasingData}
      />
    </div>
  );
}

export function CaseSummaryAgreementDetails({
  contract,
  leasingData,
  currency = 'DKK',
}) {
  const frameAgreementDetails = [
    {
      title: 'Leasing period',
      value: contract?.Period,
      suffix: 'months',
    },
    {
      title: 'Interest',
      value: `${leasingData?.Interest}%`,
      suffix: '',
    },
    {
      title: 'Establishment costs',
      value: leasingData?.InitialFee,
      suffix: 'kr',
    },
    {
      title: 'Insurance administration',
      value: leasingData?.InsuranceAdministration,
      suffix: 'kr',
    },
    {
      title: 'Administration fee without PBS',
      value: contract?.AdministrationFeeNoPBS,
      suffix: 'kr/mo',
    },
    {
      title: 'Administration fee',
      value: contract?.AdministrationFee,
      suffix: 'kr/mo',
    },
  ];

  return (
    <div className="w-full">
      <h2 className="pb-2 text-sm">Frame agreement details</h2>
      <div className="flex flex-col gap-1">
        {frameAgreementDetails.map((item, index) => {
          return (
            <CaseSummaryDetailsList
              key={`agreement-details-${index}`}
              title={item.title}
              value={item.value}
              suffix={item.suffix}
            />
          );
        })}
      </div>
    </div>
  );
}

export function CaseSummaryServicePriceDetails({ contract, leasingData }) {
  const PriceDetailsData = [
    {
      title: 'The price of the car incl. reg. tax. ',
      value:
        leasingData
          ?.VehicleOptionsLicencePlateDeliverySubidiesAndTotalRegFeeVatPrice
          .ExVat,
    },
    {
      title: 'Registration fee upon registration',
      value: leasingData?.RegFeeInPeriod,
    },
    {
      title: 'Total financing sum',
      value:
        leasingData?.FinacedAmount +
        leasingData?.TotalFeesAndInsuranceAdministration,
    },
    {
      title: 'Residual value',
      value: `(${leasingData?.ResidualValuePercent?.toFixed(0)} %) ${formatMoney(leasingData?.TotalResidualValueInclRegfee?.toFixed(0))} kr`,
    },
    {
      title: 'Environmental tax (2024)',
      value: leasingData?.AarligtMiljoetillaeg,
    },
    {
      title: 'Tax base',
      value: leasingData?.TaxValueInclVat,
    },
    {
      title: 'Downpayment',
      value: leasingData?.downpayment?.ExVat,
    },
  ];

  const ServiceDetailsData = [
    {
      title: 'Service deal',
      value: leasingData?.MonthlyService,
    },
    {
      title: 'Tire deal',
      value: leasingData?.MonthlyTireDealPrice,
    },
    ...contract.Vehicle.Options.reduce((acc, option) => {
      if (option.OptionType !== 4) return acc;
      if (option.ServiceOptionType === 8) return acc;
      const monthlyCost = option.MonthlyPriceVatPrice
        ? option.MonthlyPriceVatPrice.ExVat
        : 0;
      return [
        ...acc,
        {
          title: option.Name,
          value: monthlyCost ?? option.Price,
        },
      ];
    }, []),
  ];
  return (
    <div className="w-full grid gap-2">
      <div>
        <h2 className="pb-2 text-sm">Price details</h2>
        <div className="flex flex-col gap-1">
          {PriceDetailsData.map((item, index) => {
            return (
              <CaseSummaryDetailsList
                key={`price-details-${index}`}
                title={item.title}
                value={item.value}
              />
            );
          })}
        </div>
      </div>
      <div>
        <h2 className="pb-2 text-sm">Services</h2>
        <div className="flex flex-col gap-2">
          {ServiceDetailsData.map((item, index) => {
            return (
              <CaseSummaryDetailsList
                key={`service-details-${index}`}
                title={item.title}
                value={item.value}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

type CaseSummaryDetailsListProps = {
  title: string;
  value: number | string;
  suffix?: string;
};

export function CaseSummaryDetailsList({
  title,
  value,
  suffix = 'kr',
}: CaseSummaryDetailsListProps) {
  return (
    <data className="text-gray-500 text-xs flex flex-row items-center justify-between">
      <dt>{title}</dt>
      <dd>
        {typeof value === 'number' ? `${formatMoney(value)} ${suffix}` : value}
      </dd>
    </data>
  );
}
